"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useButtonStyle = void 0;
var common_1 = require("@monkvision/common");
var types_1 = require("@monkvision/types");
var react_1 = require("react");
var Button_styles_1 = require("./Button.styles");
function getInteractiveVariation(shade) {
    return shade === 'dark' ? common_1.InteractiveVariation.LIGHTEN : common_1.InteractiveVariation.DARKEN;
}
function useButtonStyle(params) {
    var utils = (0, common_1.useMonkTheme)().utils;
    var _a = (0, react_1.useMemo)(function () { return ({
        primary: (0, common_1.getInteractiveVariants)(utils.getColor(params.primaryColor), getInteractiveVariation(params.shade)),
        secondary: (0, common_1.getInteractiveVariants)(utils.getColor(params.secondaryColor), getInteractiveVariation(params.shade)),
    }); }, [params, utils]), primary = _a.primary, secondary = _a.secondary;
    return (0, react_1.useMemo)(function () {
        var foregroundColor = primary[types_1.InteractiveStatus.DEFAULT];
        if (params.variant === 'fill') {
            foregroundColor = secondary[types_1.InteractiveStatus.DEFAULT];
        }
        else if (params.variant === 'text-link') {
            foregroundColor = primary[params.status];
        }
        var backgroundColor = 'transparent';
        if (params.variant === 'fill') {
            backgroundColor = primary[params.status];
        }
        else if (params.variant === 'outline') {
            backgroundColor = secondary[params.status];
        }
        else if (params.variant === 'text') {
            backgroundColor = Button_styles_1.buttonTextBackgrounds[params.status];
        }
        var contentSize = params.size === 'normal' ? Button_styles_1.BUTTON_CONTENT_SIZE_NORMAL : Button_styles_1.BUTTON_CONTENT_SIZE_SMALL;
        var iconStyle = __assign(__assign(__assign({}, Button_styles_1.styles['icon']), (params.size === 'small' ? Button_styles_1.styles['iconSmall'] : {})), (!params.hasChildren ? Button_styles_1.styles['iconOnly'] : {}));
        return {
            style: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, Button_styles_1.styles['button']), (params.status === types_1.InteractiveStatus.DISABLED ? Button_styles_1.styles['buttonDisabled'] : {})), (params.size === 'small' ? Button_styles_1.styles['buttonSmall'] : {})), (params.variant === 'outline' ? Button_styles_1.styles['buttonOutline'] : {})), (params.variant === 'text-link' ? Button_styles_1.styles['buttonTextLink'] : {})), (!params.hasChildren && params.icon ? Button_styles_1.styles['buttonIconOnly'] : {})), (!params.hasChildren && params.icon && params.size === 'small'
                ? Button_styles_1.styles['buttonIconOnlySmall']
                : {})), { color: foregroundColor, borderColor: foregroundColor, backgroundColor: backgroundColor }),
            iconStyle: {
                style: iconStyle,
                color: foregroundColor,
                size: contentSize,
            },
            iconContainerStyle: {
                style: __assign({ width: contentSize, height: contentSize }, iconStyle),
            },
            spinnerStyle: {
                style: __assign({ top: "calc(50% - ".concat(contentSize / 2, "px)"), left: "calc(50% - ".concat(contentSize / 2, "px)") }, (params.preserveWidthOnLoading ? Button_styles_1.styles['spinnerFixedWith'] : {})),
                color: foregroundColor,
                size: contentSize,
            },
        };
    }, [params, primary, secondary]);
}
exports.useButtonStyle = useButtonStyle;
