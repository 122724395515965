"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSliderStyle = void 0;
var react_1 = require("react");
var types_1 = require("@monkvision/types");
var common_1 = require("@monkvision/common");
var Slider_styles_1 = require("../Slider.styles");
/**
 * Custom hook for generating styles for the Slider component based on provided parameters.
 * @param params - Parameters for generating Slider styles.
 * @returns SliderStyles object containing CSS properties for various slider elements.
 */
function useSliderStyle(params) {
    var utils = (0, common_1.useMonkTheme)().utils;
    var _a = {
        primary: utils.getColor(params.primaryColor),
        secondary: utils.getColor(params.secondaryColor),
        tertiary: utils.getColor(params.tertiaryColor),
    }, primary = _a.primary, secondary = _a.secondary, tertiary = _a.tertiary;
    return (0, react_1.useMemo)(function () {
        return {
            sliderStyle: __assign(__assign(__assign({}, Slider_styles_1.styles['sliderStyle']), params.style), (params.status === types_1.InteractiveStatus.DISABLED ? Slider_styles_1.styles['sliderDisabled'] : {})),
            trackBarStyle: __assign(__assign({}, Slider_styles_1.styles['trackBarStyle']), { background: tertiary }),
            thumbStyle: __assign(__assign(__assign({}, Slider_styles_1.styles['thumbStyle']), (params.status === types_1.InteractiveStatus.DISABLED ? { cursor: 'default' } : {})), { borderColor: primary }),
            progressBarStyle: __assign(__assign({}, Slider_styles_1.styles['progressBarStyle']), { background: secondary }),
            hoverThumbStyle: __assign(__assign(__assign({}, Slider_styles_1.styles['thumbStyle']), Slider_styles_1.styles['hoverStyle']), ([types_1.InteractiveStatus.HOVERED, types_1.InteractiveStatus.ACTIVE].includes(params.status)
                ? Slider_styles_1.styles['hovered']
                : {})),
        };
    }, [params, utils]);
}
exports.useSliderStyle = useSliderStyle;
