"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisplayText = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var PhotoCaptureHUDTutorial_styles_1 = require("./PhotoCaptureHUDTutorial.styles");
function DisplayText(_a) {
    var tutorialStep = _a.tutorialStep;
    var t = (0, react_i18next_1.useTranslation)().t;
    var textArray = t("photo.hud.tutorial.".concat(tutorialStep)).split(/<br\s*\/?>/);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: PhotoCaptureHUDTutorial_styles_1.styles['text'] }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: PhotoCaptureHUDTutorial_styles_1.styles['title'], "data-testid": 'title' }, { children: t('photo.hud.tutorial.title') })), textArray.map(function (value, index) { return ((0, jsx_runtime_1.jsxs)("div", __assign({ "data-testid": "text".concat(index) }, { children: [value, index < textArray.length - 1 && (0, jsx_runtime_1.jsx)("br", {})] }), index)); })] })));
}
exports.DisplayText = DisplayText;
