"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InspectionGallery = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var types_1 = require("@monkvision/types");
var types_2 = require("./types");
var hooks_1 = require("./hooks");
var i18n_1 = require("./i18n");
var InspectionGalleryTopBar_1 = require("./InspectionGalleryTopBar");
var InspectionGalleryItemCard_1 = require("./InspectionGalleryItemCard");
var ImageDetailedView_1 = require("../ImageDetailedView");
function getItemKey(item) {
    if (item.isAddDamage) {
        return 'item-add-damage';
    }
    if (!item.isTaken) {
        return item.sightId;
    }
    return item.image.id;
}
/**
 * This component is used to display a gallery of pictures taken during an inspection. If this component is used
 * mid-capture, set the `captureMode` prop to `true` so that you'll enable features such as compliance errors, retakes
 * etc.
 */
exports.InspectionGallery = (0, common_1.i18nWrap)(function InspectionGallery(props) {
    (0, common_1.useI18nSync)(props.lang);
    var _a = (0, react_1.useState)(null), selectedImage = _a[0], setSelectedImage = _a[1];
    var items = (0, hooks_1.useInspectionGalleryItems)(props);
    var _b = (0, react_1.useState)(null), currentFilter = _b[0], setCurrentFilter = _b[1];
    var filteredItems = (0, react_1.useMemo)(function () { return (currentFilter ? items.filter(currentFilter.callback) : items); }, [items, currentFilter]);
    var _c = (0, hooks_1.useInspectionGalleryStyles)(), containerStyle = _c.containerStyle, itemListStyle = _c.itemListStyle, itemStyle = _c.itemStyle, fillerItemStyle = _c.fillerItemStyle, emptyStyle = _c.emptyStyle;
    var fillerCount = (0, hooks_1.useItemListFillers)(filteredItems.length);
    var emptyLabel = (0, hooks_1.useInspectionGalleryEmptyLabel)({
        captureMode: props.captureMode,
        isFilterActive: currentFilter !== null,
    });
    var handleItemClick = function (item) {
        var _a, _b;
        if (item.isAddDamage && props.captureMode) {
            (_a = props.onNavigateToCapture) === null || _a === void 0 ? void 0 : _a.call(props, { reason: types_2.NavigateToCaptureReason.ADD_DAMAGE });
        }
        else if (!item.isAddDamage && !item.isTaken && props.captureMode) {
            (_b = props.onNavigateToCapture) === null || _b === void 0 ? void 0 : _b.call(props, {
                reason: types_2.NavigateToCaptureReason.CAPTURE_SIGHT,
                sightId: item.sightId,
            });
        }
        else if (!item.isAddDamage && item.isTaken) {
            setSelectedImage(item.image);
        }
    };
    var handleRetakeImage = function (image) {
        var _a, _b;
        if (props.captureMode && (image === null || image === void 0 ? void 0 : image.sightId)) {
            (_a = props.onNavigateToCapture) === null || _a === void 0 ? void 0 : _a.call(props, {
                reason: types_2.NavigateToCaptureReason.RETAKE_PICTURE,
                sightId: image.sightId,
            });
        }
        else if (props.captureMode && (image === null || image === void 0 ? void 0 : image.type) === types_1.ImageType.CLOSE_UP) {
            (_b = props.onNavigateToCapture) === null || _b === void 0 ? void 0 : _b.call(props, {
                reason: types_2.NavigateToCaptureReason.ADD_DAMAGE,
            });
        }
    };
    var imageDetailedviewCaptureProps = props.captureMode
        ? {
            captureMode: true,
            showCaptureButton: true,
            onNavigateToCapture: function () { var _a; return (_a = props.onNavigateToCapture) === null || _a === void 0 ? void 0 : _a.call(props, { reason: types_2.NavigateToCaptureReason.NONE }); },
            onRetake: function () { return handleRetakeImage(selectedImage); },
        }
        : { captureMode: false };
    if (selectedImage) {
        return ((0, jsx_runtime_1.jsx)(ImageDetailedView_1.ImageDetailedView, __assign({ lang: props.lang, image: selectedImage, showGalleryButton: true, onClose: function () { return setSelectedImage(null); }, onNavigateToGallery: function () { return setSelectedImage(null); } }, imageDetailedviewCaptureProps)));
    }
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: containerStyle }, { children: [(0, jsx_runtime_1.jsx)(InspectionGalleryTopBar_1.InspectionGalleryTopBar, { items: items, currentFilter: currentFilter, onUpdateFilter: function (filter) { return setCurrentFilter(function (c) { return (c === filter ? null : filter); }); }, showBackButton: props.showBackButton, onBack: props.onBack, captureMode: props.captureMode, onValidate: props.onValidate, allowSkipRetake: props.captureMode && !!props.allowSkipRetake, validateButtonLabel: props.validateButtonLabel, isInspectionCompleted: props.isInspectionCompleted }), (0, jsx_runtime_1.jsxs)("div", __assign({ style: itemListStyle }, { children: [filteredItems.length === 0 && (0, jsx_runtime_1.jsx)("div", __assign({ style: emptyStyle }, { children: emptyLabel })), filteredItems.map(function (item) { return ((0, jsx_runtime_1.jsx)("div", __assign({ style: itemStyle }, { children: (0, jsx_runtime_1.jsx)(InspectionGalleryItemCard_1.InspectionGalleryItemCard, { item: item, captureMode: props.captureMode, onClick: function () { return handleItemClick(item); } }) }), getItemKey(item))); }), filteredItems.length > 0 &&
                        Array.from(Array(fillerCount).keys()).map(function (key) { return ((0, jsx_runtime_1.jsx)("div", { style: fillerItemStyle }, key)); })] }))] })));
}, i18n_1.i18nInspectionGallery);
