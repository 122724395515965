"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTracking = void 0;
var analytics_1 = require("@monkvision/analytics");
var monitoring_1 = require("@monkvision/monitoring");
var network_1 = require("@monkvision/network");
var react_1 = require("react");
/**
 * Custom hook used for adding tags and userId to analytics and monitoring provider.
 */
function useTracking(_a) {
    var inspectionId = _a.inspectionId, authToken = _a.authToken;
    var analytics = (0, analytics_1.useAnalytics)();
    var monitoring = (0, monitoring_1.useMonitoring)();
    (0, react_1.useEffect)(function () {
        var _a;
        analytics.setUserId(inspectionId);
        monitoring.setTags({
            inspectionId: inspectionId,
        });
        var userId = (_a = (0, network_1.decodeMonkJwt)(authToken)) === null || _a === void 0 ? void 0 : _a.sub;
        if (userId) {
            monitoring.setUserId(userId);
            analytics.setUserProperties({ authToken: userId });
        }
    }, [inspectionId, authToken]);
}
exports.useTracking = useTracking;
