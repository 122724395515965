"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getThumbnailUrl = exports.THUMBNAIL_RESIZE_RATIO = void 0;
/**
 * The ratio used to resize the dimensions of the thumbnail.
 */
exports.THUMBNAIL_RESIZE_RATIO = 0.1;
function getResizedDimension(originalDimension) {
    return Math.round(originalDimension * exports.THUMBNAIL_RESIZE_RATIO);
}
/**
 * Util function that generate a thumbnail url.
 */
function getThumbnailUrl(thumbnailDomain, path, dimensions) {
    var baseThumbnailUrl = "https://".concat(thumbnailDomain).concat(thumbnailDomain.endsWith('/') ? '' : '/');
    var imageUrlParam = "image_url=".concat(encodeURIComponent(path));
    var widthUrlParam = "width=".concat(getResizedDimension(dimensions.width));
    var heightUrlParam = "height=".concat(getResizedDimension(dimensions.height));
    return "".concat(baseThumbnailUrl, "?").concat(imageUrlParam, "&").concat(widthUrlParam, "&").concat(heightUrlParam);
}
exports.getThumbnailUrl = getThumbnailUrl;
