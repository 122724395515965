"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskName = void 0;
var TaskName;
(function (TaskName) {
    /**
     * Vehicle pictures analysis executed in order to detect damages on the vehicle.
     */
    TaskName["DAMAGE_DETECTION"] = "damage_detection";
    /**
     * Wheel pictures analysis executed in order to output information on the wheel.
     */
    TaskName["WHEEL_ANALYSIS"] = "wheel_analysis";
    /**
     * Estimation of the types of reparations needed to repair damages on a vehicle.
     */
    TaskName["REPAIR_ESTIMATE"] = "repair_estimate";
    /**
     * Optical character recognition performed on inspection images in order to output text written on the pictures.
     */
    TaskName["IMAGES_OCR"] = "images_ocr";
    /**
     * Image editing (background removal etc.).
     */
    TaskName["IMAGE_EDITING"] = "image_editing";
    /**
     * Generation of an inspection report as a PDF file.
     */
    TaskName["INSPECTION_PDF"] = "inspection_pdf";
    /**
     * Estimation of the cost of reparations needed to repair damages on a vehicle.
     */
    TaskName["PRICING"] = "pricing";
    /**
     * Optical character recognition performed on images of vehicle dashboards in order to output information available on
     * the dashboard (car mileage etc.)
     */
    TaskName["DASHBOARD_OCR"] = "dashboard_ocr";
    /**
     * Compliance checks like image quality, car coverage etc. You don't have to add this task to your inspection or to
     * your images, it is added by default when adding the dammage detection task. However, you can specify this task if
     * you want to specify additional options used to configure how the compliance is executed (like specifying the
     * sight_id of the image for instance).
     */
    TaskName["COMPLIANCES"] = "compliances";
    /**
     * Human in the Loop is a service to include a layer of manual damage detection on top of AI damage detection. After
     * damages are detected they are sent to an annotator that will review them and edit them if needed.
     */
    TaskName["HUMAN_IN_THE_LOOP"] = "human_in_the_loop";
})(TaskName = exports.TaskName || (exports.TaskName = {}));
