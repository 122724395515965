"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCameraPreview = void 0;
var monitoring_1 = require("@monkvision/monitoring");
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var utils_1 = require("./utils");
var useUserMedia_1 = require("./useUserMedia");
/**
 * Custom hook used to initialize and handle the camera preview. It initializes the camera stream based on the given
 * configuration, and provides a handle to manage the camera such as the ref to the video element etc.
 */
function useCameraPreview(config) {
    var ref = (0, react_1.useRef)(null);
    var windowDimensions = (0, common_1.useWindowDimensions)();
    var handleError = (0, monitoring_1.useMonitoring)().handleError;
    var userMediaResult = (0, useUserMedia_1.useUserMedia)((0, utils_1.getMediaConstraints)(config), ref);
    var previewDimensions = (0, react_1.useMemo)(function () {
        if (!windowDimensions || !userMediaResult.dimensions) {
            return null;
        }
        var windowAspectRatio = windowDimensions.width / windowDimensions.height;
        var streamAspectRatio = userMediaResult.dimensions.width / userMediaResult.dimensions.height;
        return windowAspectRatio >= streamAspectRatio
            ? {
                width: windowDimensions.height * streamAspectRatio,
                height: windowDimensions.height,
            }
            : {
                width: windowDimensions.width,
                height: windowDimensions.width / streamAspectRatio,
            };
    }, [windowDimensions, userMediaResult.dimensions]);
    (0, react_1.useEffect)(function () {
        if (userMediaResult.stream && ref.current) {
            ref.current.srcObject = userMediaResult.stream;
            ref.current.onloadedmetadata = function () {
                var _a;
                (_a = ref.current) === null || _a === void 0 ? void 0 : _a.play().catch(handleError);
            };
        }
    }, [userMediaResult.stream]);
    return (0, react_1.useMemo)(function () { return (__assign({ ref: ref, previewDimensions: previewDimensions }, userMediaResult)); }, [previewDimensions, userMediaResult]);
}
exports.useCameraPreview = useCameraPreview;
