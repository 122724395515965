"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehiclePartSelection = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("@monkvision/types");
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var icons_1 = require("../../icons");
var VehicleDynamicWireframe_1 = require("../VehicleDynamicWireframe");
var VehiclePartSelection_style_1 = require("./VehiclePartSelection.style");
var ORIENTATIONS = [
    types_1.PartSelectionOrientation.FRONT_LEFT,
    types_1.PartSelectionOrientation.REAR_LEFT,
    types_1.PartSelectionOrientation.REAR_RIGHT,
    types_1.PartSelectionOrientation.FRONT_RIGHT,
];
/**
 * Component that displays a rotatable vehicle wireframe that allows the user to select multiple vehicle parts.
 */
function VehiclePartSelection(_a) {
    var vehicleType = _a.vehicleType, initialOrientation = _a.orientation, _b = _a.onPartsSelected, onPartsSelected = _b === void 0 ? function () { } : _b;
    var _c = (0, react_1.useState)(initialOrientation !== null && initialOrientation !== void 0 ? initialOrientation : ORIENTATIONS[0]), orientation = _c[0], setOrientation = _c[1];
    var _d = (0, react_1.useState)([]), selectedParts = _d[0], setSelectedParts = _d[1];
    var palette = (0, common_1.useMonkTheme)().palette;
    var rotateRight = function () {
        var currentIndex = ORIENTATIONS.indexOf(orientation);
        var nextIndex = (currentIndex + 1) % ORIENTATIONS.length;
        setOrientation(ORIENTATIONS[nextIndex]);
    };
    var rotateLeft = function () {
        var currentIndex = ORIENTATIONS.indexOf(orientation);
        var nextIndex = (currentIndex - 1 + ORIENTATIONS.length) % ORIENTATIONS.length;
        setOrientation(ORIENTATIONS[nextIndex]);
    };
    var togglePart = function (part) {
        var newSelectedParts = selectedParts.includes(part)
            ? selectedParts.filter(function (p) { return p !== part; })
            : __spreadArray(__spreadArray([], selectedParts, true), [part], false);
        setSelectedParts(newSelectedParts);
        onPartsSelected(newSelectedParts);
    };
    var getPartAttributes = function (part) { return ({
        style: {
            // TODO: need to finalize the color for the selected parts.
            fill: selectedParts.includes(part) ? palette.primary.xlight : undefined,
            stroke: palette.primary.light,
            display: 'block',
        },
    }); };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: VehiclePartSelection_style_1.styles['wrapper'] }, { children: [(0, jsx_runtime_1.jsx)(icons_1.Icon, { icon: 'rotate-left', primaryColor: 'text-primary', onClick: rotateRight }), (0, jsx_runtime_1.jsx)(VehicleDynamicWireframe_1.VehicleDynamicWireframe, { vehicleType: vehicleType, orientation: orientation, onClickPart: togglePart, getPartAttributes: getPartAttributes }), (0, jsx_runtime_1.jsx)(icons_1.Icon, { icon: 'rotate-right', primaryColor: 'text-primary', onClick: rotateLeft })] })));
}
exports.VehiclePartSelection = VehiclePartSelection;
