"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSwitchButtonStyles = void 0;
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var types_1 = require("@monkvision/types");
var SwitchButton_styles_1 = require("./SwitchButton.styles");
function useSwitchButtonStyles(params) {
    var utils = (0, common_1.useMonkTheme)().utils;
    var knobOverlayColors = (0, react_1.useMemo)(function () {
        var _a;
        return (_a = {},
            _a[types_1.InteractiveStatus.DEFAULT] = '#00000000',
            _a[types_1.InteractiveStatus.HOVERED] = (0, common_1.changeAlpha)(utils.getColor(params.checkedPrimaryColor), 0.18),
            _a[types_1.InteractiveStatus.ACTIVE] = (0, common_1.changeAlpha)(utils.getColor(params.checkedPrimaryColor), 0.3),
            _a[types_1.InteractiveStatus.DISABLED] = '#00000000',
            _a);
    }, [params.checkedPrimaryColor, utils]);
    return (0, react_1.useMemo)(function () { return ({
        buttonStyle: __assign(__assign(__assign(__assign({}, SwitchButton_styles_1.styles['button']), (params.status === types_1.InteractiveStatus.DISABLED ? SwitchButton_styles_1.styles['buttonDisabled'] : {})), (params.size === 'small' ? SwitchButton_styles_1.styles['buttonSmall'] : {})), { backgroundColor: params.checked
                ? utils.getColor(params.checkedPrimaryColor)
                : utils.getColor(params.uncheckedPrimaryColor) }),
        icon: {
            color: utils.getColor(params.checkedSecondaryColor),
            size: SwitchButton_styles_1.sizes.normal.iconSize,
            style: __assign(__assign({}, SwitchButton_styles_1.styles['icon']), { opacity: params.checked ? 1 : 0 }),
        },
        checkedLabelStyle: __assign(__assign({}, SwitchButton_styles_1.styles['label']), { color: utils.getColor(params.checkedSecondaryColor), opacity: params.checked ? 1 : 0 }),
        uncheckedLabelStyle: __assign(__assign({}, SwitchButton_styles_1.styles['label']), { color: utils.getColor(params.uncheckedSecondaryColor), opacity: params.checked ? 0 : 1 }),
        knobOverlayStyle: __assign(__assign(__assign(__assign(__assign({}, SwitchButton_styles_1.styles['knobOverlay']), (params.size === 'small' ? SwitchButton_styles_1.styles['knobOverlaySmall'] : {})), (params.checked ? SwitchButton_styles_1.styles['knobOverlayChecked'] : {})), (params.checked && params.size === 'small' ? SwitchButton_styles_1.styles['knobOverlaySmallChecked'] : {})), { backgroundColor: knobOverlayColors[params.status] }),
        knobStyle: __assign(__assign(__assign(__assign(__assign({}, SwitchButton_styles_1.styles['knob']), (params.size === 'small' ? SwitchButton_styles_1.styles['knobSmall'] : {})), (params.checked ? SwitchButton_styles_1.styles['knobChecked'] : {})), (params.checked && params.size === 'small' ? SwitchButton_styles_1.styles['knobSmallChecked'] : {})), { backgroundColor: params.checked
                ? utils.getColor(params.checkedSecondaryColor)
                : utils.getColor(params.uncheckedSecondaryColor) }),
        iconSmall: {
            color: utils.getColor(params.checkedPrimaryColor),
            size: SwitchButton_styles_1.sizes.small.iconSize,
            style: __assign(__assign({}, SwitchButton_styles_1.styles['icon']), { opacity: params.checked ? 1 : 0 }),
        },
    }); }, [utils, params, knobOverlayColors]);
}
exports.useSwitchButtonStyles = useSwitchButtonStyles;
