"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInteractiveStatus = void 0;
var react_1 = require("react");
var types_1 = require("@monkvision/types");
function getInteractiveStatus(_a) {
    var _b = _a.hovered, hovered = _b === void 0 ? false : _b, _c = _a.active, active = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d;
    if (disabled) {
        return types_1.InteractiveStatus.DISABLED;
    }
    if (active) {
        return types_1.InteractiveStatus.ACTIVE;
    }
    return hovered ? types_1.InteractiveStatus.HOVERED : types_1.InteractiveStatus.DEFAULT;
}
/**
 * Custom hook used to manage the interactive state (active, hovered, disabled...) of a React element. This hook returns
 * the state of the element, as well as MouseEvent listeners needed on the element to update its interactive state.
 *
 * @example
 * function TestComponent() {
 *   const { status, events } = useInteractiveStatus();
 *   useEffect(() => console.log('Button status :', status), [status]);
 *   return <button {...events}>My Button</button>;
 * }
 */
function useInteractiveStatus(params) {
    var _a, _b, _c, _d;
    var _e = (0, react_1.useState)(false), hovered = _e[0], setHovered = _e[1];
    var _f = (0, react_1.useState)(false), active = _f[0], setActive = _f[1];
    var onMouseEnter = (0, react_1.useCallback)(function (event) {
        var _a;
        setHovered(true);
        if ((_a = params === null || params === void 0 ? void 0 : params.componentHandlers) === null || _a === void 0 ? void 0 : _a.onMouseEnter) {
            params.componentHandlers.onMouseEnter(event);
        }
    }, [(_a = params === null || params === void 0 ? void 0 : params.componentHandlers) === null || _a === void 0 ? void 0 : _a.onMouseEnter]);
    var onMouseLeave = (0, react_1.useCallback)(function (event) {
        var _a;
        setHovered(false);
        if ((_a = params === null || params === void 0 ? void 0 : params.componentHandlers) === null || _a === void 0 ? void 0 : _a.onMouseLeave) {
            params.componentHandlers.onMouseLeave(event);
        }
    }, [(_b = params === null || params === void 0 ? void 0 : params.componentHandlers) === null || _b === void 0 ? void 0 : _b.onMouseLeave]);
    var onMouseDown = (0, react_1.useCallback)(function (event) {
        var _a;
        setActive(true);
        if ((_a = params === null || params === void 0 ? void 0 : params.componentHandlers) === null || _a === void 0 ? void 0 : _a.onMouseDown) {
            params.componentHandlers.onMouseDown(event);
        }
    }, [(_c = params === null || params === void 0 ? void 0 : params.componentHandlers) === null || _c === void 0 ? void 0 : _c.onMouseDown]);
    var onMouseUp = (0, react_1.useCallback)(function (event) {
        var _a;
        setActive(false);
        if ((_a = params === null || params === void 0 ? void 0 : params.componentHandlers) === null || _a === void 0 ? void 0 : _a.onMouseUp) {
            params.componentHandlers.onMouseUp(event);
        }
    }, [(_d = params === null || params === void 0 ? void 0 : params.componentHandlers) === null || _d === void 0 ? void 0 : _d.onMouseUp]);
    return (0, react_1.useMemo)(function () { return ({
        status: getInteractiveStatus({ hovered: hovered, active: active, disabled: params === null || params === void 0 ? void 0 : params.disabled }),
        eventHandlers: {
            onMouseEnter: onMouseEnter,
            onMouseLeave: onMouseLeave,
            onMouseDown: onMouseDown,
            onMouseUp: onMouseUp,
        },
    }); }, [hovered, active, params === null || params === void 0 ? void 0 : params.disabled, onMouseEnter, onMouseLeave, onMouseDown, onMouseUp]);
}
exports.useInteractiveStatus = useInteractiveStatus;
