"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInterval = void 0;
var react_1 = require("react");
/**
 * This custom hook creates an interval that calls the provided callback every `delay` milliseconds. If `delay` is
 * `null` or less than 0, the callback will not be called.
 */
function useInterval(callback, delay) {
    var callbackRef = (0, react_1.useRef)(callback);
    (0, react_1.useLayoutEffect)(function () {
        callbackRef.current = callback;
    }, [callback]);
    (0, react_1.useEffect)(function () {
        if (delay === null || delay < 0) {
            return function () { };
        }
        var intervalId = setInterval(function () {
            callbackRef.current();
        }, delay);
        return function () {
            clearInterval(intervalId);
        };
    }, [delay]);
}
exports.useInterval = useInterval;
