"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBackdropDialogStyles = void 0;
var common_1 = require("@monkvision/common");
var BackdropDialog_styles_1 = require("./BackdropDialog.styles");
function useBackdropDialogStyles(props) {
    var palette = (0, common_1.useMonkTheme)().palette;
    return {
        backdrop: __assign(__assign({}, BackdropDialog_styles_1.styles['backdrop']), { backgroundColor: "rgba(0, 0, 0, ".concat(props.backdropOpacity, ")") }),
        dialog: __assign(__assign({}, BackdropDialog_styles_1.styles['dialog']), { backgroundColor: palette.background.dark }),
        cancelButton: __assign(__assign(__assign({}, BackdropDialog_styles_1.styles['button']), BackdropDialog_styles_1.styles['cancelButton']), { display: props.showCancelButton ? 'flex' : 'none' }),
        confirmButton: __assign(__assign(__assign({}, BackdropDialog_styles_1.styles['button']), BackdropDialog_styles_1.styles['confirmButton']), (!props.showCancelButton ? BackdropDialog_styles_1.styles['cancelButton'] : {})),
    };
}
exports.useBackdropDialogStyles = useBackdropDialogStyles;
