"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Spinner = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var DynamicSVG_1 = require("../DynamicSVG");
var animatedSpinnerSvg = '<svg stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>@keyframes spinner_zKoa{to{transform:rotate(360deg)}}@keyframes spinner_YpZS{0%{stroke-dasharray:0 150;stroke-dashoffset:0}47.5%{stroke-dasharray:42 150;stroke-dashoffset:-16}95%,to{stroke-dasharray:42 150;stroke-dashoffset:-59}}</style><g style="transform-origin:center;animation:spinner_zKoa 2s linear infinite"><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3" style="stroke-linecap:round;animation:spinner_YpZS 1.5s ease-in-out infinite"/></g></svg>';
/**
 * Spinner component that can be used to display a loading spinner.
 */
function Spinner(_a) {
    var _b = _a.size, size = _b === void 0 ? 50 : _b, _c = _a.primaryColor, primaryColor = _c === void 0 ? 'text-white' : _c, passThroughProps = __rest(_a, ["size", "primaryColor"]);
    var utils = (0, common_1.useMonkTheme)().utils;
    var stroke = utils.getColor(primaryColor);
    return ((0, jsx_runtime_1.jsx)(DynamicSVG_1.DynamicSVG, __assign({ style: { width: size, height: size }, width: size, height: size, svg: animatedSpinnerSvg, getAttributes: function () { return ({ stroke: stroke }); } }, passThroughProps)));
}
exports.Spinner = Spinner;
