"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
exports.styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000000',
    },
    cameraPreview: {
        width: '100%',
        height: '100%',
    },
    cameraCanvas: {
        display: 'none',
    },
};
