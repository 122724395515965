"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhotoCaptureHUDElementsAddDamage1stShot = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var hooks_1 = require("../hooks");
var PhotoCaptureHUDElementsAddDamage1stShot_styles_1 = require("./PhotoCaptureHUDElementsAddDamage1stShot.styles");
var PhotoCaptureHUDCounter_1 = require("../PhotoCaptureHUDCounter");
var hooks_2 = require("../../hooks");
var PhotoCaptureHUDCancelButton_1 = require("../PhotoCaptureHUDCancelButton");
var assets_1 = require("../../../assets");
var hooks_3 = require("./hooks");
/**
 * Component implementing an HUD displayed on top of the Camera preview during the PhotoCapture process when the current
 * mode is ADD_DAMAGE_1ST_SHOT.
 */
function PhotoCaptureHUDElementsAddDamage1stShot(_a) {
    var onCancel = _a.onCancel;
    var _b = (0, react_1.useState)(true), showInfoPopup = _b[0], setShowInfoPopup = _b[1];
    var t = (0, react_i18next_1.useTranslation)().t;
    var primaryColor = (0, hooks_1.usePhotoCaptureHUDButtonBackground)();
    var style = (0, hooks_3.usePhotoCaptureHUDElementsAddDamage1stShotStyles)();
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: PhotoCaptureHUDElementsAddDamage1stShot_styles_1.styles['container'] }, { children: [(0, jsx_runtime_1.jsx)(common_ui_web_1.DynamicSVG, { style: PhotoCaptureHUDElementsAddDamage1stShot_styles_1.styles['svg'], svg: assets_1.crosshairSvg }), (0, jsx_runtime_1.jsxs)("div", __assign({ style: style.top }, { children: [(0, jsx_runtime_1.jsx)(PhotoCaptureHUDCounter_1.PhotoCaptureHUDCounter, { mode: hooks_2.PhotoCaptureMode.ADD_DAMAGE_1ST_SHOT }), (0, jsx_runtime_1.jsx)(PhotoCaptureHUDCancelButton_1.PhotoCaptureHUDCancelButton, { onCancel: onCancel })] })), showInfoPopup && ((0, jsx_runtime_1.jsx)(common_ui_web_1.Button, __assign({ icon: 'close', primaryColor: primaryColor, style: style.infoBtn, onClick: function () { return setShowInfoPopup(false); } }, { children: t('photo.hud.addDamage.infoBtn') })))] })));
}
exports.PhotoCaptureHUDElementsAddDamage1stShot = PhotoCaptureHUDElementsAddDamage1stShot;
