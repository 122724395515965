"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTokenExpired = exports.isUserAuthorized = exports.decodeMonkJwt = void 0;
var jwt_decode_1 = require("jwt-decode");
/**
 * Decode the given Monk auth token and returns the MonkJwtPayload.
 */
function decodeMonkJwt(token) {
    return (0, jwt_decode_1.jwtDecode)(token);
}
exports.decodeMonkJwt = decodeMonkJwt;
/**
 * Utility function that checks if the given user has all the required authroizations. You can either pass an auth token
 * to be decoded or the JWT payload directly.
 */
function isUserAuthorized(tokenOrPayload, permissions) {
    if (!tokenOrPayload) {
        return false;
    }
    var payload = typeof tokenOrPayload === 'object' ? tokenOrPayload : decodeMonkJwt(tokenOrPayload);
    return permissions.every(function (requiredPermission) { var _a; return (_a = payload.permissions) === null || _a === void 0 ? void 0 : _a.includes(requiredPermission); });
}
exports.isUserAuthorized = isUserAuthorized;
/**
 * Utility function that checks if an authorization token is expired or not. You can either pass an auth token to be
 * decoded or the JWT payload directly.
 */
function isTokenExpired(tokenOrPayload) {
    if (!tokenOrPayload) {
        return false;
    }
    var payload = typeof tokenOrPayload === 'object' ? tokenOrPayload : decodeMonkJwt(tokenOrPayload);
    return !payload.exp || Math.round(Date.now() / 1000) >= payload.exp;
}
exports.isTokenExpired = isTokenExpired;
