"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInnerHTML = void 0;
var react_1 = require("react");
/**
 * Custom hook used to generate the inner HTML of an SVG element encapsulated by an SVGElement component. This hook
 * will properly handle special cases (such as `<style>` tags with inner text etc.), and will use the `getInnerText`
 * customization function to generate the element's inner HTML for general cases.
 */
function useInnerHTML(_a) {
    var element = _a.element, groups = _a.groups, getInnerText = _a.getInnerText;
    return (0, react_1.useMemo)(function () {
        if (element.tagName === 'style') {
            return element.innerHTML;
        }
        return getInnerText ? getInnerText(element, groups) : null;
    }, [element, groups, getInnerText]);
}
exports.useInnerHTML = useInnerHTML;
