"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flatMap = exports.flatten = exports.uniq = exports.permutations = void 0;
/**
 * Returns an array containing all the possible permutations of the given array.
 */
function permutations(array) {
    var result = [];
    array.forEach(function (_, index) {
        var rest = permutations(array.slice(0, index).concat(array.slice(index + 1)));
        if (rest.length === 0) {
            result.push([array[index]]);
        }
        else {
            for (var j = 0; j < rest.length; j++) {
                result.push([array[index]].concat(rest[j]));
            }
        }
    });
    return result;
}
exports.permutations = permutations;
/**
 * Return a copy of the given array in which all duplicates have been removed.
 */
function uniq(array) {
    var indexablePrimitives = {
        number: {},
        string: {},
    };
    var objects = [];
    return array.filter(function (item) {
        var type = typeof item;
        if (type in indexablePrimitives) {
            var primitiveType = type;
            var primitiveItem = item;
            if (Object.hasOwn(indexablePrimitives[primitiveType], primitiveItem)) {
                return false;
            }
            indexablePrimitives[primitiveType][primitiveItem] = true;
            return true;
        }
        return objects.indexOf(item) >= 0 ? false : objects.push(item);
    });
}
exports.uniq = uniq;
/**
 * Flatten the given array.
 *
 * @example
 * console.log(flatten([ 1, [2, 3], [[4], [5, 6]]]));
 * // Output : 1,2,3,4,5,6
 */
function flatten(array) {
    return array.reduce(function (acc, val) {
        if (Array.isArray(val)) {
            acc.push.apply(acc, flatten(val));
        }
        else {
            acc.push(val);
        }
        return acc;
    }, []);
}
exports.flatten = flatten;
/**
 * JS implementation of the
 * [Array.prototype.flatMap](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flatMap)
 * method, available on all versions of JavaScript.
 */
function flatMap(array, map) {
    return flatten(array.map(map));
}
exports.flatMap = flatMap;
