"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhotoCaptureHUDElementsAddDamage2ndShot = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var common_1 = require("@monkvision/common");
var hooks_1 = require("../../hooks");
var PhotoCaptureHUDElementsAddDamage2ndShot_styles_1 = require("./PhotoCaptureHUDElementsAddDamage2ndShot.styles");
var PhotoCaptureHUDCounter_1 = require("../PhotoCaptureHUDCounter");
var PhotoCaptureHUDCancelButton_1 = require("../PhotoCaptureHUDCancelButton");
var hooks_2 = require("./hooks");
function getAspectRatio(streamDimensions) {
    if ((0, common_1.isMobileDevice)() && streamDimensions) {
        return "".concat(streamDimensions === null || streamDimensions === void 0 ? void 0 : streamDimensions.width, "/").concat(streamDimensions === null || streamDimensions === void 0 ? void 0 : streamDimensions.height);
    }
    return '16/9';
}
/**
 * Component implementing an HUD displayed on top of the Camera preview during the PhotoCapture process when the current
 * mode is ADD_DAMAGE_2ND_SHOT.
 */
function PhotoCaptureHUDElementsAddDamage2ndShot(_a) {
    var onCancel = _a.onCancel, streamDimensions = _a.streamDimensions;
    var t = (0, react_i18next_1.useTranslation)().t;
    var style = (0, hooks_2.usePhotoCaptureHUDElementsAddDamage2ndShotStyle)();
    var aspectRatio = getAspectRatio(streamDimensions);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: PhotoCaptureHUDElementsAddDamage2ndShot_styles_1.styles['container'] }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: __assign(__assign({}, PhotoCaptureHUDElementsAddDamage2ndShot_styles_1.styles['frameContainer']), { aspectRatio: aspectRatio }), "data-testid": 'frame-container' }, { children: (0, jsx_runtime_1.jsx)("div", { style: style.frame }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ style: style.top }, { children: [(0, jsx_runtime_1.jsx)(PhotoCaptureHUDCounter_1.PhotoCaptureHUDCounter, { mode: hooks_1.PhotoCaptureMode.ADD_DAMAGE_2ND_SHOT }), (0, jsx_runtime_1.jsx)(PhotoCaptureHUDCancelButton_1.PhotoCaptureHUDCancelButton, { onCancel: onCancel })] })), (0, jsx_runtime_1.jsx)("div", __assign({ style: style.infoCloseup }, { children: t('photo.hud.addDamage.infoCloseup') }))] })));
}
exports.PhotoCaptureHUDElementsAddDamage2ndShot = PhotoCaptureHUDElementsAddDamage2ndShot;
