"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleCameraHUD = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var common_1 = require("@monkvision/common");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var i18n_1 = require("../i18n");
var SimpleCameraHUD_styles_1 = require("./SimpleCameraHUD.styles");
var utils_1 = require("../utils");
/**
 * The basic Camera HUD provided by the Monk camera package. It displays a button to take pictures, as well as error
 * messages (and a retry button) in case of errors with the Camera stream.
 */
exports.SimpleCameraHUD = (0, common_1.i18nWrap)(function SimpleCameraHUD(_a) {
    var _b;
    var cameraPreview = _a.cameraPreview, handle = _a.handle, lang = _a.lang;
    (0, common_1.useI18nSync)(lang);
    var t = (0, react_i18next_1.useTranslation)().t;
    var tObj = (0, common_1.useObjectTranslation)().tObj;
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    var isHUDDisabled = handle.isLoading || !!handle.error;
    var errorLabel = (0, utils_1.getCameraErrorLabel)((_b = handle.error) === null || _b === void 0 ? void 0 : _b.type);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: __assign(__assign({}, SimpleCameraHUD_styles_1.styles['container']), responsive(SimpleCameraHUD_styles_1.styles['containerPortrait'])) }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: SimpleCameraHUD_styles_1.styles['previewContainer'] }, { children: cameraPreview })), !handle.isLoading && !!handle.error && errorLabel && ((0, jsx_runtime_1.jsxs)("div", __assign({ style: SimpleCameraHUD_styles_1.styles['messageContainer'] }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ "data-testid": 'error-message', style: __assign(__assign({}, SimpleCameraHUD_styles_1.styles['errorMessage']), responsive(SimpleCameraHUD_styles_1.styles['errorMessagePortrait'])) }, { children: tObj(errorLabel) })), (0, jsx_runtime_1.jsx)(common_ui_web_1.Button, __assign({ style: SimpleCameraHUD_styles_1.styles['retryButton'], variant: 'outline', icon: 'refresh', onClick: handle.retry }, { children: t('retry') }))] }))), (0, jsx_runtime_1.jsx)(common_ui_web_1.TakePictureButton, { style: __assign(__assign({}, SimpleCameraHUD_styles_1.styles['takePictureButton']), responsive(SimpleCameraHUD_styles_1.styles['takePicturePortrait'])), disabled: isHUDDisabled, onClick: handle.takePicture, size: 60 })] })));
}, i18n_1.i18nCamera);
