"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCheckboxStyles = void 0;
var types_1 = require("@monkvision/types");
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var Checkbox_styles_1 = require("./Checkbox.styles");
function useCheckboxStyles(props) {
    var utils = (0, common_1.useMonkTheme)().utils;
    var colors = (0, react_1.useMemo)(function () {
        var primary = utils.getColor(props.primaryColor);
        var secondary = utils.getColor(props.secondaryColor);
        var tertiary = utils.getColor(props.tertiaryColor);
        var label = utils.getColor(props.labelColor);
        return {
            primary: primary,
            primary5: (0, common_1.changeAlpha)(primary, 0.05),
            primary18: (0, common_1.changeAlpha)(primary, 0.18),
            secondary: secondary,
            tertiary: tertiary,
            tertiary5: (0, common_1.changeAlpha)(tertiary, 0.05),
            tertiary18: (0, common_1.changeAlpha)(tertiary, 0.18),
            tertiary50: (0, common_1.changeAlpha)(tertiary, 0.5),
            label: label,
        };
    }, [props.primaryColor, props.secondaryColor, props.tertiaryColor]);
    var interactiveOverlayBackgroundColor = 'transparent';
    if (props.status === types_1.InteractiveStatus.HOVERED) {
        interactiveOverlayBackgroundColor = props.checked ? colors.primary5 : colors.tertiary5;
    }
    else if (props.status === types_1.InteractiveStatus.ACTIVE) {
        interactiveOverlayBackgroundColor = props.checked ? colors.primary18 : colors.tertiary18;
    }
    return {
        checkboxStyles: __assign(__assign(__assign({}, Checkbox_styles_1.styles['checkbox']), (props.status === types_1.InteractiveStatus.DISABLED ? Checkbox_styles_1.styles['checkboxDisabled'] : {})), { backgroundColor: props.checked ? colors.primary : colors.tertiary50, borderColor: props.checked ? colors.primary : colors.tertiary }),
        icon: {
            primaryColor: colors.secondary,
        },
        interactiveOverlayStyle: __assign(__assign({}, Checkbox_styles_1.styles['interactiveOverlay']), { backgroundColor: interactiveOverlayBackgroundColor }),
        labelStyle: __assign(__assign({}, Checkbox_styles_1.styles['label']), { color: colors.label }),
    };
}
exports.useCheckboxStyles = useCheckboxStyles;
