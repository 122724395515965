"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SightSliderButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var types_1 = require("@monkvision/types");
var SightSlider_styles_1 = require("./SightSlider.styles");
function useSliderStyle(_a) {
    var isSelected = _a.isSelected, status = _a.status;
    var sliderStyle = {
        primaryColor: 'background-base',
        disabled: [types_1.ImageStatus.COMPLIANCE_RUNNING, types_1.ImageStatus.UPLOADING, types_1.ImageStatus.SUCCESS].includes(status),
        icon: undefined,
    };
    switch (status) {
        case types_1.ImageStatus.UPLOADING:
            sliderStyle.icon = 'processing';
            break;
        case types_1.ImageStatus.COMPLIANCE_RUNNING:
            sliderStyle.icon = 'processing';
            break;
        case types_1.ImageStatus.SUCCESS:
            sliderStyle.primaryColor = 'primary';
            sliderStyle.icon = 'check-circle';
            break;
        case types_1.ImageStatus.UPLOAD_FAILED:
            sliderStyle.primaryColor = 'alert-dark';
            sliderStyle.icon = 'wifi-off';
            break;
        case types_1.ImageStatus.UPLOAD_ERROR:
            sliderStyle.primaryColor = 'alert-dark';
            sliderStyle.icon = 'sync-problem';
            break;
        case types_1.ImageStatus.NOT_COMPLIANT:
            sliderStyle.primaryColor = 'alert-dark';
            sliderStyle.icon = 'error';
            break;
        default:
            break;
    }
    if (isSelected) {
        sliderStyle.primaryColor = 'primary';
        sliderStyle.disabled = false;
    }
    return sliderStyle;
}
/**
 * Button representing a sight in the PhotoCapture SightSlider component.
 */
function SightSliderButton(_a) {
    var label = _a.label, isSelected = _a.isSelected, status = _a.status, onClick = _a.onClick;
    var _b = useSliderStyle({ isSelected: isSelected, status: status }), primaryColor = _b.primaryColor, disabled = _b.disabled, icon = _b.icon;
    return ((0, jsx_runtime_1.jsx)(common_ui_web_1.Button, __assign({ style: SightSlider_styles_1.styles['button'], icon: icon, primaryColor: primaryColor, onClick: onClick, disabled: disabled }, { children: label })));
}
exports.SightSliderButton = SightSliderButton;
