"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMediaConstraints = exports.getResolutionDimensions = exports.CameraFacingMode = void 0;
var types_1 = require("@monkvision/types");
/**
 * Enumeration of the facing modes for the camera constraints.
 */
var CameraFacingMode;
(function (CameraFacingMode) {
    /**
     * User-facing camera (front camera).
     */
    CameraFacingMode["USER"] = "user";
    /**
     * Enironment-facing camera (back camera).
     */
    CameraFacingMode["ENVIRONMENT"] = "environment";
})(CameraFacingMode = exports.CameraFacingMode || (exports.CameraFacingMode = {}));
var CAMERA_RESOLUTION_DIMENSIONS = (_a = {},
    _a[types_1.CameraResolution.QNHD_180P] = { width: 320, height: 180 },
    _a[types_1.CameraResolution.NHD_360P] = { width: 640, height: 360 },
    _a[types_1.CameraResolution.HD_720P] = { width: 1280, height: 720 },
    _a[types_1.CameraResolution.FHD_1080P] = { width: 1920, height: 1080 },
    _a[types_1.CameraResolution.QHD_2K] = { width: 2560, height: 1440 },
    _a[types_1.CameraResolution.UHD_4K] = { width: 3840, height: 2160 },
    _a);
/**
 * Utility function that returns the dimensions in pixels of the given `CameraResolution`.
 */
function getResolutionDimensions(resolution, isPortrait) {
    if (isPortrait === void 0) { isPortrait = false; }
    var dimensions = CAMERA_RESOLUTION_DIMENSIONS[resolution];
    return {
        width: isPortrait ? dimensions.height : dimensions.width,
        height: isPortrait ? dimensions.width : dimensions.height,
    };
}
exports.getResolutionDimensions = getResolutionDimensions;
/**
 * This function is used by the Monk Camera package in order to add a layer of abstraction to the media constraints
 * passed to the `useUserMedia` hook. It takes an optional `CameraOptions` parameter and creates a
 * `MediaStreamConstraints` corresponding to the given options. The default option for each `CameraOptions` field can be
 * found in their respsective tsdoc descriptions, refer to the `CameraOptions` interface for more details.
 *
 * @see CameraConfig
 * @see useUserMedia
 */
function getMediaConstraints(config) {
    var _a = getResolutionDimensions(config.resolution), width = _a.width, height = _a.height;
    var video = {
        width: { ideal: width },
        height: { ideal: height },
        facingMode: config.facingMode,
    };
    return {
        audio: false,
        video: video,
    };
}
exports.getMediaConstraints = getMediaConstraints;
