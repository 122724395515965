"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SVGElement = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var hooks_1 = require("./hooks");
function getChildrenGroupIds(_a) {
    var element = _a.element, groups = _a.groups;
    return element.tagName === 'g' ? __spreadArray(__spreadArray([], groups, true), [element], false) : groups;
}
/**
 * A custom component used to encapsulate an SVG element (usually parsed from a string using the `useXMLParser` hook).
 * It displays the SVG element as well as its children, and applies cusotm properties to it so that the whole SVG image
 * can be customized dynamically.
 *
 * *Note : As a developer using the MonkJs SDK, you probably don't need to use this component anywhere in your code. If
 * you need to display a dynamic SVG, you can directly use the DynamicSVG component available and exported in this
 * package, that will handle the XML parsing for you.*
 */
function SVGElement(_a) {
    var _b;
    var element = _a.element, _c = _a.groups, groups = _c === void 0 ? [] : _c, getAttributes = _a.getAttributes, getInnerText = _a.getInnerText, passThroughProps = __rest(_a, ["element", "groups", "getAttributes", "getInnerText"]);
    var Tag = element.tagName;
    var attributes = (0, hooks_1.useJSXTransformAttributes)(element);
    var customAttributes = (0, hooks_1.useCustomAttributes)({
        element: element,
        groups: groups,
        getAttributes: getAttributes,
        style: (_b = attributes.style) !== null && _b !== void 0 ? _b : {},
    });
    var tagAttr = __assign(__assign(__assign({}, attributes), customAttributes), passThroughProps);
    var innerHTML = (0, hooks_1.useInnerHTML)({ element: element, groups: groups, getInnerText: getInnerText });
    var childrenGroupIds = getChildrenGroupIds({ element: element, groups: groups });
    return ((0, jsx_runtime_1.jsx)(Tag, __assign({}, tagAttr, { children: __spreadArray([
            innerHTML
        ], Array.from(element.children).map(function (child, id) { return ((0, jsx_runtime_1.jsx)(SVGElement, { element: child, groups: childrenGroupIds, getAttributes: getAttributes, getInnerText: getInnerText }, id.toString())); }), true) })));
}
exports.SVGElement = SVGElement;
