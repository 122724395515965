"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrowIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var hooks_1 = require("../../hooks");
var PhotoCaptureHUDTutorial_styles_1 = require("./PhotoCaptureHUDTutorial.styles");
var assets_1 = require("../../../assets");
function ArrowIcon(_a) {
    var tutorialStep = _a.tutorialStep;
    if (tutorialStep === hooks_1.TutorialSteps.GUIDELINE) {
        return (0, jsx_runtime_1.jsx)(common_ui_web_1.DynamicSVG, { style: PhotoCaptureHUDTutorial_styles_1.styles['arrowGuideline'], svg: assets_1.arrowGuidelineSVG });
    }
    if (tutorialStep === hooks_1.TutorialSteps.SIGHT_TUTORIAL) {
        return (0, jsx_runtime_1.jsx)(common_ui_web_1.DynamicSVG, { style: PhotoCaptureHUDTutorial_styles_1.styles['arrowSightTutorial'], svg: assets_1.arrowSightTutorialSVG });
    }
    return null;
}
exports.ArrowIcon = ArrowIcon;
