"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchParams = void 0;
var react_1 = require("react");
/**
 * Custom hook used to fetch search params from the current window URL.
 */
function useSearchParams() {
    return (0, react_1.useMemo)(function () { return new URL(window.location.toString()).searchParams; }, []);
}
exports.useSearchParams = useSearchParams;
