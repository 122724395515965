"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInspectionPoll = void 0;
var common_1 = require("@monkvision/common");
var monitoring_1 = require("@monkvision/monitoring");
var react_1 = require("../react");
/**
 * Custom hook used to fetch an inspection every `delay` milliseconds using the `getInspection` API request. To stop the
 * hook from making requests, simply pass a `null` vlaue for the `delay` param.
 */
function useInspectionPoll(_a) {
    var id = _a.id, delay = _a.delay, apiConfig = _a.apiConfig, onSuccess = _a.onSuccess, compliance = _a.compliance;
    var getInspection = (0, react_1.useMonkApi)(apiConfig).getInspection;
    var handleError = (0, monitoring_1.useMonitoring)().handleError;
    (0, common_1.useAsyncInterval)(function () { return getInspection({ id: id, compliance: compliance }); }, delay, {
        onResolve: function (res) { return onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(res.entities); },
        onReject: handleError,
    });
}
exports.useInspectionPoll = useInspectionPoll;
