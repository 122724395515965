"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompressionSizeRatioMeasurement = exports.PictureSizeMeasurement = exports.ScreenshotSizeMeasurement = exports.CompressionMeasurement = exports.ScreenshotMeasurement = exports.TakePictureTransaction = void 0;
exports.TakePictureTransaction = {
    name: 'Take Picture',
    operation: 'take_picture',
    description: 'Transaction created when the Camera component takes a picture. It includes video preview screenshot, compression etc.',
};
exports.ScreenshotMeasurement = {
    operation: 'take_picture.screenshot',
    description: 'Operation corresponding to the screenshot of the Camera component video stream',
    outputResolutionTagName: 'output_resolution',
};
exports.CompressionMeasurement = {
    operation: 'take_picture.compression',
    description: 'Operation corresponding to the compression of a picture taken by the Camera component.',
    formatTagName: 'format',
    qualityTagName: 'quality',
    dimensionsTagName: 'dimensions',
};
exports.ScreenshotSizeMeasurement = {
    name: 'take_picture.screenshot_size',
};
exports.PictureSizeMeasurement = {
    name: 'take_picture.picture_size',
};
exports.CompressionSizeRatioMeasurement = {
    name: 'take_picture.compression_size_ratio',
};
