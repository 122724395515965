"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnvOrThrow = void 0;
/**
 * Utility function that returns the value of a given environment variable. If the value does not exist, it throws an
 * error.
 */
function getEnvOrThrow(name) {
    var value = process.env[name];
    if (!value) {
        throw new Error("Required environment variable ".concat(name, " is not defined."));
    }
    return value;
}
exports.getEnvOrThrow = getEnvOrThrow;
