"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SightSlider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("@monkvision/types");
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var sights_1 = require("@monkvision/sights");
var SightSliderButton_1 = require("./SightSliderButton");
var hooks_1 = require("./hooks");
var scrollToSelectedSight = function (ref, index, smooth) {
    if (ref.current && ref.current.children.length > index) {
        ref.current.children[index].scrollIntoView({
            behavior: smooth ? 'smooth' : 'instant',
            inline: 'center',
        });
    }
};
function useSightSliderItems(sights, images) {
    return (0, react_1.useMemo)(function () {
        return sights.map(function (sight) {
            var _a;
            return ({
                sight: sight,
                status: (_a = images.find(function (image) { return image.sightId === sight.id; })) === null || _a === void 0 ? void 0 : _a.status,
            });
        });
    }, [sights, images]);
}
/**
 * A slider element displayed in the PhotoCapture Camera HUD. It displays a button for each sight of the capture
 * process, allowing user to have indications of the remaining sights and allowing them to change the currently selected
 * sight.
 */
function SightSlider(_a) {
    var sights = _a.sights, selectedSight = _a.selectedSight, sightsTaken = _a.sightsTaken, _b = _a.onSelectedSight, onSelectedSight = _b === void 0 ? function () { } : _b, _c = _a.onRetakeSight, onRetakeSight = _c === void 0 ? function () { } : _c, images = _a.images;
    var items = useSightSliderItems(sights, images);
    var label = (0, common_1.useSightLabel)({ labels: sights_1.labels }).label;
    var container = (0, hooks_1.useSightSliderStyles)().container;
    var ref = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        scrollToSelectedSight(ref, sights.indexOf(selectedSight), true);
    }, [selectedSight, sightsTaken]);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ style: container, ref: ref }, { children: items.map(function (_a) {
            var sight = _a.sight, status = _a.status;
            return ((0, jsx_runtime_1.jsx)(SightSliderButton_1.SightSliderButton, { label: label(sight), isSelected: sight === selectedSight, status: status, onClick: function () {
                    return status === types_1.ImageStatus.NOT_COMPLIANT ? onRetakeSight(sight.id) : onSelectedSight(sight);
                } }, sight.id));
        }) })));
}
exports.SightSlider = SightSlider;
