"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = exports.takePictureButtonColors = void 0;
var common_1 = require("@monkvision/common");
exports.takePictureButtonColors = (0, common_1.getInteractiveVariants)('#f3f3f3', common_1.InteractiveVariation.DARKEN);
exports.styles = {
    outerLayer: {
        borderStyle: 'solid',
        borderRadius: '50%',
        display: 'inline-block',
        cursor: 'pointer',
    },
    outerLayerDisabled: {
        opacity: 0.75,
        cursor: 'default',
    },
    innerLayer: {
        borderRadius: '50%',
        cursor: 'pointer',
    },
    innerLayerDisabled: {
        cursor: 'default',
    },
};
