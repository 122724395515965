"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAdaptiveCameraConfig = void 0;
var types_1 = require("@monkvision/types");
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var DEFAULT_CAMERA_CONFIG = {
    quality: 0.6,
    format: types_1.CompressionFormat.JPEG,
    resolution: types_1.CameraResolution.UHD_4K,
    allowImageUpscaling: false,
};
var CAMERA_RESOLUTION_COMPARISON_MAP = (_a = {},
    _a[types_1.CameraResolution.UHD_4K] = 4000,
    _a[types_1.CameraResolution.QHD_2K] = 2000,
    _a[types_1.CameraResolution.FHD_1080P] = 1000,
    _a[types_1.CameraResolution.HD_720P] = 720,
    _a[types_1.CameraResolution.NHD_360P] = 360,
    _a[types_1.CameraResolution.QNHD_180P] = 180,
    _a);
function getLowestResolutionBetween(a, b) {
    return CAMERA_RESOLUTION_COMPARISON_MAP[a] > CAMERA_RESOLUTION_COMPARISON_MAP[b] ? b : a;
}
var MAX_UPLOAD_DURATION_MS = 15000;
/**
 * Custom hook used to adapt the camera configuration of the PhotoCapture component based on various things suchs as
 * connection quality etc.
 */
function useAdaptiveCameraConfig(_a) {
    var _b, _c, _d, _e;
    var initialCameraConfig = _a.initialCameraConfig, useAdaptiveImageQuality = _a.useAdaptiveImageQuality;
    var _f = (0, react_1.useState)(1), maxQuality = _f[0], setMaxQuality = _f[1];
    var _g = (0, react_1.useState)(types_1.CameraResolution.UHD_4K), maxResolution = _g[0], setMaxResolution = _g[1];
    var _h = (0, react_1.useState)(true), isImageUpscalingAllowed = _h[0], setIsImageUpscalingAllowed = _h[1];
    var lowerMaxImageQuality = function () {
        setMaxQuality(0.6);
        setMaxResolution(types_1.CameraResolution.QHD_2K);
        setIsImageUpscalingAllowed(false);
    };
    var onUploadSuccess = (0, react_1.useCallback)(function (durationMs) {
        if (durationMs > MAX_UPLOAD_DURATION_MS) {
            lowerMaxImageQuality();
        }
    }, []);
    var onUploadTimeout = (0, react_1.useCallback)(function () { return lowerMaxImageQuality(); }, []);
    var config = {
        quality: (_b = initialCameraConfig.quality) !== null && _b !== void 0 ? _b : DEFAULT_CAMERA_CONFIG.quality,
        resolution: (_c = initialCameraConfig.resolution) !== null && _c !== void 0 ? _c : DEFAULT_CAMERA_CONFIG.resolution,
        format: (_d = initialCameraConfig.format) !== null && _d !== void 0 ? _d : DEFAULT_CAMERA_CONFIG.format,
        allowImageUpscaling: (_e = initialCameraConfig.allowImageUpscaling) !== null && _e !== void 0 ? _e : DEFAULT_CAMERA_CONFIG.allowImageUpscaling,
    };
    var adaptiveCameraConfig = (0, react_1.useMemo)(function () {
        var _a;
        var adaptiveConfig = {
            quality: Math.min(maxQuality, config.quality),
            resolution: getLowestResolutionBetween(maxResolution, config.resolution),
            format: (_a = initialCameraConfig.format) !== null && _a !== void 0 ? _a : config.format,
            allowImageUpscaling: isImageUpscalingAllowed && config.allowImageUpscaling,
        };
        return useAdaptiveImageQuality ? adaptiveConfig : config;
    }, [
        config.quality,
        config.resolution,
        config.format,
        config.allowImageUpscaling,
        maxQuality,
        maxResolution,
        isImageUpscalingAllowed,
    ]);
    return (0, common_1.useObjectMemo)({
        adaptiveCameraConfig: adaptiveCameraConfig,
        uploadEventHandlers: {
            onUploadSuccess: onUploadSuccess,
            onUploadTimeout: onUploadTimeout,
        },
    });
}
exports.useAdaptiveCameraConfig = useAdaptiveCameraConfig;
