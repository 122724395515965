"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMonkSearchParams = exports.MonkSearchParam = void 0;
var types_1 = require("@monkvision/types");
var react_1 = require("react");
var hooks_1 = require("../hooks");
var utils_1 = require("../utils");
/**
 * Enumeration of the usual search parameters used by Monk applications. These parameters help configure the application
 * via URL directly.
 */
var MonkSearchParam;
(function (MonkSearchParam) {
    /**
     * Search parameter used to provide an authentication token directly via URL. Note : auth tokens need be compressed
     * using ZLib (ex: using the `zlibCompress` utility function available in this package) and properly URL-encoded
     * before being passed as a URL param. No Monk app should ever use auth tokens obtained from URL search params without
     * compression and encoding.
     *
     * @see zlibCompress
     * @see encodeURIComponent
     */
    MonkSearchParam["TOKEN"] = "t";
    /**
     * Search parameter used to provide an inspection ID to the app to use directly.
     */
    MonkSearchParam["INSPECTION_ID"] = "i";
    /**
     * Search parameter used to specify the vehicle type that the application should use. The list of vehicle types
     * available and supported by the Monk SDK is described in the `VehicleType` enum exported by the `@monkvision/types`
     * package.
     *
     * @see VehicleType
     */
    MonkSearchParam["VEHICLE_TYPE"] = "v";
    /**
     * Search parameter used to specify the language used by the application. The list of languages supported by the Monk
     * SDK is available in the `monkLanguages` array exported by the `@monkvision/types` package.
     *
     * @see monkLanguages
     */
    MonkSearchParam["LANGUAGE"] = "l";
    /**
     * Search parameter used to specify the position of the steering wheel on the car.
     *
     * @see SteeringWheelPosition
     */
    MonkSearchParam["STEERING_WHEEL"] = "s";
})(MonkSearchParam = exports.MonkSearchParam || (exports.MonkSearchParam = {}));
function validateParamValue(value, validValues) {
    var validValuesArray = (Array.isArray(validValues) ? validValues : Object.values(validValues));
    return validValuesArray.includes(value) ? value : null;
}
/**
 * Custom hook used to return a getter function used to fetch the value of different MonkSearchParams.
 *
 * @see MonkSearchParam
 */
function useMonkSearchParams(_a) {
    var _b = _a === void 0 ? {} : _a, availableVehicleTypes = _b.availableVehicleTypes;
    var searchParams = (0, hooks_1.useSearchParams)();
    var get = (0, react_1.useCallback)(function (param) {
        var value = searchParams.get(param);
        switch (param) {
            case MonkSearchParam.TOKEN:
                return value ? (0, utils_1.zlibDecompress)(value) : null;
            case MonkSearchParam.INSPECTION_ID:
                return value;
            case MonkSearchParam.VEHICLE_TYPE:
                return validateParamValue(value, availableVehicleTypes !== null && availableVehicleTypes !== void 0 ? availableVehicleTypes : types_1.VehicleType);
            case MonkSearchParam.STEERING_WHEEL:
                return validateParamValue(value, types_1.SteeringWheelPosition);
            case MonkSearchParam.LANGUAGE:
                return validateParamValue(value, types_1.monkLanguages);
            default:
                return null;
        }
    }, [searchParams]);
    return (0, hooks_1.useObjectMemo)({ get: get });
}
exports.useMonkSearchParams = useMonkSearchParams;
