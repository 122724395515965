"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LiveConfigAppProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var network_1 = require("@monkvision/network");
var monitoring_1 = require("@monkvision/monitoring");
var LiveConfigAppProvider_styles_1 = require("./LiveConfigAppProvider.styles");
var Spinner_1 = require("../Spinner");
var Button_1 = require("../Button");
/**
 * This component is used in Monk web applications that support Live Configurations. It acts as both an automatic
 * live configuration fetcher and a MonkAppStateProvider.
 *
 * @see MonkAppStateProvider
 */
function LiveConfigAppProvider(_a) {
    var id = _a.id, localConfig = _a.localConfig, lang = _a.lang, children = _a.children, passThroughProps = __rest(_a, ["id", "localConfig", "lang", "children"]);
    (0, common_1.useI18nSync)(lang);
    var loading = (0, common_1.useLoadingState)(true);
    var _b = (0, react_1.useState)(null), config = _b[0], setConfig = _b[1];
    var handleError = (0, monitoring_1.useMonitoring)().handleError;
    var fetchLiveConfig = (0, react_1.useCallback)(function () {
        if (localConfig) {
            loading.onSuccess();
            setConfig(localConfig);
            return;
        }
        loading.start();
        setConfig(null);
        network_1.MonkApi.getLiveConfig(id)
            .then(function (result) {
            loading.onSuccess();
            setConfig(result);
        })
            .catch(function (err) {
            handleError(err);
            loading.onError();
        });
    }, [id, localConfig]);
    (0, react_1.useEffect)(function () {
        fetchLiveConfig();
    }, [fetchLiveConfig]);
    if (loading.isLoading || loading.error || !config) {
        return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: LiveConfigAppProvider_styles_1.styles['container'] }, { children: [loading.isLoading && (0, jsx_runtime_1.jsx)(Spinner_1.Spinner, { primaryColor: 'primary', size: 70 }), !loading.isLoading && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: LiveConfigAppProvider_styles_1.styles['errorMessage'], "data-testid": 'error-msg' }, { children: "Unable to fetch application configuration. Please try again in a few minutes." })), (0, jsx_runtime_1.jsx)(Button_1.Button, __assign({ variant: 'outline', icon: 'refresh', onClick: fetchLiveConfig }, { children: "Retry" }))] }))] })));
    }
    return ((0, jsx_runtime_1.jsx)(common_1.MonkAppStateProvider, __assign({ config: config }, passThroughProps, { children: children })));
}
exports.LiveConfigAppProvider = LiveConfigAppProvider;
