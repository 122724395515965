"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAvailableVehicleTypes = void 0;
var array_utils_1 = require("./array.utils");
/**
 * Util function used to extract the list of available vehicle types in a CaptureAppConfig object.
 */
function getAvailableVehicleTypes(config) {
    return (config.enableSteeringWheelPosition
        ? (0, array_utils_1.uniq)(__spreadArray(__spreadArray([], Object.keys(config.sights.left), true), Object.keys(config.sights.right), true))
        : Object.keys(config.sights));
}
exports.getAvailableVehicleTypes = getAvailableVehicleTypes;
