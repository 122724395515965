"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyMonitoringAdapter = void 0;
var defaultOptions = {
    showUnsupportedMethodWarnings: true,
};
/**
 * This class is an empty Monitoring Adapter, that implements all the methods but does nothing. If you use this adapter
 * in your app, whenever one of its monitoring method is invoked, nothing will happen and a warning will be displayed
 * in the console indicating that the specified method is not supported by the current Monitoring Adapter (this
 * behaviour can be configured with the options passed in the constructor). Note however that no error will be either
 * displayed or thrown by this adapter : your app will continue to work normally and dummy instances will be created and
 * return for things like Transactions.
 *
 * When creating your own Monitoring Adapter, you can extend this class if you do not plan on implementing all the
 * features of the Monk Monitoring.
 *
 * @example
 * export class MyCustomMonitoringAdapter extends EmptyMonitoringAdapter {
 *   override log(msg: string): void {
 *     console.log(msg);
 *   }
 *
 *   override handleError(err: Error | string): void {
 *     console.error(err);
 *   }
 * }
 */
var EmptyMonitoringAdapter = /** @class */ (function () {
    function EmptyMonitoringAdapter(optionsParam) {
        this.options = __assign(__assign({}, defaultOptions), (optionsParam !== null && optionsParam !== void 0 ? optionsParam : {}));
    }
    EmptyMonitoringAdapter.prototype.setUserId = function (id) {
        if (this.options.showUnsupportedMethodWarnings) {
            console.warn('Application users are not supported by the current Monk Monitoring Adapter and calling setUserId will have no effect.');
        }
    };
    EmptyMonitoringAdapter.prototype.log = function (msg, context) {
        if (this.options.showUnsupportedMethodWarnings) {
            console.warn('Logging is not supported by the current Monk Monitoring Adapter and calling log will have no effect.');
        }
    };
    EmptyMonitoringAdapter.prototype.handleError = function (err, context) {
        if (this.options.showUnsupportedMethodWarnings) {
            console.warn('Error handling is not supported by the current Monk Monitoring Adapter and calling handleError will have no effect.');
        }
    };
    EmptyMonitoringAdapter.prototype.createTransaction = function (context) {
        if (this.options.showUnsupportedMethodWarnings) {
            console.warn('Transactions are not supported by the current Monk Monitoring Adapter and calling createTransaction will have no effect.');
        }
        return {
            id: '',
            setTag: function () { },
            startMeasurement: function () { },
            stopMeasurement: function () { },
            setMeasurementTag: function () { },
            setMeasurement: function () { },
            finish: function () { },
        };
    };
    EmptyMonitoringAdapter.prototype.setTags = function (tags) {
        if (this.options.showUnsupportedMethodWarnings) {
            console.warn('Tags are not supported by the current Monk Monitoring Adapter and calling setTags will have no effect.');
        }
    };
    return EmptyMonitoringAdapter;
}());
exports.EmptyMonitoringAdapter = EmptyMonitoringAdapter;
