"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInspectionGalleryItemCardStyles = exports.useInspectionGalleryItemStatusIconName = exports.useInspectionGalleryItemLabel = void 0;
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var sights_1 = require("@monkvision/sights");
var types_1 = require("@monkvision/types");
var react_i18next_1 = require("react-i18next");
var InspectionGalleryItemCard_styles_1 = require("./InspectionGalleryItemCard.styles");
function useInspectionGalleryItemLabel(item) {
    var t = (0, react_i18next_1.useTranslation)().t;
    var tObj = (0, common_1.useObjectTranslation)().tObj;
    var label = (0, common_1.useSightLabel)({ labels: sights_1.labels }).label;
    if (item.isAddDamage) {
        return t('card.addDamage');
    }
    if (!item.isTaken) {
        return label(sights_1.sights[item.sightId]);
    }
    return item.image.label ? tObj(item.image.label) : '';
}
exports.useInspectionGalleryItemLabel = useInspectionGalleryItemLabel;
function useInspectionGalleryItemStatusIconName(_a) {
    var item = _a.item, captureMode = _a.captureMode;
    if (!captureMode || item.isAddDamage || !item.isTaken) {
        return null;
    }
    switch (item.image.status) {
        case types_1.ImageStatus.UPLOADING:
            return 'processing';
        case types_1.ImageStatus.COMPLIANCE_RUNNING:
            return 'processing';
        case types_1.ImageStatus.SUCCESS:
            return 'check-circle';
        case types_1.ImageStatus.UPLOAD_FAILED:
            return 'wifi-off';
        case types_1.ImageStatus.UPLOAD_ERROR:
            return 'sync-problem';
        case types_1.ImageStatus.NOT_COMPLIANT:
            return 'error';
        default:
            return null;
    }
}
exports.useInspectionGalleryItemStatusIconName = useInspectionGalleryItemStatusIconName;
function useInspectionGalleryItemCardStyles(_a) {
    var item = _a.item, status = _a.status, captureMode = _a.captureMode;
    var palette = (0, common_1.useMonkTheme)().palette;
    var colors = (0, react_1.useMemo)(function () { return ({
        previewBackground: (0, common_1.changeAlpha)(palette.surface.dark, 0.56),
        previewOverlayBackgroundNetwork: (0, common_1.changeAlpha)(palette.caution.dark, 0.5),
        previewOverlayBackgroundCompliance: (0, common_1.changeAlpha)(palette.alert.base, 0.5),
        labelBackground: (0, common_1.changeAlpha)(palette.surface.dark, 0.32),
    }); }, [palette]);
    var labelColor = palette.text.primary;
    if (status === types_1.InteractiveStatus.HOVERED) {
        labelColor = palette.primary.base;
    }
    if (status === types_1.InteractiveStatus.ACTIVE) {
        labelColor = palette.primary.dark;
    }
    var previewOverlayBackground = 'transparent';
    if (captureMode && !item.isAddDamage && item.isTaken) {
        if ([types_1.ImageStatus.UPLOAD_FAILED, types_1.ImageStatus.UPLOAD_ERROR].includes(item.image.status)) {
            previewOverlayBackground = colors.previewOverlayBackgroundNetwork;
        }
        if (item.image.status === types_1.ImageStatus.NOT_COMPLIANT) {
            previewOverlayBackground = colors.previewOverlayBackgroundCompliance;
        }
    }
    return {
        cardStyle: __assign(__assign({}, InspectionGalleryItemCard_styles_1.styles['card']), { cursor: [types_1.InteractiveStatus.HOVERED, types_1.InteractiveStatus.ACTIVE].includes(status)
                ? 'pointer'
                : 'default' }),
        previewStyle: __assign(__assign({}, InspectionGalleryItemCard_styles_1.styles['preview']), { backgroundColor: colors.previewBackground, backgroundImage: !item.isAddDamage && item.isTaken ? "url(".concat(item.image.thumbnailPath, ")") : 'none' }),
        previewOverlayStyle: __assign(__assign({}, InspectionGalleryItemCard_styles_1.styles['previewOverlay']), { backgroundColor: previewOverlayBackground }),
        labelStyle: __assign(__assign({}, InspectionGalleryItemCard_styles_1.styles['label']), { backgroundColor: colors.labelBackground, color: labelColor }),
        statusIcon: {
            style: InspectionGalleryItemCard_styles_1.styles['statusIcon'],
            size: 20,
            primaryColor: palette.text.primary,
        },
        sightOverlay: {
            height: InspectionGalleryItemCard_styles_1.styles['preview'].height,
        },
        addDamageIcon: {
            size: 40,
            primaryColor: labelColor,
        },
    };
}
exports.useInspectionGalleryItemCardStyles = useInspectionGalleryItemCardStyles;
