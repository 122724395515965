"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useItemListFillers = void 0;
var common_1 = require("@monkvision/common");
var InspectionGallery_styles_1 = require("../InspectionGallery.styles");
var InspectionGalleryItemCard_styles_1 = require("../InspectionGalleryItemCard/InspectionGalleryItemCard.styles");
function useItemListFillers(itemCount) {
    var dimensions = (0, common_1.useWindowDimensions)();
    var itemWidth = InspectionGalleryItemCard_styles_1.CARD_WIDTH_PX + 2 * InspectionGallery_styles_1.ITEM_PADDING_PX;
    var listWidth = dimensions.width - 2 * InspectionGallery_styles_1.GALLERY_PADDING_PX;
    var itemsPerRow = Math.floor(listWidth / itemWidth);
    return itemsPerRow - (itemCount % itemsPerRow);
}
exports.useItemListFillers = useItemListFillers;
