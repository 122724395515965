"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCustomAttributes = void 0;
var react_1 = require("react");
/**
 * Custom hook used to create the custom attributes given to an SVG element encapsulated in an SVGElement component.
 * This hook returns the custom attributes generated using the `getAttributes` customization function, but also adds
 * custom attributes used to make the SVG work properly (removing pointer events on the SVG element etc...).
 */
function useCustomAttributes(_a) {
    var element = _a.element, groups = _a.groups, getAttributes = _a.getAttributes, style = _a.style;
    return (0, react_1.useMemo)(function () {
        var elementTag = element.tagName;
        if (elementTag === 'svg') {
            return { pointerEvents: 'box-none' };
        }
        if (!getAttributes) {
            return { style: style };
        }
        var attributes = getAttributes(element, groups);
        if (elementTag === 'g') {
            attributes.pointerEvents = 'box-none';
        }
        return __assign(__assign({}, attributes), { style: __assign(__assign({}, attributes === null || attributes === void 0 ? void 0 : attributes.style), style) });
    }, [element, groups, getAttributes]);
}
exports.useCustomAttributes = useCustomAttributes;
