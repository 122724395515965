"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = exports.GALLERY_PADDING_PX = exports.ITEM_PADDING_PX = void 0;
var InspectionGalleryItemCard_styles_1 = require("./InspectionGalleryItemCard/InspectionGalleryItemCard.styles");
exports.ITEM_PADDING_PX = 6;
exports.GALLERY_PADDING_PX = 24;
exports.styles = {
    container: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box',
        padding: "".concat(60 + exports.GALLERY_PADDING_PX, "px ").concat(exports.GALLERY_PADDING_PX, "px ").concat(exports.GALLERY_PADDING_PX, "px"),
    },
    containerSmallScreen: {
        __media: { maxWidth: 550 },
        padding: "".concat(60 + exports.GALLERY_PADDING_PX, "px 0 ").concat(exports.GALLERY_PADDING_PX, "px"),
    },
    itemList: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    item: {
        boxSizing: 'border-box',
        padding: exports.ITEM_PADDING_PX,
    },
    fillerItem: {
        width: InspectionGalleryItemCard_styles_1.CARD_WIDTH_PX + 2 * exports.ITEM_PADDING_PX,
        visibility: 'hidden',
    },
    empty: {
        fontSize: 14,
        padding: '50px 50px 0 50px',
    },
};
