"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useJSXTransformAttributes = void 0;
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var utils_1 = require("./utils");
function tranformJsxAttribute(attr) {
    switch (attr.key) {
        case 'class':
            return { key: 'className', value: attr.value };
        case 'xml:space':
            return { key: 'xmlSpace', value: attr.value };
        case 'data-name':
            return { key: 'dataname', value: attr.value };
        case 'style':
            return { key: 'style', value: (0, utils_1.transformInlineCss)(attr.value) };
        default:
            return { key: (0, common_1.toCamelCase)(attr.key), value: attr.value };
    }
}
/**
 * Custom hook used to map HTML attributes to their JSX counterpart (ex: "class" becomes "className", properly mapping
 * inline style values etc.).
 */
function useJSXTransformAttributes(element) {
    return (0, react_1.useMemo)(function () {
        return element.getAttributeNames().reduce(function (prev, attr) {
            var _a;
            var _b = tranformJsxAttribute({
                key: attr,
                value: element.getAttribute(attr),
            }), key = _b.key, value = _b.value;
            return __assign(__assign({}, prev), (_a = {}, _a[key] = value, _a));
        }, {});
    }, [element]);
}
exports.useJSXTransformAttributes = useJSXTransformAttributes;
