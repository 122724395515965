"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleTypeSelection = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var network_1 = require("@monkvision/network");
var monitoring_1 = require("@monkvision/monitoring");
var analytics_1 = require("@monkvision/analytics");
var VehicleTypeSelection_styles_1 = require("./VehicleTypeSelection.styles");
var i18n_1 = require("./i18n");
var Button_1 = require("../Button");
var utils_1 = require("./utils");
var VehicleTypeSelectionCard_1 = require("./VehicleTypeSelectionCard");
var Spinner_1 = require("../Spinner");
function scrollToSelectedVehicleType(ref, index, smooth) {
    if (ref.current && ref.current.children.length > index) {
        ref.current.children[index].scrollIntoView({
            behavior: smooth ? 'smooth' : 'instant',
            inline: 'center',
        });
    }
}
/**
 * A single page component that allows the user to select a vehicle type.
 */
exports.VehicleTypeSelection = (0, common_1.i18nWrap)(function VehicleTypeSelection(props) {
    var _this = this;
    var _a, _b, _c;
    (0, common_1.useI18nSync)(props.lang);
    var getInspection = (0, network_1.useMonkApi)({
        authToken: (_a = props.authToken) !== null && _a !== void 0 ? _a : '',
        apiDomain: (_b = props.apiDomain) !== null && _b !== void 0 ? _b : '',
        thumbnailDomain: (_c = props.thumbnailDomain) !== null && _c !== void 0 ? _c : '',
    }).getInspection;
    var loading = (0, common_1.useLoadingState)();
    var _d = (0, monitoring_1.useMonitoring)(), handleError = _d.handleError, setTags = _d.setTags, setUserId = _d.setUserId;
    var analytics = (0, analytics_1.useAnalytics)();
    var _e = (0, react_1.useState)(true), initialScroll = _e[0], setInitialScroll = _e[1];
    var vehicleTypes = (0, react_1.useMemo)(function () { return (0, utils_1.getVehicleTypes)(props.availableVehicleTypes); }, [props.availableVehicleTypes]);
    var _f = (0, react_1.useState)((0, utils_1.getInitialSelectedVehicleType)(vehicleTypes, props.selectedVehicleType)), selected = _f[0], setSelected = _f[1];
    var t = (0, react_i18next_1.useTranslation)().t;
    var rootStyles = (0, common_1.useMonkTheme)().rootStyles;
    var sliderRef = (0, react_1.useRef)(null);
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    (0, react_1.useEffect)(function () {
        if (props.inspectionId) {
            setTags({ inspectionId: props.inspectionId });
            analytics.setUserId(props.inspectionId);
        }
        var userId = props.authToken ? (0, network_1.decodeMonkJwt)(props.authToken) : undefined;
        if (userId === null || userId === void 0 ? void 0 : userId.sub) {
            setUserId(userId.sub);
            analytics.setUserProperties({ authToken: userId.sub });
        }
    }, [props.inspectionId, props.authToken, analytics, setTags, setUserId]);
    (0, react_1.useEffect)(function () {
        loading.start();
        var fetchInspection = function () { return __awaiter(_this, void 0, void 0, function () {
            var fetchedInspection, vehicleType;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!props.inspectionId) {
                            loading.onSuccess();
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, getInspection({
                                id: props.inspectionId,
                            })];
                    case 1:
                        fetchedInspection = _b.sent();
                        vehicleType = (0, utils_1.getVehicleTypeFromInspection)(fetchedInspection);
                        if (vehicleType && vehicleTypes.includes(vehicleType)) {
                            (_a = props.onSelectVehicleType) === null || _a === void 0 ? void 0 : _a.call(props, vehicleType);
                        }
                        loading.onSuccess();
                        return [2 /*return*/];
                }
            });
        }); };
        fetchInspection().catch(handleError);
    }, [props.inspectionId]);
    (0, react_1.useEffect)(function () {
        var index = vehicleTypes.indexOf(selected);
        if (index >= 0 && !loading.isLoading) {
            scrollToSelectedVehicleType(sliderRef, index, !initialScroll);
            setInitialScroll(false);
        }
    }, [vehicleTypes, selected, loading]);
    var loadingContainer = loading.isLoading ? VehicleTypeSelection_styles_1.styles['loadingContainer'] : {};
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: __assign(__assign(__assign(__assign({}, rootStyles), VehicleTypeSelection_styles_1.styles['container']), responsive(VehicleTypeSelection_styles_1.styles['containerSmall'])), loadingContainer) }, { children: [loading.isLoading && (0, jsx_runtime_1.jsx)(Spinner_1.Spinner, { size: 80 }), !loading.isLoading && !loading.error && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: VehicleTypeSelection_styles_1.styles['title'] }, { children: t('header.title') })), (0, jsx_runtime_1.jsx)(Button_1.Button, __assign({ style: VehicleTypeSelection_styles_1.styles['button'], onClick: function () { var _a; return (_a = props.onSelectVehicleType) === null || _a === void 0 ? void 0 : _a.call(props, selected); } }, { children: t('header.confirm') })), (0, jsx_runtime_1.jsx)("div", __assign({ style: __assign(__assign({}, VehicleTypeSelection_styles_1.styles['sliderContainer']), responsive(VehicleTypeSelection_styles_1.styles['sliderContainerSmall'])) }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ style: VehicleTypeSelection_styles_1.styles['slider'], ref: sliderRef }, { children: vehicleTypes.map(function (v) { return ((0, jsx_runtime_1.jsx)(VehicleTypeSelectionCard_1.VehicleTypeSelectionCard, { vehicleType: v, isSelected: selected === v, onClick: function () { return setSelected(v); } }, v)); }) })) }))] }))] })));
}, i18n_1.i18nVehicleTypeSelection);
