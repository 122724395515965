"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatedVehicle = exports.isUpdatedVehicleAction = void 0;
var monkAction_1 = require("./monkAction");
/**
 * Matcher function that matches a UpdatedVehicle while also inferring its type using TypeScript's type predicate
 * feature.
 */
function isUpdatedVehicleAction(action) {
    return action.type === monkAction_1.MonkActionType.UPDATED_VEHICLE;
}
exports.isUpdatedVehicleAction = isUpdatedVehicleAction;
/**
 * Reducer function for a UpdatedVehicle action.
 */
function updatedVehicle(state, action) {
    var vehicles = state.vehicles, inspections = state.inspections;
    var inspection = inspections.find(function (value) { return value.id === action.payload.inspectionId; });
    var isVehiculeIdMatching = (inspection === null || inspection === void 0 ? void 0 : inspection.vehicle) === action.payload.vehicle.id;
    if (inspection && !isVehiculeIdMatching) {
        inspection.vehicle = action.payload.vehicle.id;
    }
    var index = vehicles.findIndex(function (vehicle) { return vehicle.id === action.payload.vehicle.id; });
    if (index >= 0) {
        vehicles[index] = action.payload.vehicle;
    }
    return __assign(__assign({}, state), { vehicles: __spreadArray([], vehicles, true) });
}
exports.updatedVehicle = updatedVehicle;
