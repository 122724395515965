"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSightLabel = void 0;
var react_1 = require("react");
var useObjectTranslation_1 = require("./useObjectTranslation");
/**
 * Custom hook used to get the label of a sight with the currently selected language.
 */
function useSightLabel(_a) {
    var labels = _a.labels;
    var tObj = (0, useObjectTranslation_1.useObjectTranslation)().tObj;
    var label = (0, react_1.useCallback)(function (sight) {
        var translationObject = labels[sight.label];
        return translationObject ? tObj(translationObject) : "translation-not-found[".concat(sight.label, "]");
    }, [tObj, labels]);
    return { label: label };
}
exports.useSightLabel = useSightLabel;
