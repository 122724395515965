"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InspectionGalleryTopBar = exports.FILTERS = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var react_1 = require("react");
var types_1 = require("@monkvision/types");
var hooks_1 = require("./hooks");
var Button_1 = require("../../Button");
var InspectionGalleryFilterPill_1 = require("./InspectionGalleryFilterPill");
exports.FILTERS = [
    {
        label: 'topBar.retakeFilter',
        callback: function (item) {
            return !item.isAddDamage &&
                item.isTaken &&
                [types_1.ImageStatus.UPLOAD_FAILED, types_1.ImageStatus.NOT_COMPLIANT].includes(item.image.status);
        },
    },
    {
        label: 'topBar.approvedFilter',
        callback: function (item) {
            return !item.isAddDamage && item.isTaken && item.image.status === types_1.ImageStatus.SUCCESS;
        },
    },
];
function InspectionGalleryTopBar(props) {
    var t = (0, react_i18next_1.useTranslation)().t;
    var _a = (0, hooks_1.useInspectionGalleryTopBarStyles)(), barStyle = _a.barStyle, leftContainerStyle = _a.leftContainerStyle, pillContainerStyle = _a.pillContainerStyle, backButtonStyle = _a.backButtonStyle, titleStyle = _a.titleStyle;
    var isSubmitAvailable = (0, react_1.useMemo)(function () {
        return props.items.every(function (item) {
            return item.isAddDamage ||
                (item.isTaken &&
                    item.image.status === types_1.ImageStatus.NOT_COMPLIANT &&
                    props.allowSkipRetake) ||
                (item.isTaken && item.image.status === types_1.ImageStatus.SUCCESS);
        });
    }, [props.items, props.allowSkipRetake]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: barStyle }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ style: leftContainerStyle }, { children: [props.showBackButton && ((0, jsx_runtime_1.jsx)(Button_1.Button, { onClick: props.onBack, variant: 'text', icon: 'arrow-back-ios', primaryColor: 'text-primary', shade: 'light', style: backButtonStyle })), (0, jsx_runtime_1.jsx)("div", __assign({ style: titleStyle }, { children: t('topBar.title') })), props.captureMode && ((0, jsx_runtime_1.jsx)("div", __assign({ style: pillContainerStyle }, { children: exports.FILTERS.map(function (filter) { return ((0, jsx_runtime_1.jsx)(InspectionGalleryFilterPill_1.InspectionGalleryFilterPill, { isSelected: props.currentFilter === filter, label: t(filter.label), count: props.items.filter(filter.callback).length, onClick: function () { var _a; return (_a = props.onUpdateFilter) === null || _a === void 0 ? void 0 : _a.call(props, filter); } }, filter.label)); }) })))] })), props.isInspectionCompleted ? ((0, jsx_runtime_1.jsx)("div", __assign({ style: titleStyle }, { children: t('topBar.completed') }))) : ((0, jsx_runtime_1.jsx)(Button_1.Button, __assign({ disabled: !isSubmitAvailable, onClick: props.onValidate }, { children: props.validateButtonLabel || t('topBar.submit') })))] })));
}
exports.InspectionGalleryTopBar = InspectionGalleryTopBar;
