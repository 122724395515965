"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBadConnectionWarning = void 0;
var react_1 = require("react");
var common_1 = require("@monkvision/common");
/**
 * Custom hook used to handle the state managing the dialog displayed to the user when he has a bad connection.
 */
function useBadConnectionWarning(_a) {
    var maxUploadDurationWarning = _a.maxUploadDurationWarning;
    var _b = (0, react_1.useState)(false), isBadConnectionWarningDialogDisplayed = _b[0], setIsBadConnectionWarningDialogDisplayed = _b[1];
    var hadDialogBeenDisplayed = (0, react_1.useRef)(false);
    var closeBadConnectionWarningDialog = (0, react_1.useCallback)(function () { return setIsBadConnectionWarningDialogDisplayed(false); }, []);
    var onUploadSuccess = (0, react_1.useCallback)(function (durationMs) {
        if (maxUploadDurationWarning >= 0 &&
            durationMs > maxUploadDurationWarning &&
            !hadDialogBeenDisplayed.current) {
            setIsBadConnectionWarningDialogDisplayed(true);
            hadDialogBeenDisplayed.current = true;
        }
    }, [maxUploadDurationWarning, hadDialogBeenDisplayed]);
    var onUploadTimeout = (0, react_1.useCallback)(function () {
        if (maxUploadDurationWarning >= 0 && !hadDialogBeenDisplayed.current) {
            setIsBadConnectionWarningDialogDisplayed(true);
            hadDialogBeenDisplayed.current = true;
        }
    }, [maxUploadDurationWarning, hadDialogBeenDisplayed]);
    return (0, common_1.useObjectMemo)({
        isBadConnectionWarningDialogDisplayed: isBadConnectionWarningDialogDisplayed,
        closeBadConnectionWarningDialog: closeBadConnectionWarningDialog,
        uploadEventHandlers: {
            onUploadSuccess: onUploadSuccess,
            onUploadTimeout: onUploadTimeout,
        },
    });
}
exports.useBadConnectionWarning = useBadConnectionWarning;
