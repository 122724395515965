"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhotoCaptureHUDElements = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var hooks_1 = require("../../hooks");
var PhotoCaptureHUDElementsSight_1 = require("../PhotoCaptureHUDElementsSight");
var PhotoCaptureHUDElementsAddDamage1stShot_1 = require("../PhotoCaptureHUDElementsAddDamage1stShot");
var PhotoCaptureHUDElementsAddDamage2ndShot_1 = require("../PhotoCaptureHUDElementsAddDamage2ndShot");
/**
 * Component implementing an HUD displayed on top of the Camera preview during the PhotoCapture process.
 */
function PhotoCaptureHUDElements(params) {
    if (params.isLoading || !!params.error) {
        return null;
    }
    if (params.mode === hooks_1.PhotoCaptureMode.SIGHT) {
        return ((0, jsx_runtime_1.jsx)(PhotoCaptureHUDElementsSight_1.PhotoCaptureHUDElementsSight, { sights: params.sights, selectedSight: params.selectedSight, onSelectedSight: params.onSelectSight, onRetakeSight: params.onRetakeSight, sightsTaken: params.sightsTaken, onAddDamage: params.onAddDamage, previewDimensions: params.previewDimensions, images: params.images, enableAddDamage: params.enableAddDamage, sightGuidelines: params.sightGuidelines, enableSightGuidelines: params.enableSightGuidelines, tutorialStep: params.tutorialStep }));
    }
    if (params.mode === hooks_1.PhotoCaptureMode.ADD_DAMAGE_1ST_SHOT) {
        return (0, jsx_runtime_1.jsx)(PhotoCaptureHUDElementsAddDamage1stShot_1.PhotoCaptureHUDElementsAddDamage1stShot, { onCancel: params.onCancelAddDamage });
    }
    return ((0, jsx_runtime_1.jsx)(PhotoCaptureHUDElementsAddDamage2ndShot_1.PhotoCaptureHUDElementsAddDamage2ndShot, { onCancel: params.onCancelAddDamage, streamDimensions: params.previewDimensions }));
}
exports.PhotoCaptureHUDElements = PhotoCaptureHUDElements;
