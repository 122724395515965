"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageDetailedView = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var i18n_1 = require("./i18n");
var hooks_1 = require("./hooks");
var Button_1 = require("../Button");
var ImageDetailedViewOverlay_1 = require("./ImageDetailedViewOverlay");
/**
 * This component is used to display the preview of an inspection image, as well as additional data such as its label
 * etc. If this component is used mid-capture, set the `captureMode` prop to `true` so that you'll enable features such
 * as compliance errors, retakes etc.
 */
exports.ImageDetailedView = (0, common_1.i18nWrap)(function ImageDetailedView(props) {
    var _a, _b;
    (0, common_1.useI18nSync)(props.lang);
    var _c = (0, hooks_1.useImageDetailedViewStyles)(props), mainContainerStyle = _c.mainContainerStyle, leftContainerStyle = _c.leftContainerStyle, overlayContainerStyle = _c.overlayContainerStyle, rightContainerStyle = _c.rightContainerStyle, closeButton = _c.closeButton, galleryButton = _c.galleryButton, cameraButton = _c.cameraButton;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: mainContainerStyle }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: leftContainerStyle }, { children: (0, jsx_runtime_1.jsx)(Button_1.Button, { onClick: props.onClose, icon: 'close', size: 'small', primaryColor: closeButton.primaryColor, secondaryColor: closeButton.secondaryColor }) })), (0, jsx_runtime_1.jsx)("div", __assign({ style: overlayContainerStyle }, { children: (0, jsx_runtime_1.jsx)(ImageDetailedViewOverlay_1.ImageDetailedViewOverlay, { image: props.image, captureMode: props.captureMode, onRetake: props.captureMode ? props.onRetake : undefined }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ style: rightContainerStyle }, { children: [(0, jsx_runtime_1.jsx)(Button_1.Button, { disabled: !((_a = props.showGalleryButton) !== null && _a !== void 0 ? _a : true), onClick: props.onNavigateToGallery, icon: 'gallery', primaryColor: galleryButton.primaryColor, secondaryColor: galleryButton.secondaryColor, style: galleryButton.style }), (0, jsx_runtime_1.jsx)(Button_1.Button, { disabled: !props.captureMode || !((_b = props.showCaptureButton) !== null && _b !== void 0 ? _b : true), onClick: props.captureMode ? props.onNavigateToCapture : undefined, icon: 'camera-outline', style: cameraButton.style })] }))] })));
}, i18n_1.i18nImageDetailedView);
