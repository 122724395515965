"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleTypeSelectionCard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var sights_1 = require("@monkvision/sights");
var hooks_1 = require("./hooks");
var VehicleTypeAsset_1 = require("../../VehicleTypeAsset");
function VehicleTypeSelectionCard(_a) {
    var vehicleType = _a.vehicleType, isSelected = _a.isSelected, onClick = _a.onClick;
    var _b = (0, common_1.useInteractiveStatus)(), status = _b.status, eventHandlers = _b.eventHandlers;
    var tObj = (0, common_1.useObjectTranslation)().tObj;
    var _c = (0, hooks_1.useVehicleTypeSelectionCardStyles)({ isSelected: isSelected, status: status }), containerStyle = _c.containerStyle, assetContainerStyle = _c.assetContainerStyle, assetStyle = _c.assetStyle, labelStyle = _c.labelStyle;
    return ((0, jsx_runtime_1.jsxs)("button", __assign({ style: containerStyle }, eventHandlers, { onClick: !isSelected ? onClick : undefined }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: assetContainerStyle }, { children: (0, jsx_runtime_1.jsx)(VehicleTypeAsset_1.VehicleTypeAsset, { vehicleType: vehicleType, style: assetStyle }) })), (0, jsx_runtime_1.jsx)("div", __assign({ style: labelStyle }, { children: tObj(sights_1.labels[vehicleType]) }))] })));
}
exports.VehicleTypeSelectionCard = VehicleTypeSelectionCard;
