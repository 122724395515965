"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEmptyMonkState = void 0;
/**
 * Creates an empty state that can be used to initialize the Monk state.
 */
function createEmptyMonkState() {
    return {
        damages: [],
        images: [],
        inspections: [],
        parts: [],
        partOperations: [],
        renderedOutputs: [],
        severityResults: [],
        tasks: [],
        vehicles: [],
        views: [],
        pricings: [],
    };
}
exports.createEmptyMonkState = createEmptyMonkState;
