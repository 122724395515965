"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeverityResultTargetType = void 0;
/**
 * Enumeration of the possible objects a severity result can be related to.
 */
var SeverityResultTargetType;
(function (SeverityResultTargetType) {
    /**
     * A vehcile part.
     */
    SeverityResultTargetType["PART"] = "part";
    /**
     * A damage.
     */
    SeverityResultTargetType["DAMAGE"] = "damage";
    /**
     * A comment on a severity.
     */
    SeverityResultTargetType["COMMENT"] = "comment";
})(SeverityResultTargetType = exports.SeverityResultTargetType || (exports.SeverityResultTargetType = {}));
