"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MileageUnit = void 0;
/**
 * Enumeration of the units used for the mileage of a vehicle.
 */
var MileageUnit;
(function (MileageUnit) {
    MileageUnit["KM"] = "km";
    MileageUnit["MILES"] = "miles";
})(MileageUnit = exports.MileageUnit || (exports.MileageUnit = {}));
