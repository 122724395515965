"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInspectionGalleryTopBarStyles = void 0;
var common_1 = require("@monkvision/common");
var InspectionGalleryTopBar_styles_1 = require("./InspectionGalleryTopBar.styles");
function useInspectionGalleryTopBarStyles() {
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    var palette = (0, common_1.useMonkTheme)().palette;
    return {
        barStyle: __assign(__assign(__assign({}, InspectionGalleryTopBar_styles_1.styles['bar']), responsive(InspectionGalleryTopBar_styles_1.styles['barSmallScreen'])), { backgroundColor: palette.background.base }),
        leftContainerStyle: InspectionGalleryTopBar_styles_1.styles['leftContainer'],
        pillContainerStyle: __assign(__assign({}, InspectionGalleryTopBar_styles_1.styles['pillContainer']), responsive(InspectionGalleryTopBar_styles_1.styles['pillContainerSmallScreen'])),
        backButtonStyle: __assign(__assign({}, InspectionGalleryTopBar_styles_1.styles['backButton']), responsive(InspectionGalleryTopBar_styles_1.styles['backButtonSmallScreen'])),
        titleStyle: __assign(__assign({}, InspectionGalleryTopBar_styles_1.styles['title']), { color: palette.text.primary }),
    };
}
exports.useInspectionGalleryTopBarStyles = useInspectionGalleryTopBarStyles;
