"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePhotoCaptureHUDButtonBackground = void 0;
var common_1 = require("@monkvision/common");
var react_1 = require("react");
/**
 * Custom hook used to generate the background color for the buttons in the PhotoCaptureHUD component.
 */
function usePhotoCaptureHUDButtonBackground() {
    var palette = (0, common_1.useMonkTheme)().palette;
    return (0, react_1.useMemo)(function () { return (0, common_1.changeAlpha)(palette.background.base, 0.64); }, [palette]);
}
exports.usePhotoCaptureHUDButtonBackground = usePhotoCaptureHUDButtonBackground;
