"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Slider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var hooks_1 = require("./hooks");
/**
 * A Slider component that allows users to select a value within a specified range by dragging along a horizontal track.
 */
function Slider(_a) {
    var _b = _a.min, min = _b === void 0 ? 0 : _b, _c = _a.max, max = _c === void 0 ? 100 : _c, _d = _a.value, value = _d === void 0 ? (max - min) / 2 : _d, _e = _a.primaryColor, primaryColor = _e === void 0 ? 'primary' : _e, _f = _a.secondaryColor, secondaryColor = _f === void 0 ? 'primary' : _f, _g = _a.tertiaryColor, tertiaryColor = _g === void 0 ? 'secondary-xlight' : _g, _h = _a.disabled, disabled = _h === void 0 ? false : _h, _j = _a.step, step = _j === void 0 ? 1 : _j, onChange = _a.onChange, style = _a.style;
    var sliderRef = (0, react_1.useRef)(null);
    var _k = (0, hooks_1.useSlider)({
        sliderRef: sliderRef,
        value: value,
        min: min,
        max: max,
        step: step > 0 ? step : 1,
        disabled: disabled,
        onChange: onChange,
    }), thumbPosition = _k.thumbPosition, handleStart = _k.handleStart, isDragging = _k.isDragging;
    var _l = (0, common_1.useInteractiveStatus)({
        disabled: disabled,
    }), status = _l.status, eventHandlers = _l.eventHandlers;
    var _m = (0, hooks_1.useSliderStyle)({
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        tertiaryColor: tertiaryColor,
        style: style,
        status: status,
    }), sliderStyle = _m.sliderStyle, thumbStyle = _m.thumbStyle, progressBarStyle = _m.progressBarStyle, trackBarStyle = _m.trackBarStyle, hoverThumbStyle = _m.hoverThumbStyle;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ role: 'button', tabIndex: 0, ref: sliderRef, style: sliderStyle, onMouseDown: handleStart, onTouchStart: handleStart, "data-testid": 'slider' }, { children: [(0, jsx_runtime_1.jsx)("div", { style: __assign({}, trackBarStyle), "data-testid": 'track' }), (0, jsx_runtime_1.jsx)("div", { style: __assign(__assign({}, progressBarStyle), { width: "".concat(thumbPosition, "%") }), "data-testid": 'value-track' }), (0, jsx_runtime_1.jsx)("div", { style: __assign(__assign({}, hoverThumbStyle), { left: "".concat(thumbPosition, "%") }), "data-testid": 'hover-thumb' }), (0, jsx_runtime_1.jsx)("div", { style: __assign(__assign({ cursor: isDragging ? 'grabbing' : 'grab' }, thumbStyle), { left: "".concat(thumbPosition, "%") }), onMouseEnter: eventHandlers.onMouseEnter, onMouseLeave: eventHandlers.onMouseLeave, "data-testid": 'thumb' })] })));
}
exports.Slider = Slider;
