"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InteractiveStatus = void 0;
/**
 * The status of an interactive DOM element.
 */
var InteractiveStatus;
(function (InteractiveStatus) {
    /**
     * The default status of the element.
     */
    InteractiveStatus["DEFAULT"] = "default";
    /**
     * The element is being hovered by the user (and neither active nor disabled).
     */
    InteractiveStatus["HOVERED"] = "hovered";
    /**
     * The element is active (and not disabled).
     */
    InteractiveStatus["ACTIVE"] = "active";
    /**
     * The element is disabled.
     */
    InteractiveStatus["DISABLED"] = "disabled";
})(InteractiveStatus = exports.InteractiveStatus || (exports.InteractiveStatus = {}));
