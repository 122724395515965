"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var hooks_1 = require("./hooks");
var icons_1 = require("../../icons");
var Button_1 = require("../Button");
/**
 * Custom component implementing a simple one-liner text field.
 */
function TextField(_a) {
    var _b = _a.type, type = _b === void 0 ? 'text' : _b, value = _a.value, onChange = _a.onChange, onBlur = _a.onBlur, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.highlighted, highlighted = _d === void 0 ? false : _d, _e = _a.monospace, monospace = _e === void 0 ? false : _e, label = _a.label, placeholder = _a.placeholder, unit = _a.unit, _f = _a.unitPosition, unitPosition = _f === void 0 ? 'left' : _f, icon = _a.icon, _g = _a.showClearButton, showClearButton = _g === void 0 ? true : _g, assistiveText = _a.assistiveText, fixedWidth = _a.fixedWidth, _h = _a.focusColor, focusColor = _h === void 0 ? 'primary-base' : _h, _j = _a.neutralColor, neutralColor = _j === void 0 ? 'text-primary' : _j, _k = _a.backgroundColor, backgroundColor = _k === void 0 ? 'background-light' : _k, id = _a.id, style = _a.style;
    var inputRef = (0, react_1.useRef)(null);
    var _l = (0, react_1.useState)(false), isFocused = _l[0], setIsFocused = _l[1];
    var isFilled = !!value && value.length > 0;
    var _m = (0, hooks_1.useTextFieldStyles)({
        disabled: disabled,
        highlighted: highlighted,
        monospace: monospace,
        unitPosition: unitPosition,
        showClearButton: showClearButton,
        focusColor: focusColor,
        neutralColor: neutralColor,
        backgroundColor: backgroundColor,
        fixedWidth: fixedWidth,
        isFocused: isFocused,
        isFilled: isFilled,
    }), mainContainerStyle = _m.mainContainerStyle, componentsContainerStyle = _m.componentsContainerStyle, leftIcon = _m.leftIcon, inputContainerStyle = _m.inputContainerStyle, unitStyle = _m.unitStyle, labelStyle = _m.labelStyle, inputStyle = _m.inputStyle, clearButton = _m.clearButton, assistiveTextStyle = _m.assistiveTextStyle;
    var handleBlur = function () {
        setIsFocused(false);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur();
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: __assign(__assign({}, mainContainerStyle), (style !== null && style !== void 0 ? style : {})), "data-testid": 'root' }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ style: componentsContainerStyle, onClick: function () { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); } }, { children: [icon && ((0, jsx_runtime_1.jsx)(icons_1.Icon, { icon: icon, size: leftIcon.size, primaryColor: leftIcon.primaryColor, style: leftIcon.style })), (0, jsx_runtime_1.jsxs)("div", __assign({ style: inputContainerStyle }, { children: [(0, jsx_runtime_1.jsx)("label", __assign({ style: labelStyle }, { children: label })), (isFocused || isFilled) && unit && (0, jsx_runtime_1.jsx)("div", __assign({ style: unitStyle }, { children: unit })), (0, jsx_runtime_1.jsx)("input", { id: id, ref: inputRef, type: type, style: inputStyle, placeholder: isFocused ? placeholder : '', value: value, disabled: disabled, onChange: function (e) { return onChange === null || onChange === void 0 ? void 0 : onChange(e.target.value); }, onFocus: function () { return setIsFocused(true); }, onBlur: handleBlur, "data-testid": 'input' })] })), showClearButton && ((0, jsx_runtime_1.jsx)(Button_1.Button, { icon: 'close', variant: 'text', primaryColor: clearButton.primaryColor, style: clearButton.style, disabled: disabled, onClick: function () { return onChange === null || onChange === void 0 ? void 0 : onChange(''); } }))] })), assistiveText && (0, jsx_runtime_1.jsx)("div", __assign({ style: assistiveTextStyle }, { children: assistiveText }))] })));
}
exports.TextField = TextField;
