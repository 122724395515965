"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapApiPricingPostRequest = exports.mapApiPricingPost = void 0;
var types_1 = require("@monkvision/types");
function mapApiPricingPost(inspectionId, response) {
    return {
        inspectionId: inspectionId,
        id: response.id,
        entityType: types_1.MonkEntityType.PRICING,
        relatedItemType: response.related_item_type,
        relatedItemId: response.related_item_id,
        pricing: response.pricing,
        operations: response.operations,
        hours: response.hours,
    };
}
exports.mapApiPricingPost = mapApiPricingPost;
function mapApiPricingPostRequest(options) {
    return {
        pricing: options.pricing >= 0 ? options.pricing : 0,
        related_item_type: options.type,
        part_type: options.type === types_1.PricingV2RelatedItemType.PART ? options.vehiclePart : types_1.VehiclePart.IGNORE,
    };
}
exports.mapApiPricingPostRequest = mapApiPricingPostRequest;
