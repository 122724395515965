"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCameraCanvas = void 0;
var react_1 = require("react");
var utils_1 = require("./utils");
/**
 * This function is used to calculate the dimensions of the canvas that will be used to draw the image, thus also
 * calculating the output dimensions of the image itself, respecting the following logic :
 * - If the aspect ratio of the stream and constraints are the same, we simply scale the stream to make it fit the
 * constraints. Note that if `allowImageUpscaling` is `false`, and the stream is smaller than the constraints, we don't
 * scale "up" the stream image, and we simply return the stream dimensions.
 * - If the aspect ratio of the stream is different from the one specified in the constraints, the logic is the same,
 * but the output aspect ratio will be the same one as the stream. The stream dimensions will simply be scaled
 * following the same logic as the previous point, making sure that neither the width nor the height of the canvas will
 * exceed the ones described by the constraints.
 */
function getCanvasDimensions(_a) {
    var resolution = _a.resolution, streamDimensions = _a.streamDimensions, allowImageUpscaling = _a.allowImageUpscaling;
    if (!streamDimensions) {
        return null;
    }
    var isPortrait = streamDimensions.width < streamDimensions.height;
    var constraintsDimensions = (0, utils_1.getResolutionDimensions)(resolution, isPortrait);
    var streamRatio = streamDimensions.width / streamDimensions.height;
    if (constraintsDimensions.width > streamDimensions.width &&
        constraintsDimensions.height > streamDimensions.height &&
        !allowImageUpscaling) {
        return {
            width: streamDimensions.width,
            height: streamDimensions.height,
        };
    }
    var fitToHeight = constraintsDimensions.width / streamRatio > constraintsDimensions.height;
    return {
        width: fitToHeight ? constraintsDimensions.height * streamRatio : constraintsDimensions.width,
        height: fitToHeight ? constraintsDimensions.height : constraintsDimensions.width / streamRatio,
    };
}
/**
 * Custom hook used to manage the camera <canvas> element used to take video screenshots and encode images.
 */
function useCameraCanvas(_a) {
    var resolution = _a.resolution, streamDimensions = _a.streamDimensions, allowImageUpscaling = _a.allowImageUpscaling;
    var ref = (0, react_1.useRef)(null);
    var handle = (0, react_1.useMemo)(function () { return ({
        ref: ref,
        dimensions: getCanvasDimensions({ resolution: resolution, streamDimensions: streamDimensions, allowImageUpscaling: allowImageUpscaling }),
    }); }, [resolution, streamDimensions]);
    (0, react_1.useEffect)(function () {
        if (handle.dimensions && ref.current) {
            ref.current.width = handle.dimensions.width;
            ref.current.height = handle.dimensions.height;
        }
    }, [handle.dimensions]);
    return handle;
}
exports.useCameraCanvas = useCameraCanvas;
