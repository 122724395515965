"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDynamicWireframe = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var types_1 = require("@monkvision/types");
var sights_1 = require("@monkvision/sights");
var DynamicSVG_1 = require("../DynamicSVG");
var VehicleDynamicWireframe_style_1 = require("./VehicleDynamicWireframe.style");
function isCarPartElement(element) {
    return element.id !== '' && element.classList.contains('car-part');
}
function createGetAttributesCallback(onClickPart, getPartAttributes) {
    return function (element, groups) {
        var groupElement = groups[0];
        var part;
        if (groupElement && isCarPartElement(groupElement)) {
            part = groupElement.id;
        }
        else if (isCarPartElement(element)) {
            part = element.id;
        }
        else {
            return { style: VehicleDynamicWireframe_style_1.styles['notCarPart'] };
        }
        var attributes = getPartAttributes(part);
        if (element.tagName === 'g') {
            delete attributes.style;
        }
        if (element.classList.contains('selectable') && element.id) {
            attributes.onClick = function () { return onClickPart(part); };
            attributes.style = __assign(__assign({}, attributes.style), VehicleDynamicWireframe_style_1.styles['selectable']);
        }
        return attributes;
    };
}
function getVehicleModel(vehicleType) {
    var _a;
    var detail = (_a = Object.entries(sights_1.vehicles)
        .filter(function (_a) {
        var type = _a[0];
        return type !== types_1.VehicleModel.AUDIA7;
    })
        .find(function (_a) {
        var details = _a[1];
        return details.type === vehicleType;
    })) === null || _a === void 0 ? void 0 : _a[1];
    if (detail === undefined) {
        throw new Error("No vehicle model found for vehicle type ".concat(vehicleType));
    }
    return detail.id;
}
/**
 * Component that displays a dynamic wireframe of a vehicle, allowing the user to select parts of the vehicle.
 */
function VehicleDynamicWireframe(_a) {
    var vehicleType = _a.vehicleType, _b = _a.orientation, orientation = _b === void 0 ? types_1.PartSelectionOrientation.FRONT_LEFT : _b, _c = _a.onClickPart, onClickPart = _c === void 0 ? function () { } : _c, _d = _a.getPartAttributes, getPartAttributes = _d === void 0 ? function () { return ({}); } : _d;
    var wireframes = sights_1.partSelectionWireframes[getVehicleModel(vehicleType)];
    if (wireframes === undefined) {
        throw new Error("No wireframe found for vehicle type ".concat(vehicleType));
    }
    var overlay = wireframes[orientation];
    var utils = (0, common_1.useMonkTheme)().utils;
    return ((0, jsx_runtime_1.jsx)(DynamicSVG_1.DynamicSVG, { svg: overlay, style: {
            width: '100%',
            color: utils.getColor('text-primary'),
        }, getAttributes: createGetAttributesCallback(onClickPart, getPartAttributes) }));
}
exports.VehicleDynamicWireframe = VehicleDynamicWireframe;
