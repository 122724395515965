"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAnalytics = void 0;
var react_1 = require("react");
var context_1 = require("./context");
/**
 * Custom hook that allows you to access the Analytics Context methods inside a component.
 */
function useAnalytics() {
    var adapter = (0, react_1.useContext)(context_1.AnalyticsContext);
    return (0, react_1.useMemo)(function () { return ({
        setUserId: adapter.setUserId.bind(adapter),
        setUserProperties: adapter.setUserProperties.bind(adapter),
        resetUser: adapter.resetUser.bind(adapter),
        trackEvent: adapter.trackEvent.bind(adapter),
        setEventsProperties: adapter.setEventsProperties.bind(adapter),
    }); }, []);
}
exports.useAnalytics = useAnalytics;
