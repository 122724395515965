"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TakePictureButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var hooks_1 = require("./hooks");
/**
 * A custom button that is used as a take-picture button in camera HUDs throughout the MonkJs SDK.
 */
exports.TakePictureButton = (0, react_1.forwardRef)(function TakePictureButton(_a, ref) {
    var _b = _a.size, size = _b === void 0 ? 60 : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.style, style = _d === void 0 ? {} : _d, onClick = _a.onClick, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, onMouseDown = _a.onMouseDown, onMouseUp = _a.onMouseUp, passThroughProps = __rest(_a, ["size", "disabled", "style", "onClick", "onMouseEnter", "onMouseLeave", "onMouseDown", "onMouseUp"]);
    var _e = (0, common_1.useInteractiveStatus)({
        disabled: disabled,
        componentHandlers: {
            onMouseEnter: onMouseEnter,
            onMouseLeave: onMouseLeave,
            onMouseDown: onMouseDown,
            onMouseUp: onMouseUp,
        },
    }), status = _e.status, eventHandlers = _e.eventHandlers;
    var _f = (0, hooks_1.useTakePictureButtonStyle)({ size: size, status: status }), _g = _f.buttonStyles, innerLayer = _g.innerLayer, outerLayer = _g.outerLayer, animateClick = _f.animateClick;
    var onButtonClick = function (event) {
        animateClick();
        if (onClick) {
            onClick(event);
        }
    };
    return ((0, jsx_runtime_1.jsx)("div", __assign({ style: __assign(__assign({}, outerLayer), style), "data-testid": 'take-picture-btn-outer-layer' }, { children: (0, jsx_runtime_1.jsx)("button", __assign({ ref: ref, style: __assign({}, innerLayer), disabled: disabled, onClick: onButtonClick }, eventHandlers, passThroughProps, { "data-testid": 'take-picture-btn' })) })));
});
