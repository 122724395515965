"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortOrder = exports.DeviceOrientation = void 0;
/**
 * Enumeration of the device orientations.
 */
var DeviceOrientation;
(function (DeviceOrientation) {
    /**
     * Portrait orientation (width < height).
     */
    DeviceOrientation["PORTRAIT"] = "portrait";
    /**
     * Landscape orientation (width > height).
     */
    DeviceOrientation["LANDSCAPE"] = "landscape";
})(DeviceOrientation = exports.DeviceOrientation || (exports.DeviceOrientation = {}));
/**
 * Enumeration of the possible sort orders.
 */
var SortOrder;
(function (SortOrder) {
    /**
     * Sort in ascending order.
     */
    SortOrder["ASC"] = "asc";
    /**
     * Sort in descending order.
     */
    SortOrder["DESC"] = "desc";
})(SortOrder = exports.SortOrder || (exports.SortOrder = {}));
