"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticsProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var context_1 = require("./context");
/**
 * Context provider for the Analytics Context. Place this at the root of your application in order to have access to
 * the analytics features in your app. Inside the children of this component, you can call the `useAnalytics` hook
 * to use the different Analytics methods.
 */
function AnalyticsProvider(_a) {
    var adapter = _a.adapter, children = _a.children;
    return (0, jsx_runtime_1.jsx)(context_1.AnalyticsContext.Provider, __assign({ value: adapter }, { children: children }));
}
exports.AnalyticsProvider = AnalyticsProvider;
