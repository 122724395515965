"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPreventExitListener = void 0;
var keys = [];
var allPreventExitState = {};
function arePreventExitRemaining() {
    if (keys.map(function (key) { return allPreventExitState[key]; }).every(function (i) { return i === false; })) {
        window.onbeforeunload = null;
        return true;
    }
    return false;
}
function publish(id, preventExit) {
    allPreventExitState[id] = preventExit;
    if (!arePreventExitRemaining()) {
        window.onbeforeunload = function (e) {
            e.preventDefault();
            return 'prevent-exit';
        };
    }
}
/**
 * Creates a listener function that manages the preventExit state of a component.
 */
function createPreventExitListener() {
    var key = Symbol('PreventExitListener');
    allPreventExitState[key] = true;
    keys.push(key);
    return {
        setPreventExit: function (preventExit) { return publish(key, preventExit); },
        allowRedirect: function () {
            window.onbeforeunload = null;
        },
        cleanup: function () {
            delete allPreventExitState[key];
            arePreventExitRemaining();
        },
    };
}
exports.createPreventExitListener = createPreventExitListener;
