"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAsyncInterval = void 0;
var react_1 = require("react");
/**
 * This custom hook creates an interval that calls the provided async callback every `delay` milliseconds if the
 * previous call isn't still running. If `delay` is `null` or less than 0, the callback will not be called. The
 * promise handlers provided will only be called while the component is still mounted.
 */
function useAsyncInterval(callback, delay, handlers) {
    var callbackRef = (0, react_1.useRef)(callback);
    (0, react_1.useLayoutEffect)(function () {
        callbackRef.current = callback;
    }, [callback]);
    (0, react_1.useEffect)(function () {
        var isActive = true;
        var isRunning = false;
        if (delay === null || delay < 0) {
            return function () { };
        }
        var intervalId = setInterval(function () {
            if (!isRunning) {
                isRunning = true;
                callbackRef
                    .current()
                    .then(function (res) {
                    var _a;
                    if (isActive) {
                        (_a = handlers === null || handlers === void 0 ? void 0 : handlers.onResolve) === null || _a === void 0 ? void 0 : _a.call(handlers, res);
                    }
                })
                    .catch(function (err) {
                    var _a;
                    if (isActive) {
                        (_a = handlers === null || handlers === void 0 ? void 0 : handlers.onReject) === null || _a === void 0 ? void 0 : _a.call(handlers, err);
                    }
                })
                    .finally(function () {
                    var _a;
                    if (isActive) {
                        isRunning = false;
                        (_a = handlers === null || handlers === void 0 ? void 0 : handlers.onComplete) === null || _a === void 0 ? void 0 : _a.call(handlers);
                    }
                });
            }
        }, delay);
        return function () {
            isActive = false;
            clearInterval(intervalId);
        };
    }, [delay]);
}
exports.useAsyncInterval = useAsyncInterval;
