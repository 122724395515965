"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePhotoCaptureHUDElementsAddDamage1stShotStyles = void 0;
var common_1 = require("@monkvision/common");
var PhotoCaptureHUDElementsAddDamage1stShot_styles_1 = require("./PhotoCaptureHUDElementsAddDamage1stShot.styles");
function usePhotoCaptureHUDElementsAddDamage1stShotStyles() {
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    return {
        top: __assign(__assign({}, PhotoCaptureHUDElementsAddDamage1stShot_styles_1.styles['top']), responsive(PhotoCaptureHUDElementsAddDamage1stShot_styles_1.styles['topLandscape'])),
        infoBtn: __assign(__assign({}, PhotoCaptureHUDElementsAddDamage1stShot_styles_1.styles['infoBtn']), responsive(PhotoCaptureHUDElementsAddDamage1stShot_styles_1.styles['infoBtnPortrait'])),
    };
}
exports.usePhotoCaptureHUDElementsAddDamage1stShotStyles = usePhotoCaptureHUDElementsAddDamage1stShotStyles;
