"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatedOnePricing = exports.isUpdatedOnePricingAction = void 0;
var monkAction_1 = require("./monkAction");
/**
 * Matcher function that matches a updatedOnePricing while also inferring its type using TypeScript's type predicate
 * feature.
 */
function isUpdatedOnePricingAction(action) {
    return action.type === monkAction_1.MonkActionType.UPDATED_ONE_PRICING;
}
exports.isUpdatedOnePricingAction = isUpdatedOnePricingAction;
/**
 * Reducer function for a updatedOnePricing action.
 */
function updatedOnePricing(state, action) {
    var pricings = state.pricings;
    var payload = action.payload;
    var updatedPricings = pricings.map(function (pricing) {
        return pricing.id === payload.pricing.id ? __assign(__assign({}, pricing), payload.pricing) : pricing;
    });
    return __assign(__assign({}, state), { pricings: updatedPricings });
}
exports.updatedOnePricing = updatedOnePricing;
