"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monkReducer = void 0;
var actions_1 = require("./actions");
/**
 * Main reducer function for the Monk state.
 */
function monkReducer(state, action) {
    if ((0, actions_1.isResetStateAction)(action)) {
        return (0, actions_1.resetState)();
    }
    if ((0, actions_1.isGotOneInspectionAction)(action)) {
        return (0, actions_1.gotOneInspection)(state, action);
    }
    if ((0, actions_1.isUpdatedOneInspectionAdditionalDataAction)(action)) {
        return (0, actions_1.updatedOneInspectionAdditionalData)(state, action);
    }
    if ((0, actions_1.isCreatedOneImageAction)(action)) {
        return (0, actions_1.createdOneImage)(state, action);
    }
    if ((0, actions_1.isUpdatedManyTasksAction)(action)) {
        return (0, actions_1.updatedManyTasks)(state, action);
    }
    if ((0, actions_1.isCreatedOnePricingAction)(action)) {
        return (0, actions_1.createdOnePricing)(state, action);
    }
    if ((0, actions_1.isDeletedOnePricingAction)(action)) {
        return (0, actions_1.deletedOnePricing)(state, action);
    }
    if ((0, actions_1.isUpdatedOnePricingAction)(action)) {
        return (0, actions_1.updatedOnePricing)(state, action);
    }
    if ((0, actions_1.isUpdatedVehicleAction)(action)) {
        return (0, actions_1.updatedVehicle)(state, action);
    }
    if ((0, actions_1.isCreatedOneDamageAction)(action)) {
        return (0, actions_1.createdOneDamage)(state, action);
    }
    if ((0, actions_1.isDeletedOneDamageAction)(action)) {
        return (0, actions_1.deletedOneDamage)(state, action);
    }
    return state;
}
exports.monkReducer = monkReducer;
