"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonkEntityType = void 0;
/**
 * Enumeration of the entities defined and used in MonkJs projects. An entity is an object with a unique ID (UUIDv4),
 * that is stored by our back-end and that can usually be fetched.
 */
var MonkEntityType;
(function (MonkEntityType) {
    MonkEntityType["DAMAGE"] = "DAMAGE";
    MonkEntityType["IMAGE"] = "IMAGE";
    MonkEntityType["INSPECTION"] = "INSPECTION";
    MonkEntityType["PART"] = "PART";
    MonkEntityType["PART_OPERATION"] = "PART_OPERATION";
    MonkEntityType["RENDERED_OUTPUT"] = "RENDERED_OUTPUT";
    MonkEntityType["SEVERITY_RESULT"] = "SEVERITY_RESULT";
    MonkEntityType["TASK"] = "TASK";
    MonkEntityType["VEHICLE"] = "VEHICLE";
    MonkEntityType["VIEW"] = "VIEW";
    MonkEntityType["PRICING"] = "PRICING";
})(MonkEntityType = exports.MonkEntityType || (exports.MonkEntityType = {}));
