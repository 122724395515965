"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInspectionGalleryStyles = void 0;
var common_1 = require("@monkvision/common");
var InspectionGallery_styles_1 = require("../InspectionGallery.styles");
function useInspectionGalleryStyles() {
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    var palette = (0, common_1.useMonkTheme)().palette;
    return {
        containerStyle: __assign(__assign(__assign({}, InspectionGallery_styles_1.styles['container']), responsive(InspectionGallery_styles_1.styles['containerSmallScreen'])), { backgroundColor: palette.background.base }),
        itemListStyle: InspectionGallery_styles_1.styles['itemList'],
        itemStyle: InspectionGallery_styles_1.styles['item'],
        fillerItemStyle: InspectionGallery_styles_1.styles['fillerItem'],
        emptyStyle: __assign(__assign({}, InspectionGallery_styles_1.styles['empty']), { color: palette.text.primary }),
    };
}
exports.useInspectionGalleryStyles = useInspectionGalleryStyles;
