"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageDetailedViewOverlay = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var common_1 = require("@monkvision/common");
var hooks_1 = require("./hooks");
var icons_1 = require("../../../icons");
var Button_1 = require("../../Button");
function ImageDetailedViewOverlay(props) {
    var t = (0, react_i18next_1.useTranslation)().t;
    var tObj = (0, common_1.useObjectTranslation)().tObj;
    var labelIcon = (0, hooks_1.useImageLabelIcon)(props);
    var retakeOverlay = (0, hooks_1.useRetakeOverlay)(props);
    var _a = (0, hooks_1.useImageDetailedViewOverlayStyles)(props), mainContainerStyle = _a.mainContainerStyle, overlayDisplayStyle = _a.overlayDisplayStyle, complianceContainerStyle = _a.complianceContainerStyle, complianceMessageContainerStyle = _a.complianceMessageContainerStyle, complianceIcon = _a.complianceIcon, complianceRetakeButton = _a.complianceRetakeButton, complianceMessageStyle = _a.complianceMessageStyle, complianceTitleStyle = _a.complianceTitleStyle, complianceDescriptionStyle = _a.complianceDescriptionStyle, imageLabelStyle = _a.imageLabelStyle, imageLabelIcon = _a.imageLabelIcon;
    return ((0, jsx_runtime_1.jsx)("div", __assign({ style: mainContainerStyle }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ style: overlayDisplayStyle }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ style: complianceContainerStyle }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ style: complianceMessageContainerStyle }, { children: [(0, jsx_runtime_1.jsx)(icons_1.Icon, { icon: retakeOverlay.icon, primaryColor: retakeOverlay.iconColor, size: complianceIcon.size }), (0, jsx_runtime_1.jsxs)("div", __assign({ style: complianceMessageStyle }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: complianceTitleStyle }, { children: retakeOverlay.title })), (0, jsx_runtime_1.jsx)("div", __assign({ style: complianceDescriptionStyle }, { children: retakeOverlay.description }))] }))] })), (0, jsx_runtime_1.jsx)(Button_1.Button, __assign({ style: complianceRetakeButton.style, size: complianceRetakeButton.size, primaryColor: retakeOverlay.buttonColor, onClick: props.onRetake }, { children: t('retake') }))] })), props.image.label && ((0, jsx_runtime_1.jsxs)("div", __assign({ style: imageLabelStyle }, { children: [labelIcon && ((0, jsx_runtime_1.jsx)(icons_1.Icon, { icon: labelIcon.icon, primaryColor: labelIcon.primaryColor, style: imageLabelIcon.style, size: imageLabelIcon.size })), tObj(props.image.label)] })))] })) })));
}
exports.ImageDetailedViewOverlay = ImageDetailedViewOverlay;
