"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Checkbox = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var hooks_1 = require("./hooks");
var icons_1 = require("../../icons");
var Checkbox_styles_1 = require("./Checkbox.styles");
/**
 * Custom component implementing a simple checkbox.
 */
function Checkbox(_a) {
    var _b = _a.checked, checked = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, onChange = _a.onChange, _d = _a.primaryColor, primaryColor = _d === void 0 ? 'primary-base' : _d, _e = _a.secondaryColor, secondaryColor = _e === void 0 ? 'text-primary' : _e, _f = _a.tertiaryColor, tertiaryColor = _f === void 0 ? 'background-light' : _f, _g = _a.labelColor, labelColor = _g === void 0 ? 'text-primary' : _g, label = _a.label;
    var _h = (0, common_1.useInteractiveStatus)({ disabled: disabled }), status = _h.status, eventHandlers = _h.eventHandlers;
    var _j = (0, hooks_1.useCheckboxStyles)({
        checked: checked,
        status: status,
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        tertiaryColor: tertiaryColor,
        labelColor: labelColor,
    }), checkboxStyles = _j.checkboxStyles, icon = _j.icon, interactiveOverlayStyle = _j.interactiveOverlayStyle, labelStyle = _j.labelStyle;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: Checkbox_styles_1.styles['container'] }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: interactiveOverlayStyle }, { children: (0, jsx_runtime_1.jsx)("button", __assign({ style: checkboxStyles, disabled: disabled, type: 'button', onClick: function () { return onChange === null || onChange === void 0 ? void 0 : onChange(!checked); } }, eventHandlers, { "data-testid": 'checkbox-btn' }, { children: checked && (0, jsx_runtime_1.jsx)(icons_1.Icon, { icon: 'check', size: 18, primaryColor: icon.primaryColor }) })) })), label && (0, jsx_runtime_1.jsx)("div", __assign({ style: labelStyle }, { children: label }))] })));
}
exports.Checkbox = Checkbox;
