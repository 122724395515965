"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResponsiveStyle = void 0;
var react_1 = require("react");
var useWindowDimensions_1 = require("./useWindowDimensions");
function areQueryConditionsMet(query, dimensions) {
    if (!query) {
        return true;
    }
    if (query.maxWidth !== undefined && dimensions.width > query.maxWidth) {
        return false;
    }
    if (query.minWidth !== undefined && dimensions.width < query.minWidth) {
        return false;
    }
    if (query.maxHeight !== undefined && dimensions.height > query.maxHeight) {
        return false;
    }
    if (query.minHeight !== undefined && dimensions.height < query.minHeight) {
        return false;
    }
    if (query.portrait !== undefined && dimensions.isPortrait !== query.portrait) {
        return false;
    }
    if (query.landscape !== undefined && dimensions.isPortrait !== !query.landscape) {
        return false;
    }
    return true;
}
/**
 * Custom hook used to render the given style only when its media query confitions are met.
 *
 * @example
 * import { useResponsiveStyle } from '@monkvision/common';
 * import { Styles } from '@monkvision/types';
 *
 * const styles: Styles = {
 *   div: {
 *     width: 100,
 *     height: 100,
 *   },
 *   divMobile: {
 *     __media: { maxWidth: 500 },
 *     backgroundColor: '#ff0000',
 *   },
 * };
 *
 * function TestComponent() {
 *   const { responsive } = useResponsiveStyle();
 *   return <div style={{...styles.div, ...responsive(styles.divMobile)}}>Hello</div>
 * }
 */
function useResponsiveStyle() {
    var dimensions = (0, useWindowDimensions_1.useWindowDimensions)();
    var responsive = (0, react_1.useCallback)(function (style) {
        if (areQueryConditionsMet(style === null || style === void 0 ? void 0 : style.__media, dimensions)) {
            return style;
        }
        return null;
    }, [dimensions]);
    return { responsive: responsive };
}
exports.useResponsiveStyle = useResponsiveStyle;
