"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVehicleTypeFromInspection = exports.getInitialSelectedVehicleType = exports.getVehicleTypes = exports.DEFAULT_AVAILABLE_VEHICLE_TYPES = exports.VEHICLE_TYPE_ORDER = void 0;
var sights_1 = require("@monkvision/sights");
var types_1 = require("@monkvision/types");
exports.VEHICLE_TYPE_ORDER = [
    types_1.VehicleType.HATCHBACK,
    types_1.VehicleType.SEDAN,
    types_1.VehicleType.CUV,
    types_1.VehicleType.SUV,
    types_1.VehicleType.PICKUP,
    types_1.VehicleType.MINIVAN,
    types_1.VehicleType.VAN,
    types_1.VehicleType.LARGE_SUV,
    types_1.VehicleType.CITY,
];
exports.DEFAULT_AVAILABLE_VEHICLE_TYPES = [
    types_1.VehicleType.SUV,
    types_1.VehicleType.CUV,
    types_1.VehicleType.SEDAN,
    types_1.VehicleType.HATCHBACK,
    types_1.VehicleType.VAN,
    types_1.VehicleType.MINIVAN,
    types_1.VehicleType.PICKUP,
];
function getVehicleTypes(availableVehicleTypes) {
    return (availableVehicleTypes !== null && availableVehicleTypes !== void 0 ? availableVehicleTypes : exports.DEFAULT_AVAILABLE_VEHICLE_TYPES).sort(function (a, b) { return exports.VEHICLE_TYPE_ORDER.indexOf(a) - exports.VEHICLE_TYPE_ORDER.indexOf(b); });
}
exports.getVehicleTypes = getVehicleTypes;
function getInitialSelectedVehicleType(vehicleTypes, selectedVehicleType) {
    if (vehicleTypes.length === 0) {
        throw new Error('Empty available vehicle types array given to the VehicleSelection component.');
    }
    if (selectedVehicleType && vehicleTypes.includes(selectedVehicleType)) {
        return selectedVehicleType;
    }
    return vehicleTypes[Math.floor(vehicleTypes.length / 2)];
}
exports.getInitialSelectedVehicleType = getInitialSelectedVehicleType;
function getVehicleTypeFromInspection(response) {
    var imageWithSightId = response.entities.images.find(function (image) { return image.sightId; });
    if (!imageWithSightId) {
        return undefined;
    }
    var sight = Object.values(sights_1.sights).find(function (s) { return s.id === imageWithSightId.sightId; });
    if (!sight) {
        return undefined;
    }
    var vehicle = Object.values(sights_1.vehicles).find(function (v) { return v.id === sight.vehicle; });
    return vehicle === null || vehicle === void 0 ? void 0 : vehicle.type;
}
exports.getVehicleTypeFromInspection = getVehicleTypeFromInspection;
