"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapApiInspectionsUrlParamsGet = exports.mapApiInspectionPost = exports.mapApiInspectionGet = exports.mapApiInspectionsGet = void 0;
var types_1 = require("@monkvision/types");
var mappers_1 = require("../image/mappers");
var config_1 = require("../config");
function mapDamages(response) {
    var _a;
    var damages = [];
    var damageIds = [];
    (_a = response.damages) === null || _a === void 0 ? void 0 : _a.forEach(function (damage) {
        var _a, _b, _c;
        damages.push({
            id: damage.id,
            entityType: types_1.MonkEntityType.DAMAGE,
            inspectionId: response.id,
            type: damage.damage_type,
            size: damage.damage_size_cm,
            parts: (_a = damage.part_ids) !== null && _a !== void 0 ? _a : [],
            relatedImages: (_c = (_b = damage.related_images) === null || _b === void 0 ? void 0 : _b.map(function (relatedImage) { return relatedImage.base_image_id; })) !== null && _c !== void 0 ? _c : [],
        });
        damageIds.push(damage.id);
    });
    return { damages: damages, damageIds: damageIds };
}
function mapRenderedOutput(renderedOutput) {
    return {
        id: renderedOutput.id,
        entityType: types_1.MonkEntityType.RENDERED_OUTPUT,
        baseImageId: renderedOutput.base_image_id,
        path: renderedOutput.path,
        additionalData: renderedOutput.additional_data,
    };
}
function mapImageRegion(imageRegion) {
    var _a, _b;
    return {
        specification: {
            boundingBox: ((_a = imageRegion.specification) === null || _a === void 0 ? void 0 : _a.bounding_box)
                ? {
                    xMin: imageRegion.specification.bounding_box.xmin,
                    yMin: imageRegion.specification.bounding_box.ymin,
                    width: imageRegion.specification.bounding_box.width,
                    height: imageRegion.specification.bounding_box.height,
                }
                : undefined,
            polygons: (_b = imageRegion.specification) === null || _b === void 0 ? void 0 : _b.polygons,
        },
    };
}
function mapView(view) {
    var _a;
    var renderedOutputs = [];
    var viewRenderedOutputs = [];
    (_a = view.rendered_outputs) === null || _a === void 0 ? void 0 : _a.forEach(function (renderedOutput) {
        viewRenderedOutputs.push(renderedOutput.id);
        renderedOutputs.push(mapRenderedOutput(renderedOutput));
    });
    return {
        view: {
            id: view.id,
            entityType: types_1.MonkEntityType.VIEW,
            elementId: view.element_id,
            imageRegion: mapImageRegion(view.image_region),
            renderedOutputs: viewRenderedOutputs,
        },
        renderedOutputs: renderedOutputs,
    };
}
function mapImages(response, thumbnailDomain, complianceOptions) {
    var _a;
    var images = [];
    var renderedOutputs = [];
    var views = [];
    var viewIds = [];
    var renderedOutputIds = [];
    var imageIds = [];
    (_a = response.images) === null || _a === void 0 ? void 0 : _a.forEach(function (image) {
        var _a, _b;
        var imageRenderedOutputs = [];
        var imageViews = [];
        (_a = image.rendered_outputs) === null || _a === void 0 ? void 0 : _a.forEach(function (renderedOutput) {
            renderedOutputIds.push(renderedOutput.id);
            imageRenderedOutputs.push(renderedOutput.id);
            renderedOutputs.push(mapRenderedOutput(renderedOutput));
        });
        (_b = image.views) === null || _b === void 0 ? void 0 : _b.forEach(function (apiView) {
            var _a = mapView(apiView), view = _a.view, viewRenderedOutputs = _a.renderedOutputs;
            viewIds.push(view.id);
            imageViews.push(view.id);
            views.push(view);
            renderedOutputs.push.apply(renderedOutputs, viewRenderedOutputs);
            renderedOutputIds.push.apply(renderedOutputIds, view.renderedOutputs);
        });
        imageIds.push(image.id);
        images.push(__assign(__assign({}, (0, mappers_1.mapApiImage)(image, response.id, thumbnailDomain, complianceOptions)), { renderedOutputs: imageRenderedOutputs, views: imageViews }));
    });
    return {
        images: images,
        renderedOutputs: renderedOutputs,
        views: views,
        viewIds: viewIds,
        renderedOutputIds: renderedOutputIds,
        imageIds: imageIds,
    };
}
function mapParts(response) {
    var _a;
    var parts = [];
    var partIds = [];
    (_a = response.parts) === null || _a === void 0 ? void 0 : _a.forEach(function (part) {
        var _a, _b, _c;
        partIds.push(part.id);
        parts.push({
            id: part.id,
            entityType: types_1.MonkEntityType.PART,
            inspectionId: response.id,
            type: part.part_type,
            damages: (_a = part.damage_ids) !== null && _a !== void 0 ? _a : [],
            relatedImages: (_c = (_b = part.related_images) === null || _b === void 0 ? void 0 : _b.map(function (relatedImage) { return relatedImage.base_image_id; })) !== null && _c !== void 0 ? _c : [],
        });
    });
    return { partIds: partIds, parts: parts };
}
function mapPricingV2Details(apiPricingV2Details, inspectionId) {
    var details = apiPricingV2Details;
    return {
        inspectionId: inspectionId,
        id: details.id,
        entityType: types_1.MonkEntityType.PRICING,
        relatedItemType: details.related_item_type,
        relatedItemId: details.related_item_id,
        pricing: details.pricing,
        operations: details.operations,
        hours: details.hours,
    };
}
function mapPricingV2(response) {
    var pricings = [];
    var pricingIds = [];
    if (!response.pricing) {
        return { pricings: pricings, pricingIds: pricingIds };
    }
    Object.values(response.pricing.details).forEach(function (details) {
        pricingIds.push(details.id);
        pricings.push(mapPricingV2Details(details, response.id));
    });
    return { pricings: pricings, pricingIds: pricingIds };
}
function mapSeverityResultRepairOperation(severityResult) {
    var partSeverity = severityResult;
    if (!(partSeverity === null || partSeverity === void 0 ? void 0 : partSeverity.repair_operation)) {
        return undefined;
    }
    return {
        t1: partSeverity.repair_operation.T1,
        t2: partSeverity.repair_operation.T2,
        paint: partSeverity.repair_operation.PAINT,
        replace: partSeverity.repair_operation.REPLACE,
        additional: partSeverity.repair_operation.ADDITIONAL,
    };
}
function mapSeverityResultValue(severityResult) {
    var _a;
    if (!((_a = severityResult === null || severityResult === void 0 ? void 0 : severityResult.value) === null || _a === void 0 ? void 0 : _a.custom_severity)) {
        return undefined;
    }
    return {
        comment: severityResult.value.custom_severity.comment,
        level: severityResult.value.custom_severity.level,
        pricing: severityResult.value.custom_severity.pricing,
        repairOperation: mapSeverityResultRepairOperation(severityResult),
    };
}
function mapSeverityResults(response) {
    var _a;
    var severityResults = [];
    var severityResultIds = [];
    (_a = response.severity_results) === null || _a === void 0 ? void 0 : _a.forEach(function (severityResult) {
        severityResultIds.push(severityResult.id);
        severityResults.push({
            id: severityResult.id,
            inspectionId: response.id,
            entityType: types_1.MonkEntityType.SEVERITY_RESULT,
            label: severityResult.label,
            isUserModified: severityResult.is_user_modified,
            relatedItemId: severityResult.related_item_id,
            relatedItemType: severityResult.related_item_type,
            value: mapSeverityResultValue(severityResult),
        });
    });
    return { severityResultIds: severityResultIds, severityResults: severityResults };
}
function mapTasks(response) {
    var _a;
    var tasks = [];
    var taskIds = [];
    (_a = response.tasks) === null || _a === void 0 ? void 0 : _a.forEach(function (task) {
        var _a, _b;
        taskIds.push(task.id);
        tasks.push({
            id: task.id,
            entityType: types_1.MonkEntityType.TASK,
            inspectionId: response.id,
            name: task.name,
            status: task.status,
            images: (_b = (_a = task.images) === null || _a === void 0 ? void 0 : _a.map(function (image) { return image.image_id; })) !== null && _b !== void 0 ? _b : [],
        });
    });
    return { taskIds: taskIds, tasks: tasks };
}
function mapVehicle(response) {
    return (response === null || response === void 0 ? void 0 : response.vehicle)
        ? {
            id: response.vehicle.id,
            inspectionId: response.id,
            entityType: types_1.MonkEntityType.VEHICLE,
            brand: response.vehicle.brand,
            model: response.vehicle.model,
            serie: response.vehicle.serie,
            plate: response.vehicle.plate,
            type: response.vehicle.vehicle_type,
            mileageUnit: response.vehicle.mileage_unit,
            mileageValue: response.vehicle.mileage_value,
            marketValueUnit: response.vehicle.market_value_unit,
            marketValue: response.vehicle.market_value_value,
            vin: response.vehicle.vin,
            color: response.vehicle.color,
            exteriorCleanliness: response.vehicle.exterior_cleanliness,
            interiorCleanliness: response.vehicle.interior_cleanliness,
            dateOfCirculation: response.vehicle.date_of_circulation,
            duplicateKeys: response.vehicle.duplicate_keys,
            expertiseRequested: response.vehicle.expertise_requested,
            carRegistration: response.vehicle.car_registration,
            vehicleQuotation: response.vehicle.vehicle_quotation,
            tradeInOffer: response.vehicle.trade_in_offer,
            ownerInfo: response.vehicle.owner_info,
            additionalData: response.vehicle.additional_data,
        }
        : undefined;
}
function mapWheelAnalysis(response) {
    var _a, _b;
    return ((_b = (_a = response.wheel_analysis) === null || _a === void 0 ? void 0 : _a.map(function (wheelAnalysis) { return ({
        inspectionId: response.id,
        rimCondition: wheelAnalysis.rim_condition,
        rimMaterial: wheelAnalysis.rim_material,
        rimVisualAspect: wheelAnalysis.rim_visual_aspect,
        hubcapOverRim: wheelAnalysis.hubcap_over_rim,
        hubcapCondition: wheelAnalysis.hubcap_condition,
        hubcapVisualAspect: wheelAnalysis.hubcap_visual_aspect,
        imageId: wheelAnalysis.image_id,
        wheelName: wheelAnalysis.wheel_name,
    }); })) !== null && _b !== void 0 ? _b : []);
}
function mapInspection(response, ids) {
    return {
        id: response.id,
        entityType: types_1.MonkEntityType.INSPECTION,
        tasks: ids.taskIds,
        images: ids.imageIds,
        damages: ids.damageIds,
        parts: ids.partIds,
        vehicle: ids.vehicleId,
        wheelAnalysis: mapWheelAnalysis(response),
        severityResults: ids.severityResultIds,
        pricings: ids.pricingIds,
        additionalData: response.additional_data,
    };
}
function mapApiInspectionsGet(response, thumbnailDomain) {
    var state = {
        damages: [],
        images: [],
        inspections: [],
        parts: [],
        renderedOutputs: [],
        severityResults: [],
        tasks: [],
        vehicles: [],
        views: [],
        pricings: [],
        partOperations: [],
    };
    if (!response.data) {
        return state;
    }
    return response.data.reduce(function (acc, inspection) {
        var _a, _b, _c, _d, _e;
        var _f = mapImages(inspection, thumbnailDomain), images = _f.images, renderedOutputs = _f.renderedOutputs, imageIds = _f.imageIds, renderedOutputIds = _f.renderedOutputIds, viewIds = _f.viewIds;
        var _g = mapDamages(inspection), damages = _g.damages, damageIds = _g.damageIds;
        var _h = mapParts(inspection), parts = _h.parts, partIds = _h.partIds;
        var _j = mapPricingV2(inspection), pricings = _j.pricings, pricingIds = _j.pricingIds;
        var vehicle = mapVehicle(inspection);
        var mappedInspection = mapInspection(inspection, {
            imageIds: imageIds,
            renderedOutputIds: renderedOutputIds,
            viewIds: viewIds,
            damageIds: damageIds,
            partIds: partIds,
            severityResultIds: [],
            taskIds: [],
            pricingIds: pricingIds,
            vehicleId: vehicle === null || vehicle === void 0 ? void 0 : vehicle.id,
        });
        (_a = acc.damages).push.apply(_a, damages);
        (_b = acc.images).push.apply(_b, images);
        acc.inspections.push(mappedInspection);
        (_c = acc.parts).push.apply(_c, parts);
        (_d = acc.renderedOutputs).push.apply(_d, renderedOutputs);
        if (vehicle) {
            acc.vehicles.push(vehicle);
        }
        (_e = acc.pricings).push.apply(_e, pricings);
        return acc;
    }, state);
}
exports.mapApiInspectionsGet = mapApiInspectionsGet;
function mapApiInspectionGet(response, thumbnailDomain, complianceOptions) {
    var _a = mapImages(response, thumbnailDomain, complianceOptions), images = _a.images, renderedOutputs = _a.renderedOutputs, views = _a.views, imageIds = _a.imageIds, renderedOutputIds = _a.renderedOutputIds, viewIds = _a.viewIds;
    var _b = mapDamages(response), damages = _b.damages, damageIds = _b.damageIds;
    var _c = mapParts(response), parts = _c.parts, partIds = _c.partIds;
    var _d = mapSeverityResults(response), severityResults = _d.severityResults, severityResultIds = _d.severityResultIds;
    var _e = mapTasks(response), tasks = _e.tasks, taskIds = _e.taskIds;
    var _f = mapPricingV2(response), pricings = _f.pricings, pricingIds = _f.pricingIds;
    var vehicle = mapVehicle(response);
    var inspection = mapInspection(response, {
        imageIds: imageIds,
        renderedOutputIds: renderedOutputIds,
        viewIds: viewIds,
        damageIds: damageIds,
        partIds: partIds,
        severityResultIds: severityResultIds,
        taskIds: taskIds,
        pricingIds: pricingIds,
    });
    return {
        damages: damages,
        images: images,
        inspections: [inspection],
        parts: parts,
        renderedOutputs: renderedOutputs,
        severityResults: severityResults,
        tasks: tasks,
        vehicles: vehicle ? [vehicle] : [],
        views: views,
        pricings: pricings,
        partOperations: [],
    };
}
exports.mapApiInspectionGet = mapApiInspectionGet;
function getHumanInTheLoopOptions(options) {
    if (options.tasks.includes(types_1.TaskName.HUMAN_IN_THE_LOOP)) {
        return { status: types_1.ProgressStatus.NOT_STARTED };
    }
    var taskOptions = options.tasks.find(function (task) { return typeof task === 'object' && task.name === types_1.TaskName.HUMAN_IN_THE_LOOP; });
    return taskOptions
        ? {
            status: types_1.ProgressStatus.NOT_STARTED,
            callbacks: taskOptions.callbacks,
        }
        : undefined;
}
function getDamageDetectionOptions(options) {
    if (options.tasks.includes(types_1.TaskName.DAMAGE_DETECTION)) {
        return {
            status: types_1.ProgressStatus.NOT_STARTED,
            damage_score_threshold: 0.3,
            generate_subimages_parts: {
                generate_tight: false,
            },
            generate_visual_output: {
                generate_damages: true,
            },
        };
    }
    var taskOptions = options.tasks.find(function (task) { return typeof task === 'object' && task.name === types_1.TaskName.DAMAGE_DETECTION; });
    return taskOptions
        ? {
            status: types_1.ProgressStatus.NOT_STARTED,
            damage_score_threshold: taskOptions.damageScoreThreshold,
            generate_visual_output: {
                generate_damages: taskOptions.generateDamageVisualOutput,
            },
            generate_subimages_damages: taskOptions.generateSubimageDamages ? {} : undefined,
            generate_subimages_parts: taskOptions.generateSubimageParts
                ? { generate_tight: false }
                : undefined,
        }
        : undefined;
}
function getWheelAnalysisOptions(options) {
    return options.tasks.includes(types_1.TaskName.WHEEL_ANALYSIS)
        ? {
            status: types_1.ProgressStatus.NOT_STARTED,
            use_longshots: true,
        }
        : undefined;
}
function getImagesOCROptions(options) {
    return options.tasks.includes(types_1.TaskName.IMAGES_OCR)
        ? {
            status: types_1.ProgressStatus.NOT_STARTED,
        }
        : undefined;
}
function getPricingOptions(options) {
    var _a;
    if (options.tasks.includes(types_1.TaskName.PRICING)) {
        return {
            status: types_1.ProgressStatus.TODO,
            output_format: 'default',
        };
    }
    var taskOptions = options.tasks.find(function (task) { return typeof task === 'object' && task.name === types_1.TaskName.PRICING; });
    return taskOptions
        ? {
            status: types_1.ProgressStatus.TODO,
            output_format: (_a = taskOptions.outputFormat) !== null && _a !== void 0 ? _a : 'default',
            config: taskOptions.config,
        }
        : undefined;
}
function getTasksOptions(options) {
    return {
        damage_detection: getDamageDetectionOptions(options),
        wheel_analysis: getWheelAnalysisOptions(options),
        images_ocr: getImagesOCROptions(options),
        human_in_the_loop: getHumanInTheLoopOptions(options),
        pricing: getPricingOptions(options),
    };
}
function mapApiInspectionPost(options) {
    var _a, _b;
    return {
        tasks: getTasksOptions(options),
        vehicle: options.vehicle
            ? {
                brand: options.vehicle.brand,
                model: options.vehicle.model,
                serie: options.vehicle.serie,
                plate: options.vehicle.plate,
                vehicle_type: options.vehicle.type,
                mileage: options.vehicle.mileageUnit && options.vehicle.mileageValue
                    ? {
                        value: options.vehicle.mileageValue,
                        unit: options.vehicle.mileageUnit,
                    }
                    : undefined,
                market_value: options.vehicle.marketValueUnit && options.vehicle.marketValue
                    ? {
                        value: options.vehicle.marketValue,
                        unit: options.vehicle.marketValueUnit,
                    }
                    : undefined,
                vin: options.vehicle.vin,
                color: options.vehicle.color,
                exterior_cleanliness: options.vehicle.exteriorCleanliness,
                interior_cleanliness: options.vehicle.interiorCleanliness,
                date_of_circulation: options.vehicle.dateOfCirculation,
                duplicate_keys: options.vehicle.duplicateKeys,
                expertise_requested: options.vehicle.expertiseRequested,
                car_registration: options.vehicle.carRegistration,
                vehicle_quotation: options.vehicle.vehicleQuotation,
                trade_in_offer: options.vehicle.tradeInOffer,
                owner_info: options.vehicle.ownerInfo,
                additional_data: options.vehicle.additionalData,
            }
            : undefined,
        damage_severity: options.enablePricingV1 === false ? undefined : { output_format: 'toyota' },
        additional_data: __assign({ user_agent: navigator.userAgent, connection: navigator.connection, monk_sdk_version: config_1.sdkVersion, damage_detection_version: 'v2', use_dynamic_crops: (_a = options.useDynamicCrops) !== null && _a !== void 0 ? _a : true, is_video_capture: (_b = options.isVideoCapture) !== null && _b !== void 0 ? _b : false }, options.additionalData),
    };
}
exports.mapApiInspectionPost = mapApiInspectionPost;
function mapApiInspectionsUrlParamsGet(options) {
    var params = new URLSearchParams();
    var url = options.count ? '/count' : '';
    url = options.filters || options.pagination ? "".concat(url, "?") : url;
    if (options.filters) {
        Object.entries(options.filters).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            params.append(key, value.toString());
        });
    }
    if (options.pagination) {
        Object.entries(options.pagination).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            params.append(key, value.toString());
        });
    }
    if (options.sort) {
        Object.entries(options.sort).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            params.append(key, value.toString());
        });
    }
    return "".concat(url).concat(params.toString());
}
exports.mapApiInspectionsUrlParamsGet = mapApiInspectionsUrlParamsGet;
