"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InspectionGalleryItemCard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var sights_1 = require("@monkvision/sights");
var hooks_1 = require("./hooks");
var icons_1 = require("../../../icons");
var SightOverlay_1 = require("../../SightOverlay");
function InspectionGalleryItemCard(_a) {
    var item = _a.item, captureMode = _a.captureMode, onClick = _a.onClick;
    var _b = (0, common_1.useInteractiveStatus)(), status = _b.status, eventHandlers = _b.eventHandlers;
    var _c = (0, hooks_1.useInspectionGalleryItemCardStyles)({ item: item, captureMode: captureMode, status: status }), cardStyle = _c.cardStyle, previewStyle = _c.previewStyle, previewOverlayStyle = _c.previewOverlayStyle, labelStyle = _c.labelStyle, statusIcon = _c.statusIcon, sightOverlay = _c.sightOverlay, addDamageIcon = _c.addDamageIcon;
    var statusIconName = (0, hooks_1.useInspectionGalleryItemStatusIconName)({ item: item, captureMode: captureMode });
    var label = (0, hooks_1.useInspectionGalleryItemLabel)(item);
    return ((0, jsx_runtime_1.jsxs)("button", __assign({ style: cardStyle, onClick: onClick, "data-testid": 'card-btn' }, eventHandlers, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ style: previewStyle, "data-testid": 'preview' }, { children: [item.isAddDamage && ((0, jsx_runtime_1.jsx)(icons_1.Icon, { icon: 'add-circle', size: addDamageIcon.size, primaryColor: addDamageIcon.primaryColor })), !item.isAddDamage && !item.isTaken && ((0, jsx_runtime_1.jsx)(SightOverlay_1.SightOverlay, { sight: sights_1.sights[item.sightId], height: sightOverlay.height })), (0, jsx_runtime_1.jsx)("div", { "data-testid": 'preview-overlay', style: previewOverlayStyle }), statusIconName && ((0, jsx_runtime_1.jsx)(icons_1.Icon, { icon: statusIconName, size: statusIcon.size, primaryColor: statusIcon.primaryColor, style: statusIcon.style }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ style: labelStyle }, { children: label }))] })));
}
exports.InspectionGalleryItemCard = InspectionGalleryItemCard;
