"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePreventExit = void 0;
var react_1 = require("react");
var store_1 = require("./store");
var hooks_1 = require("../hooks");
/**
 * Custom hook that allows preventing the user from exiting the page or navigating away.
 *
 * @param preventExit - A boolean value indicating whether to prevent the user from exiting the page or not.
 *
 * @example
 * ```tsx
 * function MyComponent() {
 *   const { allowRedirect } = usePreventExit(true);
 *   return <div onClick={allowRedirect}>My Component</div>;
 * }
 * ```
 */
function usePreventExit(preventExit) {
    var _a = (0, react_1.useMemo)(store_1.createPreventExitListener, []), cleanup = _a.cleanup, setPreventExit = _a.setPreventExit, allowRedirect = _a.allowRedirect;
    (0, react_1.useEffect)(function () { return setPreventExit(preventExit); }, [preventExit]);
    (0, react_1.useEffect)(function () { return cleanup; }, []);
    return (0, hooks_1.useObjectMemo)({ allowRedirect: allowRedirect });
}
exports.usePreventExit = usePreventExit;
