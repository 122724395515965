"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInteractiveVariants = exports.InteractiveVariation = exports.changeAlpha = exports.shadeColor = exports.getHexFromRGBA = exports.getRGBAFromString = void 0;
var types_1 = require("@monkvision/types");
var RGBA_REGEXP = /^rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*(\d+(?:\.\d+)?)\s*)?\)$/i;
var HEX_REGEXP = /^#(?:(?:[0-9a-f]{3}){1,2}|(?:[0-9a-f]{4}){1,2})$/i;
function getRGBAFromRGBAString(str) {
    var result = str.match(RGBA_REGEXP);
    if (!result) {
        return null;
    }
    return {
        r: Number(result[1]),
        g: Number(result[2]),
        b: Number(result[3]),
        a: result[4] ? Number(result[4]) : 1,
    };
}
function getRGBAFromHexString(str) {
    var _a;
    if (!HEX_REGEXP.test(str)) {
        return null;
    }
    var colors = [];
    var increment = str.length > 5 ? 2 : 1;
    for (var i = 1; i < str.length; i += increment) {
        colors.push(str.substring(i, i + increment));
    }
    colors = colors.map(function (hex) { return parseInt(hex.length > 1 ? hex : "".concat(hex).concat(hex), 16); });
    return {
        r: colors[0],
        g: colors[1],
        b: colors[2],
        a: ((_a = colors[3]) !== null && _a !== void 0 ? _a : 255) / 255,
    };
}
function convertColorToHexCode(value) {
    var rounded = Math.round(value);
    rounded = rounded > 255 ? 255 : rounded;
    var hex = rounded.toString(16);
    return hex.length > 1 ? hex : "0".concat(hex);
}
/**
 * Returns the RGBA values of the given color. The accepted formats are :
 * - RGB : rgb(167, 224, 146)
 * - RGBA : rgb(167, 224, 146, 0.03)
 * - HEX : #A7E092
 * - HEX (alpha) : #A7E09208
 * - HEX (short) : #AE9
 * - HEX (short + alpha) : #AE98
 *
 * This function is case-insensitive and ignores white spaces.
 */
function getRGBAFromString(str) {
    var trimmed = str.trim();
    var result = trimmed.length > 9 ? getRGBAFromRGBAString(trimmed) : getRGBAFromHexString(trimmed);
    if (!result) {
        throw new Error("Invalid color format : ".concat(trimmed));
    }
    return result;
}
exports.getRGBAFromString = getRGBAFromString;
/**
 * Converts RGBA values to their hexadecimal representation.
 */
function getHexFromRGBA(rgba) {
    var r = convertColorToHexCode(rgba.r);
    var g = convertColorToHexCode(rgba.g);
    var b = convertColorToHexCode(rgba.b);
    var a = convertColorToHexCode(rgba.a * 255);
    return "#".concat(r).concat(g).concat(b).concat(a);
}
exports.getHexFromRGBA = getHexFromRGBA;
/**
 * Apply a shade of black or white over the given color.
 *
 * @param color A color using the same format accepted by the `getRGBAFromString` function.
 * @param amount The amount of shade to apply, as a ratio :
 * - positive values like 0.08 to lighten the color by 8%
 * - negative values like -0.08 to darken the color by 8%
 * @see getRGBAFromString
 */
function shadeColor(color, amount) {
    var _a = getRGBAFromString(color), a = _a.a, rgb = __rest(_a, ["a"]);
    Object.entries(rgb).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        var shaded = value * (1 + amount);
        rgb[key] = shaded > 255 ? 255 : shaded;
    });
    return getHexFromRGBA(__assign({ a: a }, rgb));
}
exports.shadeColor = shadeColor;
/**
 * Returns a new color equal to the given color but with a different alpha value.
 *
 * @param color The color to change the alpha value of.
 * @param amount The alpha value (from 0 to 1).
 */
function changeAlpha(color, amount) {
    var _a = getRGBAFromString(color), _ = _a.a, rgb = __rest(_a, ["a"]);
    return getHexFromRGBA(__assign({ a: amount }, rgb));
}
exports.changeAlpha = changeAlpha;
/**
 * The different ways an interactive element's style is altered when the user interacts with it.
 */
var InteractiveVariation;
(function (InteractiveVariation) {
    /**
     * The element will darken when the user interacts with it (used for light elements).
     */
    InteractiveVariation["DARKEN"] = "darken";
    /**
     * The element will lighten when the user interacts with it (used for dark elements).
     */
    InteractiveVariation["LIGHTEN"] = "lighten";
})(InteractiveVariation = exports.InteractiveVariation || (exports.InteractiveVariation = {}));
/**
 * Create interactive variants (hovered, active...) for the given color.
 */
function getInteractiveVariants(color, variant) {
    var _a;
    if (variant === void 0) { variant = InteractiveVariation.LIGHTEN; }
    var factor = variant === InteractiveVariation.LIGHTEN ? 1 : -1;
    return _a = {},
        _a[types_1.InteractiveStatus.DEFAULT] = color,
        _a[types_1.InteractiveStatus.HOVERED] = shadeColor(color, factor * 0.08),
        _a[types_1.InteractiveStatus.ACTIVE] = shadeColor(color, factor * 0.12),
        _a[types_1.InteractiveStatus.DISABLED] = color,
        _a;
}
exports.getInteractiveVariants = getInteractiveVariants;
