"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deletedOneDamage = exports.isDeletedOneDamageAction = void 0;
var monkAction_1 = require("./monkAction");
/**
 * Matcher function that matches a DeletedOneDamage while also inferring its type using TypeScript's type predicate
 * feature.
 */
function isDeletedOneDamageAction(action) {
    return action.type === monkAction_1.MonkActionType.DELETED_ONE_DAMAGE;
}
exports.isDeletedOneDamageAction = isDeletedOneDamageAction;
/**
 * Reducer function for a deletedOneDamage action.
 */
function deletedOneDamage(state, action) {
    var _a;
    var damages = state.damages, parts = state.parts, inspections = state.inspections;
    var payload = action.payload;
    var inspection = inspections.find(function (value) { return value.id === payload.inspectionId; });
    if (inspection) {
        inspection.damages = (_a = inspection.damages) === null || _a === void 0 ? void 0 : _a.filter(function (damageId) { return damageId !== payload.damageId; });
    }
    var newDamages = damages.filter(function (damage) { return damage.id !== payload.damageId; });
    var newParts = parts.map(function (part) {
        if (part.damages.includes(payload.damageId)) {
            return __assign(__assign({}, part), { damages: part.damages.filter(function (damageId) { return damageId !== payload.damageId; }) });
        }
        return part;
    });
    return __assign(__assign({}, state), { parts: newParts, damages: __spreadArray([], newDamages, true), inspections: __spreadArray([], inspections, true) });
}
exports.deletedOneDamage = deletedOneDamage;
