"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLanguage = exports.i18nWrap = exports.i18nCreateSDKInstance = exports.useI18nSync = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var monitoring_1 = require("@monkvision/monitoring");
var i18next_1 = require("i18next");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var types_1 = require("@monkvision/types");
/**
 * This custom hook automatically updates the current i18n instance's language with the given language if is it not null
 * and supported by the MonkJs SDK.
 */
function useI18nSync(language) {
    var instance = (0, react_i18next_1.useTranslation)().i18n;
    var handleError = (0, monitoring_1.useMonitoring)().handleError;
    (0, react_1.useEffect)(function () {
        if (!language) {
            return;
        }
        if (types_1.monkLanguages.every(function (supportedLang) { return !language.toLowerCase().startsWith(supportedLang.toLowerCase()); })) {
            handleError(new Error("Unsupported language passed to the MonkJs SDK : ".concat(language, ". Currently supported languages are : ").concat(types_1.monkLanguages, ".")));
            return;
        }
        instance.changeLanguage(language).catch(handleError);
    }, [language, instance.changeLanguage]);
}
exports.useI18nSync = useI18nSync;
/**
 * This utility function is used my Monk SDK packages to create an i18n instance that can be imported by applications
 * using them in order to link the different instances together (and synchronizes values such as the current language
 * etc.).
 *
 * @param resources The list of translations of the package.
 * @return A configured i18n instance.
 */
function i18nCreateSDKInstance(_a) {
    var resources = _a.resources;
    var instance = (0, i18next_1.createInstance)({
        compatibilityJSON: 'v3',
        fallbackLng: 'en',
        interpolation: { escapeValue: false },
        resources: resources,
    });
    instance.use(react_i18next_1.initReactI18next).init().catch(console.error);
    return instance;
}
exports.i18nCreateSDKInstance = i18nCreateSDKInstance;
/**
 * This function is used internally by the Monk SDK to wrap its exported components in an I18nextProvider. The ref
 * passed to the resulting component is forwarded to the wrapped component.
 *
 * @param Component The component to wrap in the provider. It should be an functional component
 * @param instance The i18n instance created using the `i18nCreateSDKInstance` function.
 * @typeParam T - The type of the ref of the wrapped component.
 * @typeParam P - The type of the props of the wrapped component.
 * @return The Component passed in the arguments. wrapped in an I18nextProvider.
 */
function i18nWrap(Component, instance) {
    return (0, react_1.forwardRef)(function I18nWrappedComponent(props, ref) {
        return ((0, jsx_runtime_1.jsx)(react_i18next_1.I18nextProvider, __assign({ i18n: instance }, { children: (0, jsx_runtime_1.jsx)(Component, __assign({ ref: ref }, props)) })));
    });
}
exports.i18nWrap = i18nWrap;
/**
 * This function retrieves the language prefix from a given language string.
 * If the prefix is not found in the list of supported languages (monkLanguages in Types package), defaults to 'en'.
 *
 * @param language - The full language string (e.g., 'en-US', 'fr-CA').
 * @returns The language prefix if it is supported; otherwise, 'en'.
 */
function getLanguage(language) {
    var languagePrefix = language.slice(0, 2);
    return types_1.monkLanguages.includes(languagePrefix) ? languagePrefix : 'en';
}
exports.getLanguage = getLanguage;
