"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useImageDetailedViewOverlayStyles = exports.useImageLabelIcon = exports.useRetakeOverlay = exports.isImageValid = void 0;
var types_1 = require("@monkvision/types");
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var ImageDetailedViewOverlay_styles_1 = require("./ImageDetailedViewOverlay.styles");
var ImageDetailedView_styles_1 = require("../ImageDetailedView.styles");
function isImageValid(props) {
    return (!props.captureMode ||
        ![types_1.ImageStatus.UPLOAD_FAILED, types_1.ImageStatus.UPLOAD_ERROR, types_1.ImageStatus.NOT_COMPLIANT].includes(props.image.status));
}
exports.isImageValid = isImageValid;
function useRetakeOverlay(props) {
    var tObj = (0, common_1.useObjectTranslation)().tObj;
    var success = {
        title: tObj(common_1.imageStatusLabels[types_1.ImageStatus.SUCCESS].title),
        description: tObj(common_1.imageStatusLabels[types_1.ImageStatus.SUCCESS].description),
        iconColor: 'text-secondary',
        icon: 'check-circle',
        buttonColor: 'primary',
    };
    if (isImageValid(props)) {
        return success;
    }
    if (props.image.status === types_1.ImageStatus.UPLOAD_FAILED) {
        return {
            title: tObj(common_1.imageStatusLabels[types_1.ImageStatus.UPLOAD_FAILED].title),
            description: tObj(common_1.imageStatusLabels[types_1.ImageStatus.UPLOAD_FAILED].description),
            iconColor: 'alert',
            icon: 'wifi-off',
            buttonColor: 'alert',
        };
    }
    if (props.image.status === types_1.ImageStatus.UPLOAD_ERROR) {
        return {
            title: tObj(common_1.imageStatusLabels[types_1.ImageStatus.UPLOAD_ERROR].title),
            description: tObj(common_1.imageStatusLabels[types_1.ImageStatus.UPLOAD_ERROR].description),
            iconColor: 'alert',
            icon: 'sync-problem',
            buttonColor: 'alert',
        };
    }
    if (!props.image.complianceIssues || props.image.complianceIssues.length === 0) {
        return success;
    }
    return {
        title: tObj(common_1.complianceIssueLabels[props.image.complianceIssues[0]].title),
        description: tObj(common_1.complianceIssueLabels[props.image.complianceIssues[0]].description),
        iconColor: 'alert',
        icon: 'error',
        buttonColor: 'alert',
    };
}
exports.useRetakeOverlay = useRetakeOverlay;
function useImageLabelIcon(props) {
    var palette = (0, common_1.useMonkTheme)().palette;
    var colors = (0, react_1.useMemo)(function () { return ({
        grey: (0, common_1.changeAlpha)(palette.text.black, 0.5),
    }); }, [palette]);
    if (!props.captureMode) {
        return null;
    }
    switch (props.image.status) {
        case types_1.ImageStatus.UPLOADING:
            return { icon: 'processing', primaryColor: colors.grey };
        case types_1.ImageStatus.COMPLIANCE_RUNNING:
            return { icon: 'processing', primaryColor: colors.grey };
        case types_1.ImageStatus.SUCCESS:
            return { icon: 'check-circle', primaryColor: 'text-black' };
        case types_1.ImageStatus.UPLOAD_FAILED:
            return { icon: 'wifi-off', primaryColor: 'alert' };
        case types_1.ImageStatus.UPLOAD_ERROR:
            return { icon: 'sync-problem', primaryColor: 'alert' };
        case types_1.ImageStatus.NOT_COMPLIANT:
            return { icon: 'error', primaryColor: 'alert' };
        default:
            return null;
    }
}
exports.useImageLabelIcon = useImageLabelIcon;
function useImageDetailedViewOverlayStyles(props) {
    var dimensions = (0, common_1.useWindowDimensions)();
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    var palette = (0, common_1.useMonkTheme)().palette;
    return {
        mainContainerStyle: __assign(__assign({}, ImageDetailedViewOverlay_styles_1.styles['mainContainer']), responsive(ImageDetailedViewOverlay_styles_1.styles['mainContainerSmall'])),
        overlayDisplayStyle: __assign(__assign({}, ImageDetailedViewOverlay_styles_1.styles['overlayDisplay']), { justifyContent: props.image.label ? 'space-between' : 'start' }),
        complianceContainerStyle: __assign(__assign({}, ImageDetailedViewOverlay_styles_1.styles['complianceContainer']), responsive(ImageDetailedViewOverlay_styles_1.styles['complianceContainerSmall'])),
        complianceMessageContainerStyle: ImageDetailedViewOverlay_styles_1.styles['complianceMessageContainer'],
        complianceIcon: {
            size: 20,
        },
        complianceRetakeButton: {
            style: __assign(__assign({}, ImageDetailedViewOverlay_styles_1.styles['complianceRetakeButton']), responsive(ImageDetailedViewOverlay_styles_1.styles['complianceRetakeButtonSmall'])),
            size: (dimensions.width >= ImageDetailedView_styles_1.SMALL_WIDTH_BREAKPOINT ? 'normal' : 'small'),
        },
        complianceMessageStyle: __assign(__assign({}, ImageDetailedViewOverlay_styles_1.styles['complianceMessage']), { color: palette.text.primary }),
        complianceTitleStyle: ImageDetailedViewOverlay_styles_1.styles['complianceTitle'],
        complianceDescriptionStyle: ImageDetailedViewOverlay_styles_1.styles['complianceDescription'],
        imageLabelStyle: __assign(__assign({}, ImageDetailedViewOverlay_styles_1.styles['imageLabel']), { backgroundColor: palette.surface.light, color: palette.text.black }),
        imageLabelIcon: {
            style: ImageDetailedViewOverlay_styles_1.styles['imageLabelIcon'],
            size: 20,
        },
    };
}
exports.useImageDetailedViewOverlayStyles = useImageDetailedViewOverlayStyles;
