"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSlider = void 0;
var react_1 = require("react");
function getFirstThumbPosition(min, max, value) {
    if (value > max) {
        return 100;
    }
    if (value < min) {
        return 0;
    }
    return ((value - min) / (max - min)) * 100;
}
function getThumbPosition(event, sliderRef, step, min, max) {
    if (!sliderRef.current) {
        return 0;
    }
    var sliderElement = sliderRef.current;
    var sliderRect = sliderElement.getBoundingClientRect();
    var offsetX = Math.max(0, Math.min(sliderRect.width, (event instanceof MouseEvent ? event.clientX : event.touches[0].clientX) - sliderRect.left));
    var positionPercentage = (offsetX / sliderRect.width) * 100;
    var stepPercentage = 100 / ((max - min) / step);
    return Math.round(positionPercentage / stepPercentage) * stepPercentage;
}
function getNewSliderValue(roundedPercentage, max, min, step) {
    var multiplier = 1;
    if (!Number.isInteger(step)) {
        var nbDigitAfterDot = step.toString().split('.')[1].length;
        multiplier = Math.pow(10, nbDigitAfterDot);
    }
    return Math.round(((max - min) * (roundedPercentage / 100) + min) * multiplier) / multiplier;
}
function useSlider(_a) {
    var sliderRef = _a.sliderRef, value = _a.value, min = _a.min, max = _a.max, step = _a.step, disabled = _a.disabled, onChange = _a.onChange;
    var _b = (0, react_1.useState)(getFirstThumbPosition(min, max, value)), thumbPosition = _b[0], setThumbPosition = _b[1];
    var _c = (0, react_1.useState)(false), isDragging = _c[0], setIsDragging = _c[1];
    var handleStart = function () {
        setIsDragging(true);
    };
    var handleEnd = function () {
        setIsDragging(false);
    };
    var handleMove = function (event) {
        event.preventDefault();
        if (!disabled && max > min && onChange) {
            var roundedPercentage = getThumbPosition(event, sliderRef, step, min, max);
            setThumbPosition(roundedPercentage);
            var newValue = getNewSliderValue(roundedPercentage, max, min, step);
            onChange(newValue);
        }
    };
    (0, react_1.useLayoutEffect)(function () {
        if (!isDragging) {
            return function () { };
        }
        document.addEventListener('mousemove', handleMove);
        document.addEventListener('touchmove', handleMove, { passive: false });
        return function () {
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('touchmove', handleMove);
        };
    }, [isDragging]);
    (0, react_1.useLayoutEffect)(function () {
        var _a;
        document.addEventListener('mouseup', handleEnd);
        document.addEventListener('touchend', handleEnd);
        (_a = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('click', handleMove);
        return function () {
            var _a;
            document.removeEventListener('mouseup', handleEnd);
            document.removeEventListener('touchend', handleEnd);
            (_a = sliderRef === null || sliderRef === void 0 ? void 0 : sliderRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('click', handleMove);
        };
    }, []);
    return { thumbPosition: thumbPosition, handleStart: handleStart, isDragging: isDragging };
}
exports.useSlider = useSlider;
