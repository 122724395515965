"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCamelCase = exports.uncapitalize = exports.capitalize = exports.words = exports.suffix = void 0;
var WORD_PATTERN = /[A-Z][a-z]+|[A-Z]+(?=[A-Z][a-z])|[A-Z]+|[a-z]+|\d+/g;
/**
 * Suffixes a string with the given suffixes, only if their value is `true` in the suffixes object param.
 *
 * Note : The order of the suffixes is not guaranteed.
 */
function suffix(str, suffixes, separator) {
    if (separator === void 0) { separator = ' '; }
    return suffixes
        ? Object.keys(suffixes).reduce(function (prev, curr) { return "".concat(prev).concat(suffixes[curr] ? separator + curr : ''); }, str)
        : str;
}
exports.suffix = suffix;
/**
 * Split the given string into its composing words.
 */
function words(str) {
    var _a;
    return (_a = str.match(WORD_PATTERN)) !== null && _a !== void 0 ? _a : [];
}
exports.words = words;
/**
 * Capitalizes (transforms the first character to upper case) the given string.
 */
function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
exports.capitalize = capitalize;
/**
 * Uncapitalizes (transforms the first character to lower case) the given string.
 */
function uncapitalize(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}
exports.uncapitalize = uncapitalize;
/**
 * Converts a string to camel case.
 */
function toCamelCase(str) {
    return words(str)
        .map(function (word, index) { return (index === 0 ? word.toLowerCase() : capitalize(word.toLowerCase())); })
        .join('');
}
exports.toCamelCase = toCamelCase;
