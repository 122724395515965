"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.beforeError = exports.MonkNetworkError = void 0;
/**
 * Enumeration of names of known error that can occur when making Monk api requests.
 */
var MonkNetworkError;
(function (MonkNetworkError) {
    /**
     * The authentication token was not provided.
     */
    MonkNetworkError["MISSING_TOKEN"] = "MissingToken";
    /**
     * The authentication token provided in the request is invalid (badly formatted etc.).
     */
    MonkNetworkError["INVALID_TOKEN"] = "InvalidToken";
    /**
     * The authentication token provided in the request is expired.
     */
    MonkNetworkError["EXPIRED_TOKEN"] = "TokenExpired";
    /**
     * The user corresponding to the authentication token provided in the request does not have the sufficient
     * authorization to perform the request.
     */
    MonkNetworkError["INSUFFICIENT_AUTHORIZATION"] = "InsufficientAuthorization";
})(MonkNetworkError = exports.MonkNetworkError || (exports.MonkNetworkError = {}));
function getErrorMessage(name) {
    switch (name) {
        case MonkNetworkError.MISSING_TOKEN:
            return 'Missing authentication token in request headers.';
        case MonkNetworkError.INVALID_TOKEN:
            return 'Invalid authentication token in request.';
        case MonkNetworkError.EXPIRED_TOKEN:
            return 'Authentication token is expired.';
        case MonkNetworkError.INSUFFICIENT_AUTHORIZATION:
            return 'User does not have the proper authorization grants to perform this request.';
        default:
            return null;
    }
}
function getErrorName(status, message) {
    if (status === 401 && message.includes('Authorization header is required')) {
        return MonkNetworkError.MISSING_TOKEN;
    }
    if (status === 401 &&
        [
            'Token payload schema unknown',
            'Token decode failed',
            'Token audience invalid, please check audience',
            'Token issuer invalid, please check issuer',
            'Wrong authorization header format, should be in the format : Bearer TOKEN',
            'Invalid authentication token in request.',
        ].some(function (str) { return message.includes(str); })) {
        return MonkNetworkError.INVALID_TOKEN;
    }
    if (status === 401 && message.includes('Token is expired')) {
        return MonkNetworkError.EXPIRED_TOKEN;
    }
    // TODO : Also check conditions for MonkNetworkError.INSUFFICIENT_AUTHORIZATION.
    return null;
}
/* eslint-disable no-param-reassign */
var beforeError = function (error) { return __awaiter(void 0, void 0, void 0, function () {
    var response, clone, body;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                response = error.response;
                clone = response.clone();
                return [4 /*yield*/, clone.json()];
            case 1:
                body = (_c.sent());
                error.name = (_a = getErrorName(clone.status, body.message)) !== null && _a !== void 0 ? _a : error.name;
                error.message = (_b = getErrorMessage(error.name)) !== null && _b !== void 0 ? _b : error.message;
                Object.assign(error, { body: body });
                return [2 /*return*/, error];
        }
    });
}); };
exports.beforeError = beforeError;
