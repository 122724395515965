"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthGuard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_router_dom_1 = require("react-router-dom");
var network_1 = require("@monkvision/network");
var common_1 = require("@monkvision/common");
/**
 * This component can be used in your application Routers (react-router-dom v6) to protect a given route and redirect
 * the user to another page if they are not authorized to access this resource.
 *
 * **Note : For this component to work properly, it must be the child of a `MonkAppStateProvider` component.**
 */
function AuthGuard(_a) {
    var redirectTo = _a.redirectTo, children = _a.children;
    var _b = (0, common_1.useMonkAppState)(), authToken = _b.authToken, loading = _b.loading, config = _b.config;
    if (loading.isLoading) {
        return null;
    }
    if (!authToken ||
        ((config === null || config === void 0 ? void 0 : config.requiredApiPermissions) &&
            !(0, network_1.isUserAuthorized)(authToken, config === null || config === void 0 ? void 0 : config.requiredApiPermissions)) ||
        (0, network_1.isTokenExpired)(authToken)) {
        return (0, jsx_runtime_1.jsx)(react_router_dom_1.Navigate, { to: redirectTo, replace: true });
    }
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children });
}
exports.AuthGuard = AuthGuard;
