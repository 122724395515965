"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CameraResolution = exports.CompressionFormat = void 0;
/**
 * Enumeration of the different compression format available for the Monk Camera
 */
var CompressionFormat;
(function (CompressionFormat) {
    /**
     * Compression using the JPEG format.
     */
    CompressionFormat["JPEG"] = "image/jpeg";
})(CompressionFormat = exports.CompressionFormat || (exports.CompressionFormat = {}));
/**
 * Enumeration of the 16:9 resolutions that can be used to specify the constraints of a video stream fetched by the
 * Monk camera.
 *
 * ***Important Note : Lower quality resolutions should only be used for testing, as AI models can have a hard time
 * detecting damages on lower res pictures.***
 */
var CameraResolution;
(function (CameraResolution) {
    /**
     * QnHD quality (180p) : 320x180 pixels.
     *
     * *Note : This quality is to be used for testing purposes only.*
     */
    CameraResolution["QNHD_180P"] = "180p";
    /**
     * nHD quality (360p) : 640x360 pixels.
     *
     * *Note : This quality is to be used for testing purposes only.*
     */
    CameraResolution["NHD_360P"] = "360p";
    /**
     * HD quality (720p) : 1280x720 pixels.
     */
    CameraResolution["HD_720P"] = "720p";
    /**
     * FHD quality (1080p) : 1920x1080 pixels.
     */
    CameraResolution["FHD_1080P"] = "1080p";
    /**
     * QHD quality (2K) : 2560x1440 pixels.
     */
    CameraResolution["QHD_2K"] = "2K";
    /**
     * UHD quality (4K) : 3840x2160 pixels.
     */
    CameraResolution["UHD_4K"] = "4K";
})(CameraResolution = exports.CameraResolution || (exports.CameraResolution = {}));
