"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTextFieldStyles = void 0;
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var TextField_styles_1 = require("./TextField.styles");
function useTextFieldStyles(props) {
    var _a;
    var utils = (0, common_1.useMonkTheme)().utils;
    var colors = (0, react_1.useMemo)(function () {
        var focus = utils.getColor(props.focusColor);
        var neutral = utils.getColor(props.neutralColor);
        var backgroundColor = utils.getColor(props.backgroundColor);
        return {
            focus: focus,
            neutral: neutral,
            neutral30: (0, common_1.changeAlpha)(neutral, 0.3),
            backgroundColor: backgroundColor,
        };
    }, [props.focusColor, props.neutralColor, props.backgroundColor]);
    var borderColor = colors.neutral30;
    if (props.isFilled) {
        borderColor = colors.neutral;
    }
    if (props.isFocused || props.highlighted) {
        borderColor = colors.focus;
    }
    return {
        mainContainerStyle: __assign(__assign(__assign({}, TextField_styles_1.styles['mainContainer']), (props.disabled ? TextField_styles_1.styles['mainContainerDisabled'] : {})), { width: (_a = props.fixedWidth) !== null && _a !== void 0 ? _a : '100%' }),
        componentsContainerStyle: __assign(__assign({}, TextField_styles_1.styles['componentsContainer']), { paddingLeft: props.icon ? 12 : 16, paddingRight: props.showClearButton ? 12 : 16, borderTopWidth: props.highlighted ? 1 : 0, borderLeftWidth: props.highlighted ? 1 : 0, borderRightWidth: props.highlighted ? 1 : 0, borderBottomWidth: props.isFocused ? 2 : 1, borderColor: borderColor, marginBottom: props.isFocused ? 0 : 1, backgroundColor: colors.backgroundColor, cursor: props.disabled ? 'default' : 'text' }),
        leftIcon: {
            size: 24,
            primaryColor: colors.neutral,
            style: TextField_styles_1.styles['icon'],
        },
        inputContainerStyle: __assign(__assign({}, TextField_styles_1.styles['inputContainer']), { flexDirection: props.unitPosition === 'left' ? 'row' : 'row-reverse' }),
        unitStyle: __assign(__assign({}, TextField_styles_1.styles['unit']), { color: colors.neutral, paddingLeft: props.unitPosition === 'left' ? 0 : 6, paddingRight: props.unitPosition === 'left' ? 6 : 0 }),
        labelStyle: __assign(__assign(__assign({}, TextField_styles_1.styles['label']), (props.isFilled || props.isFocused ? TextField_styles_1.styles['labelFloating'] : {})), { color: props.isFocused || props.highlighted ? colors.focus : colors.neutral }),
        inputStyle: __assign(__assign({}, TextField_styles_1.styles['input']), { color: colors.neutral, fontFamily: props.monospace ? 'monospace' : 'sans-serif' }),
        clearButton: {
            primaryColor: colors.neutral,
            style: TextField_styles_1.styles['clearButton'],
            visibility: props.isFilled ? 'visible' : 'hidden',
        },
        assistiveTextStyle: __assign(__assign({}, TextField_styles_1.styles['assistiveText']), { color: props.isFocused || props.highlighted ? colors.focus : colors.neutral }),
    };
}
exports.useTextFieldStyles = useTextFieldStyles;
