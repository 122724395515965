"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppStateAnalytics = void 0;
var analytics_1 = require("@monkvision/analytics");
var react_1 = require("react");
function useAppStateAnalytics(_a) {
    var inspectionId = _a.inspectionId;
    var setUserId = (0, analytics_1.useAnalytics)().setUserId;
    (0, react_1.useEffect)(function () {
        if (inspectionId) {
            setUserId(inspectionId);
        }
    }, [inspectionId, setUserId]);
}
exports.useAppStateAnalytics = useAppStateAnalytics;
