"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useForm = void 0;
var react_1 = require("react");
var useObjectMemo_1 = require("./useObjectMemo");
function mapFormValues(values, val) {
    return Object.keys(values).reduce(function (prev, curr) {
        var _a;
        return (__assign(__assign({}, prev), (_a = {}, _a[curr] = val, _a)));
    }, {});
}
/**
 * Custom hook used to manage forms in ReactJs. Please refer to
 * [the official documentation](https://github.com/monkvision/monkjs/blob/main/packages/common/README/HOOKS.md) for more
 * details on how to use this hook.
 */
function useForm(_a) {
    var initialValues = _a.initialValues, validate = _a.validate;
    var _b = (0, react_1.useState)(initialValues), values = _b[0], setValues = _b[1];
    var _c = (0, react_1.useState)(mapFormValues(initialValues, false)), touchedInputs = _c[0], setTouchedInputs = _c[1];
    var touch = (0, react_1.useCallback)(function (name) {
        setTouchedInputs(function (previousValues) {
            var _a;
            return (__assign(__assign({}, previousValues), (_a = {}, _a[name] = true, _a)));
        });
    }, []);
    var getInputValue = (0, react_1.useCallback)(function (name) { return values[name]; }, [values]);
    var getInputProps = (0, react_1.useCallback)(function (name) { return ({
        value: values[name],
        onChange: function (value) {
            setValues(function (previousValues) {
                var _a;
                return (__assign(__assign({}, previousValues), (_a = {}, _a[name] = value, _a)));
            });
            touch(name);
        },
        onBlur: function () {
            touch(name);
        },
    }); }, [values, touch]);
    var getInputError = (0, react_1.useCallback)(function (name) { var _a, _b; return (_b = (_a = validate === null || validate === void 0 ? void 0 : validate[name]) === null || _a === void 0 ? void 0 : _a.call(validate, values[name])) !== null && _b !== void 0 ? _b : null; }, [validate, values]);
    var isValid = (0, react_1.useCallback)(function () { return Object.keys(initialValues).every(function (name) { return getInputError(name) === null; }); }, [initialValues, getInputError]);
    var isInputTouchedOrDirty = (0, react_1.useCallback)(function (name) { return touchedInputs[name] || values[name] !== initialValues[name]; }, [touchedInputs, values, initialValues]);
    var isInputErrorDisplayed = (0, react_1.useCallback)(function (name) { return isInputTouchedOrDirty(name) && !!getInputError(name); }, [isInputTouchedOrDirty, getInputError]);
    return (0, useObjectMemo_1.useObjectMemo)({
        getInputValue: getInputValue,
        getInputProps: getInputProps,
        isValid: isValid,
        getInputError: getInputError,
        isInputErrorDisplayed: isInputErrorDisplayed,
        isInputTouchedOrDirty: isInputTouchedOrDirty,
    });
}
exports.useForm = useForm;
