"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppStateMonitoring = void 0;
var react_1 = require("react");
var jwt_decode_1 = require("jwt-decode");
var monitoring_1 = require("@monkvision/monitoring");
function useAppStateMonitoring(_a) {
    var authToken = _a.authToken, inspectionId = _a.inspectionId, vehicleType = _a.vehicleType, steeringWheel = _a.steeringWheel;
    var _b = (0, monitoring_1.useMonitoring)(), setTags = _b.setTags, setUserId = _b.setUserId;
    (0, react_1.useEffect)(function () {
        setTags({
            inspectionId: inspectionId,
            vehicleType: vehicleType,
            steeringWheel: steeringWheel,
        });
    }, [inspectionId, vehicleType, steeringWheel, setTags]);
    (0, react_1.useEffect)(function () {
        var userId = authToken ? (0, jwt_decode_1.jwtDecode)(authToken).sub : undefined;
        if (userId) {
            setUserId(userId);
        }
    }, [authToken, setUserId]);
}
exports.useAppStateMonitoring = useAppStateMonitoring;
