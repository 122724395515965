"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMimetype = exports.getFileExtensions = exports.MIMETYPE_FILE_EXTENSIONS = void 0;
/**
 * Datamap that associates mimetypes to known file extensions corresponding to this mimetype.
 */
exports.MIMETYPE_FILE_EXTENSIONS = {
    'text/plain': ['txt'],
    'text/html': ['html', 'htm', 'shtml'],
    'text/css': ['css'],
    'text/xml': ['xml'],
    'image/gif': ['gif'],
    'image/jpeg': ['jpeg', 'jpg'],
    'image/png': ['png'],
    'image/tiff': ['tif', 'tiff'],
    'image/vnd.wap.wbmp': ['wbmp'],
    'image/x-icon': ['ico'],
    'image/x-jng': ['jng'],
    'image/x-ms-bmp': ['bmp'],
    'image/svg+xml': ['svg'],
    'image/webp': ['webp'],
    'application/octet-stream': ['bin', 'exe', 'dll', 'eot', 'iso', 'img', 'msi', 'msp', 'msm'],
    'application/x-javascript': ['js'],
    'application/pdf': ['pdf'],
    'application/xhtml+xml': ['xhtml'],
    'application/zip': ['zip'],
    'audio/midi': ['mid', 'midi', 'kar'],
    'audio/mpeg': ['mp3'],
    'audio/ogg': ['ogg'],
    'audio/x-realaudio': ['ra'],
    'video/3gpp': ['3gpp', '3gp'],
    'video/mpeg': ['mpeg', 'mpg'],
    'video/quicktime': ['mov'],
    'video/x-flv': ['flv'],
    'video/x-mng': ['mng'],
    'video/x-ms-asf': ['asx', 'asf'],
    'video/x-ms-wmv': ['wmv'],
    'video/x-msvideo': ['avi'],
    'video/mp4': ['m4v', 'mp4'],
};
/**
 * Returns a list of file extensions known to be corresponding to the given mimetype. If no file extension is known for
 * this mimetype, this function will throw an error.
 */
function getFileExtensions(mimetype) {
    var extensions = exports.MIMETYPE_FILE_EXTENSIONS[mimetype];
    if (!extensions) {
        throw new Error("Unknown mimetype : ".concat(mimetype));
    }
    return extensions;
}
exports.getFileExtensions = getFileExtensions;
/**
 * Returns the mimetype associated with the given file extension. If the file extension is unknown, this function will
 * throw an error.
 */
function getMimetype(fileExtension) {
    var _a;
    var mimetype = (_a = Object.entries(exports.MIMETYPE_FILE_EXTENSIONS).find(function (_a) {
        var extensions = _a[1];
        return extensions.includes(fileExtension);
    })) === null || _a === void 0 ? void 0 : _a[0];
    if (!mimetype) {
        throw new Error("Unknown file extension : ".concat(fileExtension));
    }
    return mimetype;
}
exports.getMimetype = getMimetype;
