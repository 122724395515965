"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRetry = exports.usePhotoCaptureErrorLabel = void 0;
var camera_web_1 = require("@monkvision/camera-web");
var react_i18next_1 = require("react-i18next");
var common_1 = require("@monkvision/common");
var network_1 = require("@monkvision/network");
var errors_1 = require("../../errors");
function usePhotoCaptureErrorLabel(captureError, handle, inspectionId) {
    var _a;
    var t = (0, react_i18next_1.useTranslation)().t;
    var tObj = (0, common_1.useObjectTranslation)().tObj;
    var cameraErrorLabel = (0, camera_web_1.getCameraErrorLabel)((_a = handle.error) === null || _a === void 0 ? void 0 : _a.type);
    if (handle.error && cameraErrorLabel) {
        return tObj(cameraErrorLabel);
    }
    if (captureError instanceof Error) {
        if (captureError.name === errors_1.PhotoCaptureErrorName.MISSING_TASK_IN_INSPECTION) {
            return t('photo.hud.error.missingTasks');
        }
        if ([network_1.MonkNetworkError.MISSING_TOKEN, network_1.MonkNetworkError.INVALID_TOKEN].includes(captureError.name)) {
            return t('photo.hud.error.invalidToken');
        }
        if (captureError.name === network_1.MonkNetworkError.EXPIRED_TOKEN) {
            return t('photo.hud.error.expiredToken');
        }
        if (captureError.name === network_1.MonkNetworkError.INSUFFICIENT_AUTHORIZATION) {
            return t('photo.hud.error.insufficientAuth');
        }
    }
    if (captureError) {
        return "".concat(t('photo.hud.error.inspectionLoading'), " ").concat(inspectionId);
    }
    return null;
}
exports.usePhotoCaptureErrorLabel = usePhotoCaptureErrorLabel;
function useRetry(_a) {
    var captureError = _a.captureError, handle = _a.handle, onRetry = _a.onRetry;
    if (handle.error) {
        return handle.retry;
    }
    if (captureError instanceof Error &&
        [
            errors_1.PhotoCaptureErrorName.MISSING_TASK_IN_INSPECTION,
            network_1.MonkNetworkError.MISSING_TOKEN,
            network_1.MonkNetworkError.INVALID_TOKEN,
            network_1.MonkNetworkError.EXPIRED_TOKEN,
            network_1.MonkNetworkError.INSUFFICIENT_AUTHORIZATION,
        ].includes(captureError.name)) {
        return null;
    }
    if (captureError) {
        return onRetry;
    }
    return null;
}
exports.useRetry = useRetry;
