"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInspectionGalleryFilterPillStyles = void 0;
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var InspectionGalleryFilterPill_styles_1 = require("./InspectionGalleryFilterPill.styles");
function useInspectionGalleryFilterPillStyles(_a) {
    var isSelected = _a.isSelected;
    var palette = (0, common_1.useMonkTheme)().palette;
    var colors = (0, react_1.useMemo)(function () { return ({
        unselectedPillBackground: (0, common_1.changeAlpha)(palette.surface.dark, 0.32),
        unselectedCountBackground: (0, common_1.changeAlpha)(palette.surface.light, 0.08),
        selectedCountBackground: (0, common_1.changeAlpha)(palette.surface.dark, 0.08),
    }); }, [palette]);
    return {
        pill: {
            style: InspectionGalleryFilterPill_styles_1.styles['pill'],
            primaryColor: isSelected ? palette.surface.light : colors.unselectedPillBackground,
            secondaryColor: isSelected ? palette.surface.dark : palette.text.primary,
            shade: (isSelected ? 'light' : 'dark'),
        },
        countStyle: __assign(__assign({}, InspectionGalleryFilterPill_styles_1.styles['count']), { backgroundColor: isSelected
                ? colors.selectedCountBackground
                : colors.unselectedCountBackground }),
    };
}
exports.useInspectionGalleryFilterPillStyles = useInspectionGalleryFilterPillStyles;
