"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var icons_1 = require("../../icons");
var Spinner_1 = require("../Spinner");
var Button_styles_1 = require("./Button.styles");
var hooks_1 = require("./hooks");
/**
 * Basic button component, available with 4 variants. Accepts optional MonkButtonProps (see the `MonkButtonProps`
 * interface for more details), as well as HTMLButtonElement props that are passed through the underlying button
 * element. The ref is also forwarded to the button.
 *
 * @see MonkButtonProps
 */
exports.Button = (0, react_1.forwardRef)(function Button(_a, ref) {
    var primaryColor = _a.primaryColor, secondaryColor = _a.secondaryColor, variant = _a.variant, size = _a.size, icon = _a.icon, loading = _a.loading, shade = _a.shade, _b = _a.preserveWidthOnLoading, preserveWidthOnLoading = _b === void 0 ? false : _b, _c = _a.style, style = _c === void 0 ? {} : _c, disabled = _a.disabled, onMouseUp = _a.onMouseUp, onMouseDown = _a.onMouseDown, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, children = _a.children, passThroughProps = __rest(_a, ["primaryColor", "secondaryColor", "variant", "size", "icon", "loading", "shade", "preserveWidthOnLoading", "style", "disabled", "onMouseUp", "onMouseDown", "onMouseEnter", "onMouseLeave", "children"]);
    var isLoading = typeof loading === 'object' ? loading.isLoading : loading !== null && loading !== void 0 ? loading : false;
    var isDisabled = !!disabled || isLoading;
    var handleMouseDown = function (event) {
        event.preventDefault();
        if (onMouseDown) {
            onMouseDown(event);
        }
    };
    var _d = (0, common_1.useInteractiveStatus)({
        disabled: isDisabled,
        componentHandlers: { onMouseUp: onMouseUp, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onMouseDown: handleMouseDown },
    }), status = _d.status, eventHandlers = _d.eventHandlers;
    var _e = (0, hooks_1.useButtonStyle)({
        primaryColor: primaryColor !== null && primaryColor !== void 0 ? primaryColor : (variant === 'outline' ? 'primary-xlight' : 'primary'),
        secondaryColor: secondaryColor !== null && secondaryColor !== void 0 ? secondaryColor : (variant === 'outline' ? 'surface-dark' : 'text-primary'),
        variant: variant !== null && variant !== void 0 ? variant : 'fill',
        size: size !== null && size !== void 0 ? size : 'normal',
        shade: shade !== null && shade !== void 0 ? shade : 'dark',
        loading: isLoading,
        preserveWidthOnLoading: preserveWidthOnLoading,
        status: status,
        hasChildren: !!children,
        icon: icon,
    }), buttonStyle = _e.style, iconStyle = _e.iconStyle, spinnerStyle = _e.spinnerStyle;
    var content = (0, react_1.useMemo)(function () { return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [icon && ((0, jsx_runtime_1.jsx)("div", __assign({ style: __assign({ width: iconStyle.size, height: iconStyle.size }, iconStyle.style) }, { children: (0, jsx_runtime_1.jsx)(icons_1.Icon, { icon: icon, primaryColor: iconStyle.color, size: iconStyle.size, style: iconStyle.style }) }))), children] })); }, [icon, iconStyle, children]);
    var loadingContent = (0, react_1.useMemo)(function () {
        return preserveWidthOnLoading ? ((0, jsx_runtime_1.jsxs)("div", __assign({ style: Button_styles_1.styles['fixedLoadingContainer'] }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: Button_styles_1.styles['loadingHiddenContent'] }, { children: content })), (0, jsx_runtime_1.jsx)(Spinner_1.Spinner, { size: spinnerStyle.size, primaryColor: spinnerStyle.color, style: spinnerStyle.style })] }))) : ((0, jsx_runtime_1.jsx)(Spinner_1.Spinner, { size: spinnerStyle.size, primaryColor: spinnerStyle.color, style: spinnerStyle.style }));
    }, [preserveWidthOnLoading, content, spinnerStyle]);
    return ((0, jsx_runtime_1.jsx)("button", __assign({ ref: ref, style: __assign(__assign({}, buttonStyle), style), disabled: isDisabled }, eventHandlers, passThroughProps, { "data-testid": 'monk-btn' }, { children: isLoading ? loadingContent : content })));
});
