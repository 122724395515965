"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticsContext = void 0;
var react_1 = require("react");
var adapters_1 = require("../adapters");
/**
 * A React Context that wraps up the analytics methods provided by an Analytics Adapter. This context is initialized by
 * default with the EmptyAnalyticsAdapter.
 */
exports.AnalyticsContext = (0, react_1.createContext)(new adapters_1.EmptyAnalyticsAdapter());
