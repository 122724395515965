"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
exports.styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '20px',
        alignItems: 'center',
        textAlign: 'center',
        maxWidth: '70%',
        margin: 'auto',
    },
    errorMessage: {
        textAlign: 'center',
        paddingBottom: 20,
    },
};
