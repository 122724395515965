"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InspectionGalleryFilterPill = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var hooks_1 = require("./hooks");
var Button_1 = require("../../../Button");
function InspectionGalleryFilterPill(_a) {
    var isSelected = _a.isSelected, label = _a.label, count = _a.count, onClick = _a.onClick;
    var _b = (0, hooks_1.useInspectionGalleryFilterPillStyles)({ isSelected: isSelected }), pill = _b.pill, countStyle = _b.countStyle;
    return ((0, jsx_runtime_1.jsxs)(Button_1.Button, __assign({ onClick: onClick, primaryColor: pill.primaryColor, secondaryColor: pill.secondaryColor, shade: pill.shade, style: pill.style }, { children: [(0, jsx_runtime_1.jsx)("span", { children: label }), (0, jsx_runtime_1.jsx)("span", __assign({ style: countStyle }, { children: count }))] })));
}
exports.InspectionGalleryFilterPill = InspectionGalleryFilterPill;
