"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMonkState = void 0;
var react_1 = require("react");
var context_1 = require("./context");
/**
 * Custom hook that allows you to access the Monk State Context methods inside a component.
 *
 * Note : If this hook is called inside a component that is not a child of a MonkProvider component, it will throw an
 * error.
 */
function useMonkState() {
    var stateWithDispatch = (0, react_1.useContext)(context_1.MonkContext);
    if (!stateWithDispatch) {
        throw new Error('MonkContext not initialized! Did you make a call to `useMonkState()` without initializing the MonkContext in a parent component?');
    }
    return stateWithDispatch;
}
exports.useMonkState = useMonkState;
