"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLoadingState = void 0;
var react_1 = require("react");
var useObjectMemo_1 = require("./useObjectMemo");
/**
 * Custom hook used to create a `LoadingState` object. This object can be used to track the processing of a task in
 * the component. For instance, you can use this hook to handle the loading and errors of API calls in your components.
 *
 * @param [startsLoading] Boolean indicating if the loading state starts in loading mode or not.
 */
function useLoadingState(startsLoading) {
    var _a = (0, react_1.useState)(!!startsLoading), isLoading = _a[0], setIsLoading = _a[1];
    var _b = (0, react_1.useState)(null), error = _b[0], setError = _b[1];
    var start = (0, react_1.useCallback)(function () {
        setError(null);
        setIsLoading(true);
    }, []);
    var onSuccess = (0, react_1.useCallback)(function () {
        setError(null);
        setIsLoading(false);
    }, []);
    var onError = (0, react_1.useCallback)(function (err) {
        setError(err);
        setIsLoading(false);
    }, []);
    return (0, useObjectMemo_1.useObjectMemo)({
        isLoading: isLoading,
        error: error,
        start: start,
        onSuccess: onSuccess,
        onError: onError,
    });
}
exports.useLoadingState = useLoadingState;
