"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavigateToCaptureReason = void 0;
/**
 * Enumeration of the reasons of a navigation event from the gallery back to the Capture flow.
 */
var NavigateToCaptureReason;
(function (NavigateToCaptureReason) {
    /**
     * No particuliar reason : the user simply wants to return to the capture flow.
     */
    NavigateToCaptureReason["NONE"] = "none";
    /**
     * The user wants to return to the capture flow in order to capture a specific sight.
     */
    NavigateToCaptureReason["CAPTURE_SIGHT"] = "capture_sight";
    /**
     * The user wants to return to the capture flow in order to retake a non-compliant picture
     */
    NavigateToCaptureReason["RETAKE_PICTURE"] = "retake_picture";
    /**
     * The user wants to return to the capture flow in order to add a custom damage.
     */
    NavigateToCaptureReason["ADD_DAMAGE"] = "add_damage";
})(NavigateToCaptureReason = exports.NavigateToCaptureReason || (exports.NavigateToCaptureReason = {}));
