"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
exports.styles = {
    pill: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px 10px',
        fontSize: 12,
        marginRight: 8,
    },
    count: {
        borderRadius: 200,
        padding: '1px 4px',
        marginLeft: 4,
    },
};
