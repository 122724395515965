"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTakePictureButtonStyle = void 0;
var types_1 = require("@monkvision/types");
var react_1 = require("react");
var TakePictureButton_styles_1 = require("./TakePictureButton.styles");
var INNER_BUTTON_SIZE_RATIO = 0.84;
var PRESS_ANIMATION_DURATION_MS = 150;
function useTakePictureButtonStyle(params) {
    var _a = (0, react_1.useState)(false), isPressed = _a[0], setIsPressed = _a[1];
    var borderWidth = (params.size * (1 - INNER_BUTTON_SIZE_RATIO)) / 4;
    var animateClick = function () {
        setIsPressed(true);
        setTimeout(function () { return setIsPressed(false); }, PRESS_ANIMATION_DURATION_MS);
    };
    var buttonStyles = {
        outerLayer: __assign(__assign(__assign({}, TakePictureButton_styles_1.styles['outerLayer']), (params.status === types_1.InteractiveStatus.DISABLED ? TakePictureButton_styles_1.styles['outerLayerDisabled'] : {})), { width: params.size - 2 * borderWidth, height: params.size - 2 * borderWidth, borderWidth: borderWidth, borderColor: TakePictureButton_styles_1.takePictureButtonColors[types_1.InteractiveStatus.DEFAULT] }),
        innerLayer: __assign(__assign(__assign({}, TakePictureButton_styles_1.styles['innerLayer']), (params.status === types_1.InteractiveStatus.DISABLED ? TakePictureButton_styles_1.styles['innerLayerDisabled'] : {})), { width: params.size * INNER_BUTTON_SIZE_RATIO, height: params.size * INNER_BUTTON_SIZE_RATIO, margin: borderWidth, backgroundColor: TakePictureButton_styles_1.takePictureButtonColors[params.status], border: 'none', transform: isPressed ? 'scale(0.7)' : 'scale(1)', transition: "transform ".concat(PRESS_ANIMATION_DURATION_MS / 2, "ms ease-in") }),
    };
    return {
        buttonStyles: buttonStyles,
        animateClick: animateClick,
    };
}
exports.useTakePictureButtonStyle = useTakePictureButtonStyle;
