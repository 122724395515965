"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugMonitoringAdapter = void 0;
var adapter_1 = require("./adapter");
var emptyAdapter_1 = require("./emptyAdapter");
var CONSOLE_LOG_FUNCTIONS = (_a = {},
    _a[adapter_1.LogSeverity.DEBUG] = console.debug,
    _a[adapter_1.LogSeverity.INFO] = console.info,
    _a[adapter_1.LogSeverity.WARNING] = console.warn,
    _a[adapter_1.LogSeverity.ERROR] = console.error,
    _a[adapter_1.LogSeverity.FATAL] = console.error,
    _a);
var defaultOptions = {
    showUnsupportedMethodWarnings: true,
};
/**
 * This is a basic Monitoring Adapter that simply logs element in the console. This can be used in your app if you do
 * not have any need for advanced Monitoring Features such as performance measurements. The only methods implemented
 * are `log` and `handleError`, that simply log elements in the console.
 *
 * All the severity levels are implemented and redirected to the corresponding console level except for the fatal level
 * which is redirected to the `console.error` function. The `extras` data passed in the context will also be logged
 * along the message or error, but the tags will be ignored.
 */
var DebugMonitoringAdapter = /** @class */ (function (_super) {
    __extends(DebugMonitoringAdapter, _super);
    function DebugMonitoringAdapter(optionsParam) {
        var _this = _super.call(this, optionsParam) || this;
        _this.options = __assign(__assign({}, defaultOptions), (optionsParam !== null && optionsParam !== void 0 ? optionsParam : {}));
        return _this;
    }
    DebugMonitoringAdapter.prototype.log = function (msg, context) {
        var loggingFunction = DebugMonitoringAdapter.getLoggingFunction(context);
        loggingFunction(msg, context);
    };
    DebugMonitoringAdapter.prototype.handleError = function (err, context) {
        var loggingFunction = DebugMonitoringAdapter.getLoggingFunction(adapter_1.LogSeverity.ERROR);
        loggingFunction(err, context);
    };
    DebugMonitoringAdapter.createLoggingFunction = function (consoleFunction) {
        return function (msg, context) {
            if (typeof context === 'object' && context.extras) {
                return consoleFunction(msg, context.extras);
            }
            return consoleFunction(msg);
        };
    };
    DebugMonitoringAdapter.getLoggingFunction = function (context) {
        var severity = adapter_1.LogSeverity.INFO;
        if (typeof context === 'string') {
            severity = context;
        }
        else if (typeof context === 'object' && context.level) {
            severity = context.level;
        }
        return DebugMonitoringAdapter.createLoggingFunction(CONSOLE_LOG_FUNCTIONS[severity]);
    };
    return DebugMonitoringAdapter;
}(emptyAdapter_1.EmptyMonitoringAdapter));
exports.DebugMonitoringAdapter = DebugMonitoringAdapter;
