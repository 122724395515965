"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitoringContext = void 0;
var react_1 = require("react");
var adapters_1 = require("../adapters");
/**
 * A React Context that wraps up the monitoring methods provided by a Monitoring Adapter. This context is initialized by
 * default with the EmptyMonitoringAdapter.
 */
exports.MonitoringContext = (0, react_1.createContext)(new adapters_1.EmptyMonitoringAdapter());
