"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deletedOnePricing = exports.isDeletedOnePricingAction = void 0;
var monkAction_1 = require("./monkAction");
/**
 * Matcher function that matches a DeletedOnePricing while also inferring its type using TypeScript's type predicate
 * feature.
 */
function isDeletedOnePricingAction(action) {
    return action.type === monkAction_1.MonkActionType.DELETED_ONE_PRICING;
}
exports.isDeletedOnePricingAction = isDeletedOnePricingAction;
/**
 * Reducer function for a deletedOnePricing action.
 */
function deletedOnePricing(state, action) {
    var _a;
    var pricings = state.pricings, inspections = state.inspections;
    var payload = action.payload;
    var inspection = inspections.find(function (value) { return value.id === payload.inspectionId; });
    if (inspection) {
        inspection.pricings = (_a = inspection.pricings) === null || _a === void 0 ? void 0 : _a.filter(function (pricingId) { return pricingId !== payload.pricingId; });
    }
    var newPricings = pricings.filter(function (pricing) { return pricing.id !== payload.pricingId; });
    return __assign(__assign({}, state), { pricings: newPricings, inspections: __spreadArray([], inspections, true) });
}
exports.deletedOnePricing = deletedOnePricing;
