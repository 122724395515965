"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createdOnePricing = exports.isCreatedOnePricingAction = void 0;
var monkAction_1 = require("./monkAction");
/**
 * Matcher function that matches a CreatedOnePricing while also inferring its type using TypeScript's type predicate
 * feature.
 */
function isCreatedOnePricingAction(action) {
    return action.type === monkAction_1.MonkActionType.CREATED_ONE_PRICING;
}
exports.isCreatedOnePricingAction = isCreatedOnePricingAction;
/**
 * Reducer function for a createdOnePricing action.
 */
function createdOnePricing(state, action) {
    var _a, _b;
    var pricings = state.pricings, inspections = state.inspections;
    var payload = action.payload;
    var inspection = inspections.find(function (value) { return value.id === payload.pricing.inspectionId; });
    if (inspection) {
        inspection.pricings = (_a = inspection.pricings) === null || _a === void 0 ? void 0 : _a.filter(function (pricingId) { return ![payload.pricing.id, payload.localId].includes(pricingId); });
        (_b = inspection.pricings) === null || _b === void 0 ? void 0 : _b.push(payload.pricing.id);
    }
    var newPricings = pricings.filter(function (pricing) { return ![payload.pricing.id, payload.localId].includes(pricing.id); });
    newPricings.push(action.payload.pricing);
    return __assign(__assign({}, state), { pricings: newPricings, inspections: __spreadArray([], inspections, true) });
}
exports.createdOnePricing = createdOnePricing;
