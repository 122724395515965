"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhotoCaptureHUDCounter = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var PhotoCaptureHUDCounter_styles_1 = require("./PhotoCaptureHUDCounter.styles");
var hooks_1 = require("../hooks");
var hooks_2 = require("./hooks");
/**
 * Component that implements an indicator of pictures taken during the PhotoCapture process.
 */
function PhotoCaptureHUDCounter(props) {
    var label = (0, hooks_2.usePhotoCaptureHUDCounterLabel)(props);
    var backgroundColor = (0, hooks_1.usePhotoCaptureHUDButtonBackground)();
    return ((0, jsx_runtime_1.jsx)("div", __assign({ style: __assign(__assign({}, PhotoCaptureHUDCounter_styles_1.styles['counter']), { backgroundColor: backgroundColor }), "data-testid": 'damage-counter' }, { children: label })));
}
exports.PhotoCaptureHUDCounter = PhotoCaptureHUDCounter;
