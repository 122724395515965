"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhotoCaptureTutorialOption = void 0;
/**
 * Enumeration of the tutorial options.
 */
var PhotoCaptureTutorialOption;
(function (PhotoCaptureTutorialOption) {
    /**
     * Photo capture is disabled.
     */
    PhotoCaptureTutorialOption["DISABLED"] = "disabled";
    /**
     * Photo capture is enable.
     */
    PhotoCaptureTutorialOption["ENABLED"] = "enabled";
    /**
     * Photo capture is enable only time.
     */
    PhotoCaptureTutorialOption["FIRST_TIME_ONLY"] = "first_time_only";
})(PhotoCaptureTutorialOption = exports.PhotoCaptureTutorialOption || (exports.PhotoCaptureTutorialOption = {}));
