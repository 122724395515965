"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepairOperationType = exports.PricingV2RelatedItemType = void 0;
/**
 * Enumeration of the types of items that a PricingV2 object can refer to.
 */
var PricingV2RelatedItemType;
(function (PricingV2RelatedItemType) {
    /**
     * The PricingV2 object is referring to a vehicle part.
     */
    PricingV2RelatedItemType["PART"] = "part";
    /**
     * The PricingV2 object is referring to the entirety of the vehicle.
     */
    PricingV2RelatedItemType["VEHICLE"] = "vehicle";
})(PricingV2RelatedItemType = exports.PricingV2RelatedItemType || (exports.PricingV2RelatedItemType = {}));
/**
 * Enumeration of the different operations that can be performed in order to repair a vehicle (used in PricingV2).
 */
var RepairOperationType;
(function (RepairOperationType) {
    RepairOperationType["POLISHING"] = "polishing";
    RepairOperationType["SANDING"] = "sanding";
    RepairOperationType["PAINTING"] = "painting";
    RepairOperationType["REPLACING"] = "replacing";
    RepairOperationType["PAINTLESS_DENT_REPAIR"] = "paintless_dent_repair";
    RepairOperationType["DENT_REPAIR"] = "dent_repair";
    RepairOperationType["REFINISHING"] = "refinishing";
    RepairOperationType["REMOVING"] = "removing";
    RepairOperationType["PAINTING_HARD"] = "painting_hard";
    RepairOperationType["PAINT_PREPARATION"] = "paint_preparation";
})(RepairOperationType = exports.RepairOperationType || (exports.RepairOperationType = {}));
