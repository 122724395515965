"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTheme = void 0;
var default_1 = require("./default");
function createGetColor(palette) {
    return function (prop) {
        var _a;
        var split = prop.split('-');
        var colorName = split[0];
        var variantName = ((_a = split[1]) !== null && _a !== void 0 ? _a : 'base');
        return palette[colorName] && palette[colorName][variantName]
            ? palette[colorName][variantName]
            : prop;
    };
}
function createThemeUtils(palette) {
    return {
        getColor: createGetColor(palette),
    };
}
function createRootStyles(palette) {
    return {
        backgroundColor: palette.background.base,
        color: palette.text.white,
    };
}
/**
 * Helper function used to create a MonkTheme by filling the missing values (such as colors etc.) with the ones used in
 * the default Monk theme.
 *
 * @param [theme] Optional custom values for the theme.
 */
function createTheme(_a) {
    var _b = _a === void 0 ? {} : _a, palette = _b.palette;
    var themePalette = __assign(__assign({}, default_1.MonkDefaultPalette), (palette !== null && palette !== void 0 ? palette : {}));
    return {
        palette: themePalette,
        utils: createThemeUtils(themePalette),
        rootStyles: createRootStyles(themePalette),
    };
}
exports.createTheme = createTheme;
