"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyAnalyticsAdapter = void 0;
var defaultOptions = {
    showUnsupportedMethodWarnings: true,
};
/**
 * This class is an empty Analytics Adapter, that implements all the methods but does nothing. If you use this adapter
 * in your app, whenever one of its analytics method is invoked, nothing will happen and a warning will be displayed
 * in the console indicating that the specified method is not supported by the current Analytics Adapter (this
 * behaviour can be configured with the options passed in the constructor). Note however that no error will be either
 * displayed or thrown by this adapter : your app will continue to work normally.
 *
 * When creating your own Analytics Adapter, you can extend this class if you do not plan on implementing all the
 * features of the Monk Analytics.
 *
 * @example
 * export class MyCustomAnalyticsAdapter extends EmptyAnalyticsAdapter {
 *   override setUserId(id: string, context?: Record<string, Primitive>): void {
 *      // custom settings
 *   }
 *
 *   override trackEvent(name: string, context?: Record<string, Primitive> ): void {
 *      // custom settings
 *   }
 * }
 */
var EmptyAnalyticsAdapter = /** @class */ (function () {
    function EmptyAnalyticsAdapter(optionsParam) {
        this.options = __assign(__assign({}, defaultOptions), (optionsParam !== null && optionsParam !== void 0 ? optionsParam : {}));
    }
    EmptyAnalyticsAdapter.prototype.setUserId = function (id, context) {
        if (this.options.showUnsupportedMethodWarnings) {
            console.warn('Application users are not supported by the current Monk Analytics Adapter and calling setUserId will have no effect.');
        }
    };
    EmptyAnalyticsAdapter.prototype.setUserProperties = function (context) {
        if (this.options.showUnsupportedMethodWarnings) {
            console.warn('Application users are not supported by the current Monk Analytics Adapter and calling setUserProperties will have no effect.');
        }
    };
    EmptyAnalyticsAdapter.prototype.resetUser = function () {
        if (this.options.showUnsupportedMethodWarnings) {
            console.warn('Application users are not supported by the current Monk Analytics Adapter and calling resetUser will have no effect.');
        }
    };
    EmptyAnalyticsAdapter.prototype.trackEvent = function (name, context) {
        if (this.options.showUnsupportedMethodWarnings) {
            console.warn('Events are not supported by the current Monk Analytics Adapter and calling trackEvent will have no effect.');
        }
    };
    EmptyAnalyticsAdapter.prototype.setEventsProperties = function (context) {
        if (this.options.showUnsupportedMethodWarnings) {
            console.warn('Events are not supported by the current Monk Analytics Adapter and calling setEventsProperties will have no effect.');
        }
    };
    return EmptyAnalyticsAdapter;
}());
exports.EmptyAnalyticsAdapter = EmptyAnalyticsAdapter;
