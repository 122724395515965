"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateInspection = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var react_1 = require("react");
var network_1 = require("@monkvision/network");
var common_1 = require("@monkvision/common");
var monitoring_1 = require("@monkvision/monitoring");
var analytics_1 = require("@monkvision/analytics");
var CreateInspection_styles_1 = require("./CreateInspection.styles");
var i18n_1 = require("./i18n");
var Spinner_1 = require("../Spinner");
var Button_1 = require("../Button");
var CreateInspectionError;
(function (CreateInspectionError) {
    CreateInspectionError["CREATE_INSPECTION"] = "errors.create-inspection";
    CreateInspectionError["MISSING_INSPECTION_ID"] = "errors.missing-inspection-id";
})(CreateInspectionError || (CreateInspectionError = {}));
/**
 * This component is a ready-to-use CreateInspection page that is used throughout the different Monk webapps to handle
 * inspection creation.
 *
 * **Note : For this component to work properly, it must be the child of a `MonkAppStateProvider` component.**
 */
exports.CreateInspection = (0, common_1.i18nWrap)(function CreateInspection(_a) {
    var lang = _a.lang, onInspectionCreated = _a.onInspectionCreated;
    (0, common_1.useI18nSync)(lang);
    var loading = (0, common_1.useLoadingState)();
    var t = (0, react_i18next_1.useTranslation)().t;
    var _b = (0, monitoring_1.useMonitoring)(), handleError = _b.handleError, setTags = _b.setTags;
    var _c = (0, common_1.useMonkAppState)(), config = _c.config, authToken = _c.authToken, inspectionId = _c.inspectionId, setInspectionId = _c.setInspectionId;
    var createInspection = (0, network_1.useMonkApi)({
        authToken: authToken !== null && authToken !== void 0 ? authToken : '',
        apiDomain: config.apiDomain,
        thumbnailDomain: config.thumbnailDomain,
    }).createInspection;
    var analytics = (0, analytics_1.useAnalytics)();
    var handleCreateInspection = function () {
        loading.start();
        if (config === null || config === void 0 ? void 0 : config.allowCreateInspection) {
            createInspection(config.createInspectionOptions)
                .then(function (res) {
                loading.onSuccess();
                setInspectionId(res.id);
            })
                .catch(function (err) {
                loading.onError(CreateInspectionError.CREATE_INSPECTION);
                handleError(err);
            });
        }
    };
    (0, react_1.useEffect)(function () {
        if (!inspectionId) {
            if (config === null || config === void 0 ? void 0 : config.allowCreateInspection) {
                loading.start();
                handleCreateInspection();
            }
            else {
                loading.onError(CreateInspectionError.MISSING_INSPECTION_ID);
            }
        }
        else {
            setTags({ inspectionId: inspectionId });
            analytics.setUserId(inspectionId);
            onInspectionCreated === null || onInspectionCreated === void 0 ? void 0 : onInspectionCreated();
        }
    }, [inspectionId, setTags, analytics]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: CreateInspection_styles_1.styles['container'] }, { children: [loading.isLoading && (0, jsx_runtime_1.jsx)(Spinner_1.Spinner, { size: 80 }), !loading.isLoading && loading.error && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: CreateInspection_styles_1.styles['errorMessage'] }, { children: t(loading.error) })), loading.error === CreateInspectionError.CREATE_INSPECTION && ((0, jsx_runtime_1.jsx)("div", __assign({ style: CreateInspection_styles_1.styles['retryBtnContainer'] }, { children: (0, jsx_runtime_1.jsx)(Button_1.Button, __assign({ variant: 'outline', icon: 'refresh', onClick: handleCreateInspection }, { children: t('errors.retry') })) })))] }))] })));
}, i18n_1.i18nCreateInspection);
