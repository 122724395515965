"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetState = exports.isResetStateAction = void 0;
var monkAction_1 = require("./monkAction");
var state_1 = require("../state");
/**
 * Matcher function that matches a MonkGotOneAction while also inferring its type using TypeScript's type predicate
 * feature.
 */
function isResetStateAction(action) {
    return action.type === monkAction_1.MonkActionType.RESET_STATE;
}
exports.isResetStateAction = isResetStateAction;
/**
 * Reducer function for a ResetState action.
 */
function resetState() {
    return (0, state_1.createEmptyMonkState)();
}
exports.resetState = resetState;
