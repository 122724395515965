"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddDamageMode = exports.PhotoCaptureMode = void 0;
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var analytics_1 = require("@monkvision/analytics");
/**
 * Enum of the different picture taking modes that the PhotoCapture component can be in.
 */
var PhotoCaptureMode;
(function (PhotoCaptureMode) {
    /**
     * SIGHT mode : user is asked to take a picture of its vehicle following a given Sight.
     */
    PhotoCaptureMode["SIGHT"] = "sight";
    /**
     * ADD_DAMAGE_1ST_SHOT mode : user is asked to take a picture centered on a damage, far away from the vehicle.
     */
    PhotoCaptureMode["ADD_DAMAGE_1ST_SHOT"] = "add_damage_1st_shot";
    /**
     * ADD_DAMAGE_2ND_SHOT mode : user is asked to take a zoomed picture of a damage on the car.
     */
    PhotoCaptureMode["ADD_DAMAGE_2ND_SHOT"] = "add_damage_2nd_shot";
})(PhotoCaptureMode = exports.PhotoCaptureMode || (exports.PhotoCaptureMode = {}));
/**
 * Custom hook used to switch between sight picture taking and add damage picture taking.
 */
function useAddDamageMode() {
    var _a = (0, react_1.useState)(PhotoCaptureMode.SIGHT), mode = _a[0], setMode = _a[1];
    var trackEvent = (0, analytics_1.useAnalytics)().trackEvent;
    var handleAddDamage = (0, react_1.useCallback)(function () {
        setMode(PhotoCaptureMode.ADD_DAMAGE_1ST_SHOT);
        trackEvent('AddDamage Selected', {
            mode: PhotoCaptureMode.ADD_DAMAGE_1ST_SHOT,
        });
    }, []);
    var updatePhotoCaptureModeAfterPictureTaken = (0, react_1.useCallback)(function () {
        setMode(function (currentMode) {
            return currentMode === PhotoCaptureMode.ADD_DAMAGE_1ST_SHOT
                ? PhotoCaptureMode.ADD_DAMAGE_2ND_SHOT
                : PhotoCaptureMode.SIGHT;
        });
    }, []);
    var handleCancelAddDamage = (0, react_1.useCallback)(function () {
        trackEvent('AddDamage Canceled', {
            mode: mode,
        });
        setMode(PhotoCaptureMode.SIGHT);
    }, []);
    return (0, common_1.useObjectMemo)({
        mode: mode,
        handleAddDamage: handleAddDamage,
        updatePhotoCaptureModeAfterPictureTaken: updatePhotoCaptureModeAfterPictureTaken,
        handleCancelAddDamage: handleCancelAddDamage,
    });
}
exports.useAddDamageMode = useAddDamageMode;
