"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWindowDimensions = void 0;
var react_1 = require("react");
/**
 * Custom hook used to listen to the browser window resize event and keep track of the current dimensions of the
 * inner window.
 */
function useWindowDimensions() {
    var getWindowDimensions = function () {
        return ({
            width: window.innerWidth,
            height: window.innerHeight,
            isPortrait: window.innerHeight - window.innerWidth > 0,
        });
    };
    var _a = (0, react_1.useState)(getWindowDimensions()), dimensions = _a[0], setDimensions = _a[1];
    (0, react_1.useLayoutEffect)(function () {
        var handleResize = function () { return setDimensions(getWindowDimensions); };
        handleResize();
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return dimensions;
}
exports.useWindowDimensions = useWindowDimensions;
