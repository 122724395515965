"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionStatus = exports.LogSeverity = void 0;
/**
 * Severity level of a log element.
 */
var LogSeverity;
(function (LogSeverity) {
    LogSeverity["DEBUG"] = "debug";
    LogSeverity["INFO"] = "info";
    LogSeverity["WARNING"] = "warning";
    LogSeverity["ERROR"] = "error";
    LogSeverity["FATAL"] = "fatal";
})(LogSeverity = exports.LogSeverity || (exports.LogSeverity = {}));
/**
 * The status of a transaction when it is ended.
 */
var TransactionStatus;
(function (TransactionStatus) {
    /**
     * The operation completed successfully.
     */
    TransactionStatus["OK"] = "ok";
    /**
     * Unknown. Any non-standard HTTP status code.
     */
    TransactionStatus["UNKNOWN_ERROR"] = "unknown_error";
    /**
     * The operation was cancelled (typically by the user).
     */
    TransactionStatus["CANCELLED"] = "cancelled";
    /**
     * The operation was aborted, typically due to a concurrency issue.
     */
    TransactionStatus["ABORTED"] = "aborted";
})(TransactionStatus = exports.TransactionStatus || (exports.TransactionStatus = {}));
