"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMobileDevice = void 0;
/**
 * Checks if the current device is a mobile device.
 */
function isMobileDevice() {
    var userAgent = navigator.userAgent.toLowerCase();
    return (userAgent.includes('mobile') ||
        userAgent.includes('android') ||
        userAgent.includes('iphone') ||
        userAgent.includes('ipad') ||
        userAgent.includes('windows phone'));
}
exports.isMobileDevice = isMobileDevice;
