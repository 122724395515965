"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useImageDetailedViewStyles = void 0;
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var ImageDetailedView_styles_1 = require("./ImageDetailedView.styles");
function useImageDetailedViewStyles(props) {
    var _a, _b;
    var palette = (0, common_1.useMonkTheme)().palette;
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    var colors = (0, react_1.useMemo)(function () { return ({
        darkButtonBackground: (0, common_1.changeAlpha)(palette.surface.dark, 0.56),
    }); }, [palette]);
    var rightContainerJustifyContent = 'start';
    if (props.captureMode) {
        rightContainerJustifyContent = props.showGalleryButton ? 'space-between' : 'end';
    }
    return {
        mainContainerStyle: __assign(__assign(__assign({}, ImageDetailedView_styles_1.styles['mainContainer']), responsive(ImageDetailedView_styles_1.styles['mainContainerSmall'])), { backgroundColor: palette.background.base, backgroundImage: "url(".concat(props.image.path, ")") }),
        leftContainerStyle: ImageDetailedView_styles_1.styles['leftContainer'],
        overlayContainerStyle: ImageDetailedView_styles_1.styles['overlayContainer'],
        rightContainerStyle: __assign(__assign(__assign({}, ImageDetailedView_styles_1.styles['rightContainer']), responsive(ImageDetailedView_styles_1.styles['rightContainerSmall'])), { justifyContent: rightContainerJustifyContent }),
        closeButton: {
            primaryColor: colors.darkButtonBackground,
            secondaryColor: palette.text.primary,
        },
        galleryButton: {
            primaryColor: colors.darkButtonBackground,
            secondaryColor: palette.text.primary,
            style: {
                visibility: ((_a = props.showGalleryButton) !== null && _a !== void 0 ? _a : true) ? 'visible' : 'hidden',
            },
        },
        cameraButton: {
            style: {
                visibility: props.captureMode && ((_b = props.showCaptureButton) !== null && _b !== void 0 ? _b : true) ? 'visible' : 'hidden',
            },
        },
    };
}
exports.useImageDetailedViewStyles = useImageDetailedViewStyles;
