"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonkAppStateContext = void 0;
var react_1 = require("react");
/**
 * React context used to store the current Monk application state.
 *
 * @see MonkAppState
 */
exports.MonkAppStateContext = (0, react_1.createContext)(null);
