"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInspectionImages = void 0;
/**
 * Utility function that extracts the images of the given inspection.
 *
 * @param inspectionId The ID of the inspection to get the images of.
 * @param images Array containing every image existing in the current local state.
 * @param filterRetakes Boolean indicating if retaken pictures should be filtered out or not (default: false).
 */
function getInspectionImages(inspectionId, images, filterRetakes) {
    if (filterRetakes === void 0) { filterRetakes = false; }
    var inspectionImages = images.filter(function (image) { return image.inspectionId === inspectionId; });
    if (!filterRetakes) {
        return inspectionImages;
    }
    var filteredRetakes = [];
    inspectionImages.forEach(function (image) {
        if (image.sightId) {
            var index = filteredRetakes.findIndex(function (i) { return i.sightId === image.sightId; });
            if (index >= 0) {
                if (image.createdAt &&
                    filteredRetakes[index].createdAt &&
                    image.createdAt > filteredRetakes[index].createdAt) {
                    filteredRetakes[index] = image;
                }
                return;
            }
        }
        filteredRetakes.push(image);
    });
    return filteredRetakes;
}
exports.getInspectionImages = getInspectionImages;
