"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVisilityStyle = exports.usePhotoCaptureHUDSightPreviewStyle = void 0;
var common_1 = require("@monkvision/common");
var PhotoCaptureHUDElementsSight_styles_1 = require("./PhotoCaptureHUDElementsSight.styles");
function usePhotoCaptureHUDSightPreviewStyle(_a) {
    var previewDimensions = _a.previewDimensions;
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    return {
        container: PhotoCaptureHUDElementsSight_styles_1.styles['container'],
        elementsContainer: __assign(__assign({}, PhotoCaptureHUDElementsSight_styles_1.styles['elementsContainer']), responsive(PhotoCaptureHUDElementsSight_styles_1.styles['elementsContainerPortrait'])),
        top: PhotoCaptureHUDElementsSight_styles_1.styles['top'],
        bottom: PhotoCaptureHUDElementsSight_styles_1.styles['bottom'],
        overlay: {
            width: previewDimensions === null || previewDimensions === void 0 ? void 0 : previewDimensions.width,
            height: previewDimensions === null || previewDimensions === void 0 ? void 0 : previewDimensions.height,
        },
        guidelineBtn: PhotoCaptureHUDElementsSight_styles_1.styles['guidelineBtn'],
        addDamageBtn: PhotoCaptureHUDElementsSight_styles_1.styles['addDamageBtn'],
    };
}
exports.usePhotoCaptureHUDSightPreviewStyle = usePhotoCaptureHUDSightPreviewStyle;
function getVisilityStyle(enable) {
    return { visibility: enable ? 'visible' : 'hidden' };
}
exports.getVisilityStyle = getVisilityStyle;
