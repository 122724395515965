"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useComplianceAnalytics = void 0;
var analytics_1 = require("@monkvision/analytics");
var common_1 = require("@monkvision/common");
var types_1 = require("@monkvision/types");
var react_1 = require("react");
/**
 * Custom hook used for the compliance analytics by sending an event for 'non_compliant' image.
 */
function useComplianceAnalytics(_a) {
    var inspectionId = _a.inspectionId, sights = _a.sights;
    var _b = (0, react_1.useState)([]), imagesEventTracking = _b[0], setImagesEventTracking = _b[1];
    var _c = (0, react_1.useState)(false), isInitialInspectionFetched = _c[0], setIsInitialInspectionFetched = _c[1];
    var state = (0, common_1.useMonkState)().state;
    var trackEvent = (0, analytics_1.useAnalytics)().trackEvent;
    (0, react_1.useEffect)(function () {
        var newImagesEventTracking = state.images
            .filter(function (image) { return image.inspectionId === inspectionId && image.sightId; })
            .map(function (image) {
            var _a;
            var imageEventTracking = imagesEventTracking.find(function (i) { return i.id === image.id; });
            if (imageEventTracking === null || imageEventTracking === void 0 ? void 0 : imageEventTracking.isAlreadySent) {
                return imageEventTracking;
            }
            if (image.status === types_1.ImageStatus.NOT_COMPLIANT && image.complianceIssues) {
                if (isInitialInspectionFetched) {
                    trackEvent('Compliance Issue', {
                        complianceIssue: image.complianceIssues.at(0),
                        sightId: image.sightId,
                        sightLabel: (_a = sights.find(function (sight) { return sight.id === image.sightId; })) === null || _a === void 0 ? void 0 : _a.label,
                    });
                }
                return __assign(__assign({}, image), { isAlreadySent: true });
            }
            return __assign(__assign({}, image), { isAlreadySent: false });
        });
        setImagesEventTracking(newImagesEventTracking);
    }, [state]);
    return { setIsInitialInspectionFetched: setIsInitialInspectionFetched };
}
exports.useComplianceAnalytics = useComplianceAnalytics;
