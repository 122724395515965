"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhotoCaptureHUDButtons = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var common_1 = require("@monkvision/common");
var hooks_1 = require("./hooks");
/**
 * Components implementing the main buttons of the PhotoCapture Camera HUD. This component implements 3 buttons :
 * - A take picture button
 * - A gallery button
 * - A close button (only displayed if the `onClose` callback is defined)
 */
function PhotoCaptureHUDButtons(_a) {
    var galleryPreview = _a.galleryPreview, onTakePicture = _a.onTakePicture, onOpenGallery = _a.onOpenGallery, onClose = _a.onClose, _b = _a.galleryDisabled, galleryDisabled = _b === void 0 ? false : _b, _c = _a.takePictureDisabled, takePictureDisabled = _c === void 0 ? false : _c, _d = _a.closeDisabled, closeDisabled = _d === void 0 ? false : _d, _e = _a.showCloseButton, showCloseButton = _e === void 0 ? false : _e, _f = _a.showGalleryBadge, showGalleryBadge = _f === void 0 ? false : _f, _g = _a.retakeCount, retakeCount = _g === void 0 ? 0 : _g;
    var _h = (0, common_1.useInteractiveStatus)({
        disabled: galleryDisabled,
    }), galleryStatus = _h.status, galleryEventHandlers = _h.eventHandlers;
    var _j = (0, common_1.useInteractiveStatus)({
        disabled: closeDisabled,
    }), closeStatus = _j.status, closeEventHandlers = _j.eventHandlers;
    var _k = (0, hooks_1.useCaptureHUDButtonsStyles)({
        galleryStatus: galleryStatus,
        closeStatus: closeStatus,
        closeBtnAvailable: !!onClose,
        galleryPreview: galleryPreview,
        showCloseButton: showCloseButton,
        showGalleryBadge: showGalleryBadge,
    }), containerStyle = _k.containerStyle, gallery = _k.gallery, galleryBadgeStyle = _k.galleryBadgeStyle, close = _k.close, backgroundCoverStyle = _k.backgroundCoverStyle;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: containerStyle }, { children: [(0, jsx_runtime_1.jsx)("button", __assign({ style: close.style, disabled: closeDisabled, onClick: onClose }, closeEventHandlers, { "data-testid": 'monk-close-btn' }, { children: (0, jsx_runtime_1.jsx)(common_ui_web_1.Icon, { icon: 'close', size: 30, primaryColor: close.iconColor }) })), (0, jsx_runtime_1.jsx)(common_ui_web_1.TakePictureButton, { onClick: onTakePicture, size: 85, disabled: takePictureDisabled }), (0, jsx_runtime_1.jsx)("button", __assign({ style: gallery.style, disabled: galleryDisabled, onClick: onOpenGallery }, galleryEventHandlers, { "data-testid": 'monk-gallery-btn' }, { children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [galleryPreview ? ((0, jsx_runtime_1.jsx)("div", { style: backgroundCoverStyle })) : ((0, jsx_runtime_1.jsx)(common_ui_web_1.Icon, { icon: 'gallery', size: 30, primaryColor: gallery.iconColor })), (0, jsx_runtime_1.jsx)("div", __assign({ "data-testid": 'monk-gallery-badge', style: galleryBadgeStyle }, { children: retakeCount > 0 && retakeCount }))] }) }))] })));
}
exports.PhotoCaptureHUDButtons = PhotoCaptureHUDButtons;
