"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DynamicSVG = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var hooks_1 = require("./hooks");
var SVGElement_1 = require("./SVGElement");
/**
 * Custom component that is used to display an SVG image from an SVG string, and dynamically customize this SVG by
 * passing custom attributes or inner text to the SVG children elements. The only required property is the SVG string
 * itself. The rest of the props are passed to the SVG element itself.
 *
 * In order to customize the SVG dynamically, you can pass customization functions as props : getAttributes and
 * getInnerText that will return respectively custom attributes and custom inner text for the SVG element and its
 * children based on the given element and its parent groups.
 *
 * @example
 * import React, { useCallback } from 'react';
 * import { DynamicSVG } from '@monkvision/common-ui-web';
 *
 * const svg = `<svg height="100" width="100">
 *  <circle id="circle1" cx="20" cy="20" r="30"/><circle id="circle2" cx="80" cy="80" r="30"/></svg>`;
 *
 * // Applies a red fill and an onClick handler on the element with ID "circle1"
 * function MyCustomSVG() {
 *   const getAttributes = useCallback((element: Element) => {
 *     if (element.getAttribute('id') === 'circle1') {
 *       return {
 *         style: { fill: 'red' },
 *         onClick: () => console.log('hello'),
 *         pointerEvents: 'all',
 *       };
 *     }
 *     return null;
 *   }, []);
 *
 *   return <DynamicSVG svg={svg} width={300} getAttributes={getAttributes} />
 * }
 */
function DynamicSVG(_a) {
    var svg = _a.svg, passThroughProps = __rest(_a, ["svg"]);
    var doc = (0, hooks_1.useXMLParser)(svg);
    var svgEl = (0, react_1.useMemo)(function () {
        var element = doc.children[0];
        if (element.tagName !== 'svg') {
            throw new Error("Invalid SVG string provided to the DynamicSVG component: expected <svg> tag as the first children of XML document but got <".concat(element.tagName, ">."));
        }
        return element;
    }, [doc]);
    return (0, jsx_runtime_1.jsx)(SVGElement_1.SVGElement, __assign({ element: svgEl }, passThroughProps));
}
exports.DynamicSVG = DynamicSVG;
