"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAsyncEffect = void 0;
var react_1 = require("react");
/**
 * Custom hook that can be used to run asyncrhonous effects. It is similar to `useEffect` but makes sure to not execute
 * the effect handlers if the effect's Promise resolves after the current component as been dismounted.
 */
function useAsyncEffect(effect, deps, handlers) {
    (0, react_1.useEffect)(function () {
        var isActive = true;
        effect()
            .then(function (res) {
            var _a;
            if (isActive) {
                (_a = handlers === null || handlers === void 0 ? void 0 : handlers.onResolve) === null || _a === void 0 ? void 0 : _a.call(handlers, res);
            }
        })
            .catch(function (err) {
            var _a;
            if (isActive) {
                (_a = handlers === null || handlers === void 0 ? void 0 : handlers.onReject) === null || _a === void 0 ? void 0 : _a.call(handlers, err);
            }
        })
            .finally(function () {
            var _a;
            if (isActive) {
                (_a = handlers === null || handlers === void 0 ? void 0 : handlers.onComplete) === null || _a === void 0 ? void 0 : _a.call(handlers);
            }
        });
        return function () {
            isActive = false;
        };
    }, deps);
}
exports.useAsyncEffect = useAsyncEffect;
