"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CAR_COVERAGE_COMPLIANCE_ISSUES = exports.ZOOM_LEVEL_COMPLIANCE_ISSUES = exports.VQA_COMPLIANCE_ISSUES = exports.IQA_COMPLIANCE_ISSUES = exports.DEFAULT_COMPLIANCE_ISSUES = exports.COMPLIANCE_ISSUES_PRIORITY = exports.ComplianceIssue = exports.ImageStatus = exports.CentersOnElement = exports.ImageSubtype = exports.ImageType = void 0;
/**
 * The type of image.
 */
var ImageType;
(function (ImageType) {
    /**
     * A "normal" image taken by the user when doing an inspection : a sight was shown to the user, and he took a picture
     * of the vehicle while trying to match the point of view of the sight.
     */
    ImageType["BEAUTY_SHOT"] = "beauty_shot";
    /**
     * This type of image includes 2 scenarios :
     * - This image is a "close up" picture taken by the user when using the "Zoomed Damage" feature of the capture
     * workflow : the user took a custom picture, zoomed closely on a damage and without being shown a sight at all.
     * - This image is associated to a View of beauty shot image (a zoomed / cropped / transformed image) that focuses on
     * a specific part of the image (a vehicle part, a damage etc.).
     */
    ImageType["CLOSE_UP"] = "close_up";
})(ImageType = exports.ImageType || (exports.ImageType = {}));
/**
 * The subtype of an image.
 */
var ImageSubtype;
(function (ImageSubtype) {
    /**
     * For images that are "close up" images focused on a vehicle part.
     */
    ImageSubtype["CLOSE_UP_PART"] = "close_up_part";
    /**
     * For images that are "close up" images focused on a damage.
     */
    ImageSubtype["CLOSE_UP_DAMAGE"] = "close_up_damage";
})(ImageSubtype = exports.ImageSubtype || (exports.ImageSubtype = {}));
/**
 * Enumeration of elements other than vehicle parts that can be the main focus of a picture.
 */
var CentersOnElement;
(function (CentersOnElement) {
    CentersOnElement["FRONT"] = "front";
    CentersOnElement["BACK"] = "back";
    CentersOnElement["LEFT"] = "left";
    CentersOnElement["RIGHT"] = "right";
    CentersOnElement["FRONT_LEFT"] = "front_left";
    CentersOnElement["FRONT_RIGHT"] = "front_right";
    CentersOnElement["BACK_LEFT"] = "back_left";
    CentersOnElement["BACK_RIGHT"] = "back_right";
    CentersOnElement["KEYS"] = "keys";
    CentersOnElement["DASHBOARD"] = "dashboard";
    CentersOnElement["UNDERCARRIAGE"] = "undercarriage";
    CentersOnElement["SEATS"] = "seats";
    CentersOnElement["TRUNK_INTERIOR"] = "trunk_interior";
})(CentersOnElement = exports.CentersOnElement || (exports.CentersOnElement = {}));
/**
 * Enumeration of the possible statuses of an inspection image.
 */
var ImageStatus;
(function (ImageStatus) {
    /**
     * The image is currently being uploaded.
     */
    ImageStatus["UPLOADING"] = "uploading";
    /**
     * The image has been uploaded successfully, the compliance is enabled for this image and the compliance check has not
     * finished yet.
     */
    ImageStatus["COMPLIANCE_RUNNING"] = "compliance_running";
    /**
     * The image was successfully uploaded. If the compliance is enabled for this image, this status also means that the
     * compliance has finished and the image is fully compliant.
     */
    ImageStatus["SUCCESS"] = "success";
    /**
     * The image couldn't be uploaded to the API due to connection issues.
     */
    ImageStatus["UPLOAD_FAILED"] = "upload_failed";
    /**
     * An HTTP error resulted from the API call made to upload the image.
     */
    ImageStatus["UPLOAD_ERROR"] = "upload_error";
    /**
     * The image was successfully uploaded to the API, and the compliance chekc finished running but its result says that
     * the image is NOT compliant. The list of reasons describing why the image is not compliant is available in the
     * `image.complianceReasons` array.
     */
    ImageStatus["NOT_COMPLIANT"] = "not_compliant";
})(ImageStatus = exports.ImageStatus || (exports.ImageStatus = {}));
/**
 * Enumeration of the possible issues that could explain why an image is not compliant.
 */
var ComplianceIssue;
(function (ComplianceIssue) {
    ComplianceIssue["OTHER"] = "other";
    ComplianceIssue["LOW_RESOLUTION"] = "low_resolution";
    ComplianceIssue["BLURRINESS"] = "blurriness";
    ComplianceIssue["UNDEREXPOSURE"] = "underexposure";
    ComplianceIssue["OVEREXPOSURE"] = "overexposure";
    ComplianceIssue["LENS_FLARE"] = "lens_flare";
    ComplianceIssue["DIRTINESS"] = "dirtiness";
    ComplianceIssue["SNOWNESS"] = "snowness";
    ComplianceIssue["WETNESS"] = "wetness";
    ComplianceIssue["REFLECTIONS"] = "reflections";
    ComplianceIssue["UNKNOWN_SIGHT"] = "unknown_sight";
    ComplianceIssue["UNKNOWN_VIEWPOINT"] = "unknown_viewpoint";
    ComplianceIssue["NO_VEHICLE"] = "no_vehicle";
    ComplianceIssue["WRONG_ANGLE"] = "wrong_angle";
    ComplianceIssue["WRONG_CENTER_PART"] = "wrong_center_part";
    ComplianceIssue["MISSING_PARTS"] = "missing_parts";
    ComplianceIssue["HIDDEN_PARTS"] = "hidden_parts";
    ComplianceIssue["TOO_ZOOMED"] = "too_zoomed";
    ComplianceIssue["NOT_ZOOMED_ENOUGH"] = "not_zoomed_enough";
    ComplianceIssue["INTERIOR_NOT_SUPPORTED"] = "interior_not_supported";
    ComplianceIssue["MISSING"] = "missing";
    ComplianceIssue["LOW_QUALITY"] = "low_quality";
})(ComplianceIssue = exports.ComplianceIssue || (exports.ComplianceIssue = {}));
/**
 * The order of priority of compliance issues in the SDK. This array is used to sort compliance issue arrays. The
 * smaller the index, the higher the priority of the compliance issue.
 */
exports.COMPLIANCE_ISSUES_PRIORITY = [
    ComplianceIssue.NO_VEHICLE,
    ComplianceIssue.BLURRINESS,
    ComplianceIssue.OVEREXPOSURE,
    ComplianceIssue.UNDEREXPOSURE,
    ComplianceIssue.LENS_FLARE,
    ComplianceIssue.TOO_ZOOMED,
    ComplianceIssue.NOT_ZOOMED_ENOUGH,
    ComplianceIssue.WRONG_ANGLE,
    ComplianceIssue.HIDDEN_PARTS,
    ComplianceIssue.MISSING_PARTS,
    ComplianceIssue.WRONG_CENTER_PART,
    ComplianceIssue.REFLECTIONS,
    ComplianceIssue.SNOWNESS,
    ComplianceIssue.WETNESS,
    ComplianceIssue.DIRTINESS,
    ComplianceIssue.LOW_QUALITY,
    ComplianceIssue.LOW_RESOLUTION,
    ComplianceIssue.UNKNOWN_SIGHT,
    ComplianceIssue.UNKNOWN_VIEWPOINT,
    ComplianceIssue.INTERIOR_NOT_SUPPORTED,
    ComplianceIssue.MISSING,
    ComplianceIssue.OTHER,
];
/**
 * The default compliance issues used in the SDK. This array is the default value for the `complianceIssues` property of
 * the `ComplianceOptions` interface. If a compliance issue is not in this array, then it is disabled by default in the
 * SDK.
 *
 * @see ComplianceOptions
 */
exports.DEFAULT_COMPLIANCE_ISSUES = [
    // ComplianceIssue.OTHER,
    // ComplianceIssue.LOW_RESOLUTION,
    ComplianceIssue.BLURRINESS,
    ComplianceIssue.UNDEREXPOSURE,
    ComplianceIssue.OVEREXPOSURE,
    ComplianceIssue.LENS_FLARE,
    // ComplianceIssue.DIRTINESS,
    // ComplianceIssue.SNOWNESS,
    // ComplianceIssue.WETNESS,
    ComplianceIssue.REFLECTIONS,
    ComplianceIssue.UNKNOWN_SIGHT,
    ComplianceIssue.UNKNOWN_VIEWPOINT,
    ComplianceIssue.NO_VEHICLE,
    ComplianceIssue.WRONG_ANGLE,
    ComplianceIssue.WRONG_CENTER_PART,
    ComplianceIssue.MISSING_PARTS,
    ComplianceIssue.HIDDEN_PARTS,
    ComplianceIssue.TOO_ZOOMED,
    ComplianceIssue.NOT_ZOOMED_ENOUGH,
    // ComplianceIssue.INTERIOR_NOT_SUPPORTED,
    ComplianceIssue.MISSING,
    // ComplianceIssue.LOW_QUALITY,
];
/**
 * An array of compliance issues used to make the IQA checks (Image Quality Assessment). Spread this array in the
 * `complianceIssues` property of the `ComplianceOptions` interface to enable IQA.
 */
exports.IQA_COMPLIANCE_ISSUES = [
    ComplianceIssue.BLURRINESS,
    ComplianceIssue.UNDEREXPOSURE,
    ComplianceIssue.OVEREXPOSURE,
    ComplianceIssue.LENS_FLARE,
];
/**
 * An array of compliance issues used to make the VQA checks (Vehicle Quality Assessment). Spread this array in the
 * `complianceIssues` property of the `ComplianceOptions` interface to enable VQA.
 */
exports.VQA_COMPLIANCE_ISSUES = [
    ComplianceIssue.DIRTINESS,
    ComplianceIssue.SNOWNESS,
    ComplianceIssue.WETNESS,
    ComplianceIssue.REFLECTIONS,
];
/**
 * An array of compliance issues used to make the zoom level checks. Spread this array in the `complianceIssues`
 * property of the `ComplianceOptions` interface to enable zoom level checks.
 */
exports.ZOOM_LEVEL_COMPLIANCE_ISSUES = [
    ComplianceIssue.TOO_ZOOMED,
    ComplianceIssue.NOT_ZOOMED_ENOUGH,
];
/**
 * An array of compliance issues used to make the car coverage checks (is the sight properly aligned etc.). Spread this
 * array in the `complianceIssues` property of the `ComplianceOptions` interface to enable car coverage.
 */
exports.CAR_COVERAGE_COMPLIANCE_ISSUES = [
    ComplianceIssue.UNKNOWN_SIGHT,
    ComplianceIssue.UNKNOWN_VIEWPOINT,
    ComplianceIssue.NO_VEHICLE,
    ComplianceIssue.WRONG_ANGLE,
    ComplianceIssue.WRONG_CENTER_PART,
    ComplianceIssue.MISSING_PARTS,
    ComplianceIssue.HIDDEN_PARTS,
];
