"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhotoCaptureHUDTutorial = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var PhotoCaptureHUDTutorial_styles_1 = require("./PhotoCaptureHUDTutorial.styles");
var hooks_1 = require("../../hooks");
var hooks_2 = require("../hooks");
var PhotoCaptureHUDElementsSight_1 = require("../PhotoCaptureHUDElementsSight");
var ArrowIcon_1 = require("./ArrowIcon");
var DisplayText_1 = require("./DisplayText");
function getButtonStyle(enableAddDamage) {
    return { visibility: enableAddDamage ? 'visible' : 'hidden' };
}
/**
 * Component that displays an tutorial overlay on top of the PhotoCapture component.
 */
function PhotoCaptureHUDTutorial(_a) {
    var currentTutorialStep = _a.currentTutorialStep, allowSkipTutorial = _a.allowSkipTutorial, sightGuidelines = _a.sightGuidelines, sightId = _a.sightId, onNextTutorialStep = _a.onNextTutorialStep, onCloseTutorial = _a.onCloseTutorial;
    var t = (0, react_i18next_1.useTranslation)().t;
    var primaryColor = (0, hooks_2.usePhotoCaptureHUDButtonBackground)();
    return currentTutorialStep ? ((0, jsx_runtime_1.jsx)("div", __assign({ style: PhotoCaptureHUDTutorial_styles_1.styles['backdropContainer'], "data-testid": 'backdrop' }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ style: PhotoCaptureHUDTutorial_styles_1.styles['elementsContainer'] }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ style: PhotoCaptureHUDTutorial_styles_1.styles['topContainer'] }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ style: PhotoCaptureHUDTutorial_styles_1.styles['buttonsContainer'] }, { children: [(0, jsx_runtime_1.jsx)("div", { style: PhotoCaptureHUDTutorial_styles_1.styles['closeButtonTwin'] }), (0, jsx_runtime_1.jsx)(PhotoCaptureHUDElementsSight_1.SightGuideline, { sightId: sightId, sightGuidelines: sightGuidelines, enableSightGuidelines: currentTutorialStep === hooks_1.TutorialSteps.GUIDELINE, enableAddDamage: true, enableDefaultMessage: true }), (0, jsx_runtime_1.jsx)(common_ui_web_1.Button, { style: __assign(__assign({}, PhotoCaptureHUDTutorial_styles_1.styles['closeButton']), getButtonStyle(allowSkipTutorial)), disabled: !allowSkipTutorial, icon: 'close', primaryColor: primaryColor, onClick: onCloseTutorial })] })), (0, jsx_runtime_1.jsx)("div", __assign({ style: PhotoCaptureHUDTutorial_styles_1.styles['arrows'] }, { children: (0, jsx_runtime_1.jsx)(ArrowIcon_1.ArrowIcon, { tutorialStep: currentTutorialStep }) }))] })), (0, jsx_runtime_1.jsx)(DisplayText_1.DisplayText, { tutorialStep: currentTutorialStep }), (0, jsx_runtime_1.jsx)(common_ui_web_1.Button, __assign({ style: PhotoCaptureHUDTutorial_styles_1.styles['nextButton'], primaryColor: 'primary', onClick: onNextTutorialStep }, { children: t('photo.hud.tutorial.next') }))] })) }))) : null;
}
exports.PhotoCaptureHUDTutorial = PhotoCaptureHUDTutorial;
