"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timeoutPromise = void 0;
/**
 * This function creates and returns a new Promise that will resolve to void after the given amount of milliseconds.
 */
function timeoutPromise(delayMs) {
    return new Promise(function (resolve) {
        setTimeout(function () { return resolve(); }, delayMs);
    });
}
exports.timeoutPromise = timeoutPromise;
