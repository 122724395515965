"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwitchButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var hooks_1 = require("./hooks");
var icons_1 = require("../../icons");
/**
 * Switch button component that can be used to turn ON or OFF a feature.
 *
 * @see https://uxplanet.org/checkbox-vs-toggle-switch-7fc6e83f10b8
 * @example
 * export function MyComponent() {
 *   const [checked, setChecked] = useState(false);
 *   return (
 *     <div>
 *       <SwitchButton checked={checked} onSwitch={(value) => setChecked(value)} />
 *     </div>
 *   );
 * }
 */
function SwitchButton(_a) {
    var _b = _a.size, size = _b === void 0 ? 'normal' : _b, _c = _a.checked, checked = _c === void 0 ? false : _c, onSwitch = _a.onSwitch, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.checkedPrimaryColor, checkedPrimaryColor = _e === void 0 ? 'primary' : _e, _f = _a.checkedSecondaryColor, checkedSecondaryColor = _f === void 0 ? 'text-primary' : _f, _g = _a.uncheckedPrimaryColor, uncheckedPrimaryColor = _g === void 0 ? 'text-disabled' : _g, _h = _a.uncheckedSecondaryColor, uncheckedSecondaryColor = _h === void 0 ? 'text-primary' : _h, checkedLabel = _a.checkedLabel, uncheckedLabel = _a.uncheckedLabel;
    var _j = (0, common_1.useInteractiveStatus)({ disabled: disabled }), status = _j.status, eventHandlers = _j.eventHandlers;
    var switchButtonStyles = (0, hooks_1.useSwitchButtonStyles)({
        size: size,
        checked: checked,
        checkedPrimaryColor: checkedPrimaryColor,
        checkedSecondaryColor: checkedSecondaryColor,
        uncheckedPrimaryColor: uncheckedPrimaryColor,
        uncheckedSecondaryColor: uncheckedSecondaryColor,
        status: status,
    });
    var handleSwitch = function () {
        if (onSwitch) {
            onSwitch(!checked);
        }
    };
    return ((0, jsx_runtime_1.jsxs)("button", __assign({ style: switchButtonStyles.buttonStyle, onClick: handleSwitch, disabled: disabled }, eventHandlers, { "data-testid": 'switch-btn' }, { children: [size === 'normal' && !checkedLabel && ((0, jsx_runtime_1.jsx)(icons_1.Icon, { icon: 'check', primaryColor: switchButtonStyles.icon.color, size: switchButtonStyles.icon.size, style: switchButtonStyles.icon.style })), size === 'normal' && checkedLabel && ((0, jsx_runtime_1.jsx)("div", __assign({ style: switchButtonStyles.checkedLabelStyle }, { children: checkedLabel !== null && checkedLabel !== void 0 ? checkedLabel : '' }))), size === 'normal' && ((0, jsx_runtime_1.jsx)("div", __assign({ style: switchButtonStyles.uncheckedLabelStyle }, { children: uncheckedLabel !== null && uncheckedLabel !== void 0 ? uncheckedLabel : '' }))), (0, jsx_runtime_1.jsx)("div", __assign({ style: switchButtonStyles.knobStyle, "data-testid": 'switch-knob' }, { children: size === 'small' && checked && ((0, jsx_runtime_1.jsx)(icons_1.Icon, { icon: 'check', primaryColor: switchButtonStyles.iconSmall.color, size: switchButtonStyles.iconSmall.size, style: switchButtonStyles.iconSmall.style })) })), (0, jsx_runtime_1.jsx)("div", { style: switchButtonStyles.knobOverlayStyle })] })));
}
exports.SwitchButton = SwitchButton;
