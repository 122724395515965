"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVehicleTypeSelectionCardStyles = void 0;
var types_1 = require("@monkvision/types");
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var VehicleTypeSelectionCard_styles_1 = require("./VehicleTypeSelectionCard.styles");
function getCardColor(colors, isSelected, status) {
    if (isSelected) {
        return colors.selected;
    }
    if (status === types_1.InteractiveStatus.HOVERED) {
        return colors.hovered;
    }
    if (status === types_1.InteractiveStatus.ACTIVE) {
        return colors.active;
    }
    return colors.default;
}
function useVehicleTypeSelectionCardStyles(_a) {
    var isSelected = _a.isSelected, status = _a.status;
    var palette = (0, common_1.useMonkTheme)().palette;
    var colors = (0, react_1.useMemo)(function () { return ({
        assetContainerBackground: {
            default: (0, common_1.changeAlpha)(palette.surface.dark, 0.56),
            hovered: (0, common_1.changeAlpha)(palette.surface.dark, 0.4),
            active: (0, common_1.changeAlpha)(palette.surface.dark, 0.24),
            selected: (0, common_1.changeAlpha)(palette.surface.light, 0.08),
        },
        labelBackground: {
            default: (0, common_1.changeAlpha)(palette.surface.dark, 0.32),
            hovered: (0, common_1.changeAlpha)(palette.surface.dark, 0.24),
            active: (0, common_1.changeAlpha)(palette.surface.dark, 0.18),
            selected: palette.surface.light,
        },
    }); }, [palette]);
    return {
        containerStyle: __assign(__assign(__assign({}, VehicleTypeSelectionCard_styles_1.styles['container']), (isSelected ? VehicleTypeSelectionCard_styles_1.styles['containerSelected'] : {})), { cursor: isSelected ? 'default' : 'pointer' }),
        assetContainerStyle: __assign(__assign({}, VehicleTypeSelectionCard_styles_1.styles['assetContainer']), { backgroundColor: getCardColor(colors.assetContainerBackground, isSelected, status) }),
        assetStyle: VehicleTypeSelectionCard_styles_1.styles['asset'],
        labelStyle: __assign(__assign(__assign({}, VehicleTypeSelectionCard_styles_1.styles['label']), (isSelected ? VehicleTypeSelectionCard_styles_1.styles['labelSelected'] : {})), { backgroundColor: getCardColor(colors.labelBackground, isSelected, status), color: isSelected ? palette.text.black : palette.text.primary }),
    };
}
exports.useVehicleTypeSelectionCardStyles = useVehicleTypeSelectionCardStyles;
