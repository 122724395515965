"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useObjectMemo = void 0;
var react_1 = require("react");
/**
 * This custom hook is used to have a more handy way of memoizing a record of values.
 *
 * @example
 * import { useMemo } from 'React';
 * import { useObjectMemo } from '@monkvision/common';
 *
 * // These 2 lines are equivalent
 * const foo = useMemo(() => ({ bar, baz }), [bar, baz]);
 * const foo = useObjectMemo({ bar, baz });
 */
function useObjectMemo(object) {
    return (0, react_1.useMemo)(function () { return object; }, Object.values(object));
}
exports.useObjectMemo = useObjectMemo;
