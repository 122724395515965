"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapApiDamagePostRequest = void 0;
function mapApiDamagePostRequest(damageType, vehiclePart) {
    return {
        damage_type: damageType,
        part_type: vehiclePart,
    };
}
exports.mapApiDamagePostRequest = mapApiDamagePostRequest;
