"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonkDefaultPalette = void 0;
exports.MonkDefaultPalette = {
    primary: {
        xdark: '#00203D',
        dark: '#00529E',
        base: '#0A84FF',
        light: '#91CAFF',
        xlight: '#F2F9FF',
    },
    secondary: {
        xdark: '#2E2F38',
        dark: '#505361',
        base: '#595E72',
        light: '#9C9FAE',
        xlight: '#C7C8D1',
    },
    alert: {
        xdark: '#330D0D',
        dark: '#992923',
        base: '#FF453A',
        light: '#FFA29D',
        xlight: '#FFECEA',
    },
    caution: {
        xdark: '#332B02',
        dark: '#998007',
        base: '#FFD60A',
        light: '#FFEB85',
        xlight: '#FFFBE7',
    },
    success: {
        xdark: '#0B2B0F',
        dark: '#196C26',
        base: '#32D74B',
        light: '#98EBA5',
        xlight: '#EBFAED',
    },
    information: {
        xdark: '#031A33',
        dark: '#054F99',
        base: '#0A84FF',
        light: '#85C2FF',
        xlight: '#E7F3FF',
    },
    text: {
        primary: '#FDFDFD',
        secondary: '#9B9FA3',
        disabled: '#7B8084',
        white: '#FFFFFF',
        black: '#000000',
        link: '#0A84FF',
        linkInverted: '#00529E',
    },
    background: {
        dark: '#0A0C0E',
        base: '#1C2228',
        light: '#3B4147',
    },
    surface: {
        dark: '#010101',
        light: '#FDFDFD',
    },
    outline: {
        base: '#7B61FF',
    },
};
