"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapApiImage = void 0;
var types_1 = require("@monkvision/types");
var utils_1 = require("../utils");
var DEFAULT_COMPLIANCE_OPTIONS = {
    enableCompliance: true,
    complianceIssues: types_1.DEFAULT_COMPLIANCE_ISSUES,
};
function getActiveComplianceOptions(sightId, complianceOptions) {
    var _a, _b, _c, _d;
    var enableComplianceGlobal = (_a = complianceOptions === null || complianceOptions === void 0 ? void 0 : complianceOptions.enableCompliance) !== null && _a !== void 0 ? _a : DEFAULT_COMPLIANCE_OPTIONS.enableCompliance;
    var enableCompliance = sightId && (complianceOptions === null || complianceOptions === void 0 ? void 0 : complianceOptions.enableCompliancePerSight)
        ? complianceOptions.enableCompliancePerSight.includes(sightId)
        : enableComplianceGlobal;
    var complianceIssuesGlobal = (_b = complianceOptions === null || complianceOptions === void 0 ? void 0 : complianceOptions.complianceIssues) !== null && _b !== void 0 ? _b : DEFAULT_COMPLIANCE_OPTIONS.complianceIssues;
    var complianceIssues = sightId && ((_c = complianceOptions === null || complianceOptions === void 0 ? void 0 : complianceOptions.complianceIssuesPerSight) === null || _c === void 0 ? void 0 : _c[sightId])
        ? complianceOptions.complianceIssuesPerSight[sightId]
        : complianceIssuesGlobal;
    var customComplianceThresholds = sightId
        ? (_d = complianceOptions === null || complianceOptions === void 0 ? void 0 : complianceOptions.customComplianceThresholdsPerSight) === null || _d === void 0 ? void 0 : _d[sightId]
        : complianceOptions === null || complianceOptions === void 0 ? void 0 : complianceOptions.customComplianceThresholds;
    return { enableCompliance: enableCompliance, complianceIssues: complianceIssues, customComplianceThresholds: customComplianceThresholds };
}
function getComplianceIssue(thresholdName) {
    switch (thresholdName) {
        case 'blurriness':
            return types_1.ComplianceIssue.BLURRINESS;
        case 'overexposure':
            return types_1.ComplianceIssue.OVEREXPOSURE;
        case 'underexposure':
            return types_1.ComplianceIssue.UNDEREXPOSURE;
        case 'lensFlare':
            return types_1.ComplianceIssue.LENS_FLARE;
        case 'wetness':
            return types_1.ComplianceIssue.WETNESS;
        case 'snowness':
            return types_1.ComplianceIssue.SNOWNESS;
        case 'dirtiness':
            return types_1.ComplianceIssue.DIRTINESS;
        case 'reflections':
            return types_1.ComplianceIssue.REFLECTIONS;
        default:
            return undefined;
    }
}
function getComplianceScore(thresholdName, complianceResult) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    switch (thresholdName) {
        case 'blurriness':
            return (_a = complianceResult === null || complianceResult === void 0 ? void 0 : complianceResult.image_analysis) === null || _a === void 0 ? void 0 : _a.blurriness;
        case 'overexposure':
            return (_b = complianceResult === null || complianceResult === void 0 ? void 0 : complianceResult.image_analysis) === null || _b === void 0 ? void 0 : _b.overexposure;
        case 'underexposure':
            return (_c = complianceResult === null || complianceResult === void 0 ? void 0 : complianceResult.image_analysis) === null || _c === void 0 ? void 0 : _c.underexposure;
        case 'lensFlare':
            return (_d = complianceResult === null || complianceResult === void 0 ? void 0 : complianceResult.image_analysis) === null || _d === void 0 ? void 0 : _d.lens_flare;
        case 'wetness':
            return (_e = complianceResult === null || complianceResult === void 0 ? void 0 : complianceResult.vehicle_analysis) === null || _e === void 0 ? void 0 : _e.wetness;
        case 'snowness':
            return (_f = complianceResult === null || complianceResult === void 0 ? void 0 : complianceResult.vehicle_analysis) === null || _f === void 0 ? void 0 : _f.snowness;
        case 'dirtiness':
            return (_g = complianceResult === null || complianceResult === void 0 ? void 0 : complianceResult.vehicle_analysis) === null || _g === void 0 ? void 0 : _g.dirtiness;
        case 'reflections':
            return (_h = complianceResult === null || complianceResult === void 0 ? void 0 : complianceResult.vehicle_analysis) === null || _h === void 0 ? void 0 : _h.reflections;
        case 'zoom':
            return (_j = complianceResult === null || complianceResult === void 0 ? void 0 : complianceResult.image_analysis) === null || _j === void 0 ? void 0 : _j.zoom;
        default:
            return undefined;
    }
}
function applyCustomComplianceThresholds(issues, complianceResult, customComplianceThresholds) {
    var complianceIssues = issues !== null && issues !== void 0 ? issues : [];
    if (!customComplianceThresholds) {
        return complianceIssues;
    }
    Object.keys(customComplianceThresholds).forEach(function (key) {
        var thresholdName = key;
        var complianceScore = getComplianceScore(thresholdName, complianceResult);
        if (thresholdName === 'zoom' && complianceScore && customComplianceThresholds.zoom) {
            complianceIssues = complianceIssues.filter(function (issue) { return ![types_1.ComplianceIssue.TOO_ZOOMED, types_1.ComplianceIssue.NOT_ZOOMED_ENOUGH].includes(issue); });
            if (complianceScore < customComplianceThresholds.zoom.min) {
                complianceIssues.push(types_1.ComplianceIssue.TOO_ZOOMED);
            }
            else if (complianceScore > customComplianceThresholds.zoom.max) {
                complianceIssues.push(types_1.ComplianceIssue.NOT_ZOOMED_ENOUGH);
            }
            return;
        }
        var customThreshold = customComplianceThresholds[thresholdName];
        var complianceIssue = getComplianceIssue(thresholdName);
        if (complianceScore && complianceIssue && customThreshold !== undefined) {
            complianceIssues = complianceIssues.filter(function (issue) { return issue !== complianceIssue; });
            if (complianceScore < customThreshold) {
                complianceIssues.push(complianceIssue);
            }
        }
    });
    return complianceIssues;
}
function filterCompliances(issues, validIssues) {
    return issues.filter(function (issue) { return validIssues.includes(issue); });
}
function compareComplianceIssues(a, b) {
    return types_1.COMPLIANCE_ISSUES_PRIORITY.indexOf(a) - types_1.COMPLIANCE_ISSUES_PRIORITY.indexOf(b);
}
function mapCompliance(sightId, complianceResult, complianceOptions) {
    var _a = getActiveComplianceOptions(sightId, complianceOptions), enableCompliance = _a.enableCompliance, complianceIssues = _a.complianceIssues, customComplianceThresholds = _a.customComplianceThresholds;
    if (!enableCompliance) {
        return { status: types_1.ImageStatus.SUCCESS };
    }
    if (!complianceResult) {
        return { status: types_1.ImageStatus.COMPLIANCE_RUNNING };
    }
    var newIssuesAfterCustomThresholds = applyCustomComplianceThresholds(complianceResult.compliance_issues, complianceResult, customComplianceThresholds);
    var filteredCompliances = filterCompliances(newIssuesAfterCustomThresholds, complianceIssues);
    if (filteredCompliances.length === 0) {
        return { status: types_1.ImageStatus.SUCCESS };
    }
    return {
        status: types_1.ImageStatus.NOT_COMPLIANT,
        complianceIssues: filteredCompliances.sort(compareComplianceIssues),
    };
}
function mapApiImage(image, inspectionId, thumbnailDomain, complianceOptions) {
    var _a, _b, _c;
    var sightId = (_a = image.additional_data) === null || _a === void 0 ? void 0 : _a.sight_id;
    var _d = mapCompliance(sightId, image.compliances, complianceOptions), status = _d.status, complianceIssues = _d.complianceIssues;
    return {
        id: image.id,
        entityType: types_1.MonkEntityType.IMAGE,
        inspectionId: inspectionId,
        label: (_b = image.additional_data) === null || _b === void 0 ? void 0 : _b.label,
        sightId: sightId,
        createdAt: ((_c = image.additional_data) === null || _c === void 0 ? void 0 : _c.created_at)
            ? Date.parse(image.additional_data.created_at)
            : undefined,
        path: image.path,
        thumbnailPath: (0, utils_1.getThumbnailUrl)(thumbnailDomain, image.path, {
            height: image.image_height,
            width: image.image_width,
        }),
        width: image.image_width,
        height: image.image_height,
        size: image.binary_size,
        mimetype: image.mimetype,
        type: image.image_type,
        subtype: image.image_subtype,
        status: status,
        complianceIssues: complianceIssues,
        siblingKey: image.image_sibling_key,
        viewpoint: image.viewpoint,
        detailedViewpoint: image.detailed_viewpoint
            ? {
                isExterior: image.detailed_viewpoint.is_exterior,
                distance: image.detailed_viewpoint.distance,
                centersOn: image.detailed_viewpoint.centers_on,
            }
            : undefined,
        additionalData: image.additional_data,
        renderedOutputs: [],
        views: [],
    };
}
exports.mapApiImage = mapApiImage;
