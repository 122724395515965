"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCanvasHandle = void 0;
/**
 * Utility function used to retreive a canvas handle or throw if it is not available.
 */
function getCanvasHandle(ref, onError) {
    if (!ref.current) {
        var err = new Error('Unable to process the camera picture because the canvas element is null.');
        if (onError) {
            onError(err);
        }
        throw err;
    }
    var context = ref.current.getContext('2d', {
        alpha: false,
        willReadFrequently: true,
    });
    if (!context) {
        var err = new Error('Unable to process the camera picture because the canvas context is null.');
        if (onError) {
            onError(err);
        }
        throw err;
    }
    return { canvas: ref.current, context: context };
}
exports.getCanvasHandle = getCanvasHandle;
