"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SentryMonitoringAdapter = void 0;
var Sentry = __importStar(require("@sentry/react"));
var monitoring_1 = require("@monkvision/monitoring");
var defaultOptions = {
    environment: 'local',
    debug: false,
    tracesSampleRate: 0.025,
    release: '',
};
function getScopeFn(context) {
    return function (scope) {
        if (context === null || context === void 0 ? void 0 : context.tags) {
            scope.setTags(context.tags);
        }
        if (context === null || context === void 0 ? void 0 : context.extras) {
            scope.setContext('extras', context.extras);
        }
        return scope;
    };
}
function getLogContext(context) {
    if (!context || typeof context === 'string') {
        return context;
    }
    return getScopeFn(context);
}
/**
 * This is a Monitoring Adapter that connects the app to the Sentry platform.
 * There are four methods implemented which are `setUserId`, `log`, `handleError` and `createTransaction`,
 *
 * When initializing the adapter, the user have to pass required sentry configuration keys to make connection between
 * the application and Sentry. The `log` and `handleError` methods will log data and errors respectively in the Sentry
 * dashboards, as well as log them in the console. The `createTransaction` method used to measure performances in an
 * application at any given point.
 */
var SentryMonitoringAdapter = /** @class */ (function (_super) {
    __extends(SentryMonitoringAdapter, _super);
    function SentryMonitoringAdapter(optionsParam) {
        var _this = _super.call(this) || this;
        _this.sentryOptions = __assign(__assign({}, defaultOptions), optionsParam);
        Sentry.addTracingExtensions();
        Sentry.init(__assign(__assign({}, _this.sentryOptions), { beforeBreadcrumb: function (breadcrumb) { return (breadcrumb.category === 'xhr' ? null : breadcrumb); } }));
        if (_this.sentryOptions.customTags) {
            Sentry.setTags(_this.sentryOptions.customTags);
        }
        return _this;
    }
    SentryMonitoringAdapter.prototype.setUserId = function (id) {
        Sentry.setUser({ id: id });
    };
    SentryMonitoringAdapter.prototype.log = function (msg, context) {
        _super.prototype.log.call(this, msg, context);
        Sentry.captureMessage(msg, getLogContext(context));
    };
    SentryMonitoringAdapter.prototype.handleError = function (err, context) {
        _super.prototype.handleError.call(this, err, context);
        Sentry.captureException(err, getScopeFn(context));
    };
    SentryMonitoringAdapter.prototype.createTransaction = function (context) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        var transaction = Sentry.startTransaction({
            parentSpanId: context === null || context === void 0 ? void 0 : context.parentId,
            name: (_a = context === null || context === void 0 ? void 0 : context.name) !== null && _a !== void 0 ? _a : '',
            data: (_b = context === null || context === void 0 ? void 0 : context.data) !== null && _b !== void 0 ? _b : {},
            op: (_c = context === null || context === void 0 ? void 0 : context.operation) !== null && _c !== void 0 ? _c : '',
            description: (_d = context === null || context === void 0 ? void 0 : context.description) !== null && _d !== void 0 ? _d : '',
            traceId: (_e = context === null || context === void 0 ? void 0 : context.traceId) !== null && _e !== void 0 ? _e : '',
            tags: (_f = context === null || context === void 0 ? void 0 : context.tags) !== null && _f !== void 0 ? _f : {},
            sampled: true,
        });
        var transactionSpans = {};
        return {
            id: transaction.spanId,
            setTag: function (tagName, tagValue) { return transaction.setTag(tagName, tagValue); },
            startMeasurement: function (name, measurementContext) {
                transactionSpans[name] = transaction.startChild(__assign(__assign({}, (measurementContext !== null && measurementContext !== void 0 ? measurementContext : {})), { op: name }));
            },
            stopMeasurement: function (name, status) {
                if (status === void 0) { status = monitoring_1.TransactionStatus.OK; }
                if (!transactionSpans[name]) {
                    _this.handleError(new Error("Unable to stop measurement in SentryMonitoringAdapter : Unknown measurement name \"".concat(name, "\"")));
                    return;
                }
                transactionSpans[name].setStatus(status);
                transactionSpans[name].finish();
                delete transactionSpans[name];
            },
            setMeasurementTag: function (measurementName, tagName, value) {
                if (!transactionSpans[measurementName]) {
                    _this.handleError(new Error("Unable to set tag to measurement in SentryMonitoringAdapter : Unknown measurement name \"".concat(measurementName, "\"")));
                    return;
                }
                transactionSpans[measurementName].setTag(tagName, value);
            },
            setMeasurement: function (name, value, unit) {
                if (unit === void 0) { unit = 'none'; }
                transaction.setMeasurement(name, value, unit);
            },
            finish: function (status) {
                if (status === void 0) { status = monitoring_1.TransactionStatus.OK; }
                transaction.setStatus(status);
                transaction.finish();
            },
        };
    };
    SentryMonitoringAdapter.prototype.setTags = function (tags) {
        Sentry.setTags(tags);
    };
    return SentryMonitoringAdapter;
}(monitoring_1.DebugMonitoringAdapter));
exports.SentryMonitoringAdapter = SentryMonitoringAdapter;
