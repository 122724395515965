"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
exports.styles = {
    counter: {
        display: 'flex',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 12,
        margin: '0 10px',
        whiteSpace: 'nowrap',
        borderRadius: 8,
        zIndex: 9,
    },
};
