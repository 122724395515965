"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonkProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var context_1 = require("./context");
var hooks_1 = require("./hooks");
var reducer_1 = require("./reducer");
var state_1 = require("./state");
/**
 * Context provider for the Monk Context. Place this at the root of your application in order to initialize the Monk
 * state in your app and get access to the dispatch function.
 *
 * ***Important Note :*** If this provider component is placed in a component which is already a child of a
 * MonkProvider, this provider will have no effect. Only the FIRST MonkProvider has any effect.
 */
function MonkProvider(_a) {
    var initialState = _a.initialState, children = _a.children;
    var _b = (0, react_1.useReducer)(reducer_1.monkReducer, __assign(__assign({}, (0, state_1.createEmptyMonkState)()), initialState)), state = _b[0], dispatch = _b[1];
    try {
        (0, hooks_1.useMonkState)();
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children });
    }
    catch (err) {
        return (0, jsx_runtime_1.jsx)(context_1.MonkContext.Provider, __assign({ value: { state: state, dispatch: dispatch } }, { children: children }));
    }
}
exports.MonkProvider = MonkProvider;
