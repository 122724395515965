"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.email = exports.required = exports.mergeValidationFunctions = exports.BasicValidationErrors = void 0;
var EMAIL_REGEX = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
/**
 * Basic form validation errors returned by avaliable validation functions in the SDK.
 */
var BasicValidationErrors;
(function (BasicValidationErrors) {
    /**
     * Error returned when the field is required by no value has been passed.
     */
    BasicValidationErrors["REQUIRED"] = "required";
    /**
     * Error returned when the value is not a valid email.
     */
    BasicValidationErrors["EMAIL_NOT_VALID"] = "emailNotValid";
})(BasicValidationErrors = exports.BasicValidationErrors || (exports.BasicValidationErrors = {}));
/**
 * Util function used to merge multiple validation functions into a single one. The first error discovered will be
 * returned.
 */
function mergeValidationFunctions() {
    var validateFunctions = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        validateFunctions[_i] = arguments[_i];
    }
    return function (value) {
        for (var i = 0; i < validateFunctions.length; i++) {
            var error = validateFunctions[i](value);
            if (error) {
                return error;
            }
        }
        return null;
    };
}
exports.mergeValidationFunctions = mergeValidationFunctions;
/**
 * Validation function for required fields.
 */
function required(value) {
    return value !== null && value !== undefined && value !== ''
        ? null
        : BasicValidationErrors.REQUIRED;
}
exports.required = required;
/**
 * Validation function for valid email fields.
 */
function email(value) {
    var error = required(value);
    if (error) {
        return error;
    }
    return typeof value === 'string' && value.match(EMAIL_REGEX) !== null
        ? null
        : BasicValidationErrors.EMAIL_NOT_VALID;
}
exports.email = email;
