"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMonkTheme = void 0;
var react_1 = require("react");
var context_1 = require("./context");
/**
 * Custom hook that allows you to access the current Monk theme used by the application.
 */
function useMonkTheme() {
    return (0, react_1.useContext)(context_1.MonkThemeContext);
}
exports.useMonkTheme = useMonkTheme;
