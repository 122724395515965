"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
var PhotoCaptureHUDButtons_styles_1 = require("../../PhotoCaptureHUDButtons/PhotoCaptureHUDButtons.styles");
exports.styles = {
    container: {
        display: 'flex',
        justifyContent: 'start',
    },
    containerWide: {
        display: 'flex',
        position: 'fixed',
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: "calc(98% - (".concat(PhotoCaptureHUDButtons_styles_1.PHOTO_CAPTURE_HUD_BUTTONS_BAR_WIDTH * 4, "px))"),
        justifyContent: 'center',
    },
    button: {
        textAlign: 'start',
        borderRadius: '12px',
        fontSize: 14,
        flexDirection: 'row-reverse',
        paddingRight: 0,
        alignItems: 'start',
        gap: 10,
    },
};
