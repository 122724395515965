"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useXMLParser = void 0;
var react_1 = require("react");
/**
 * Custom hook used to parse an XML string and return a Document object. This hook is mainly used in the SDK to parse
 * SVG images from strings and iterate over the parsed document to create dynamic SVGs.
 */
function useXMLParser(xml) {
    return (0, react_1.useMemo)(function () { return new DOMParser().parseFromString(xml, 'text/xml'); }, [xml]);
}
exports.useXMLParser = useXMLParser;
