"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createdOneImage = exports.isCreatedOneImageAction = void 0;
var monkAction_1 = require("./monkAction");
/**
 * Matcher function that matches a CreatedOneImage while also inferring its type using TypeScript's type predicate
 * feature.
 */
function isCreatedOneImageAction(action) {
    return action.type === monkAction_1.MonkActionType.CREATED_ONE_IMAGE;
}
exports.isCreatedOneImageAction = isCreatedOneImageAction;
/**
 * Reducer function for a CreatedOneImage action.
 */
function createdOneImage(state, action) {
    var inspections = state.inspections;
    var localImage = state.images.find(function (image) { return image.id === action.payload.localId; });
    if (localImage) {
        URL.revokeObjectURL(localImage.path);
    }
    var images = state.images.filter(function (image) { return ![action.payload.image.id, action.payload.localId].includes(image.id); });
    images.push(action.payload.image);
    var imageInspection = inspections.find(function (inspection) { return inspection.id === action.payload.inspectionId; });
    if (imageInspection) {
        imageInspection.images = imageInspection.images.filter(function (imageId) { return ![action.payload.image.id, action.payload.localId].includes(imageId); });
        imageInspection.images.push(action.payload.image.id);
    }
    return __assign(__assign({}, state), { inspections: __spreadArray([], inspections, true), images: __spreadArray([], images, true) });
}
exports.createdOneImage = createdOneImage;
