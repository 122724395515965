"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQueue = void 0;
var react_1 = require("react");
var defaultOptions = {
    storeFailedItems: false,
};
/**
 * This hook is used to create a processing queue. The `process` function passed as a parameter is an async function
 * that is used to process items in the queue. You can find more details on how the queue works by taking a look at the
 * TSDoc of the `Queue` interface.
 *
 * @see Queue
 * @see QueueOptions
 */
function useQueue(process, optionsParams) {
    var _this = this;
    var _a = (0, react_1.useState)([]), processedItems = _a[0], setProcessedItems = _a[1];
    var _b = (0, react_1.useState)([]), itemsOnHold = _b[0], setItemsOnHold = _b[1];
    var _c = (0, react_1.useState)([]), canceledItems = _c[0], setCanceledItems = _c[1];
    var _d = (0, react_1.useState)([]), failedItems = _d[0], setFailedItems = _d[1];
    var _e = (0, react_1.useState)(0), totalItems = _e[0], setTotalItems = _e[1];
    var options = (0, react_1.useMemo)(function () { return (__assign(__assign({}, defaultOptions), (optionsParams !== null && optionsParams !== void 0 ? optionsParams : {}))); }, [optionsParams]);
    var stateRef = (0, react_1.useRef)();
    stateRef.current = { processedItems: processedItems, itemsOnHold: itemsOnHold, canceledItems: canceledItems, options: options };
    var clear = (0, react_1.useCallback)(function (cancelProcessing) {
        if (cancelProcessing === void 0) { cancelProcessing = false; }
        if (cancelProcessing) {
            setCanceledItems(function (items) { var _a, _b; return __spreadArray(__spreadArray([], items, true), ((_b = (_a = stateRef.current) === null || _a === void 0 ? void 0 : _a.processedItems) !== null && _b !== void 0 ? _b : []), true); });
        }
        setProcessedItems([]);
        setItemsOnHold([]);
        setFailedItems([]);
        setTotalItems(0);
    }, []);
    var clearFailedItems = (0, react_1.useCallback)(function (itemsToClear) {
        setFailedItems(function (items) { return items.filter(function (i) { return !itemsToClear.includes(i.item); }); });
    }, []);
    var processItem = (0, react_1.useCallback)(function (item) { return __awaiter(_this, void 0, void 0, function () {
        var error_1;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    setProcessedItems(function (items) { return __spreadArray(__spreadArray([], items, true), [item], false); });
                    _f.label = 1;
                case 1:
                    _f.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, process(item)];
                case 2:
                    _f.sent();
                    if ((_a = stateRef.current) === null || _a === void 0 ? void 0 : _a.canceledItems.includes(item)) {
                        setCanceledItems(function (items) { return items.filter(function (i) { return i !== item; }); });
                        return [2 /*return*/];
                    }
                    if ((_b = stateRef.current) === null || _b === void 0 ? void 0 : _b.options.onItemComplete) {
                        stateRef.current.options.onItemComplete(item);
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _f.sent();
                    if ((_c = stateRef.current) === null || _c === void 0 ? void 0 : _c.canceledItems.includes(item)) {
                        setCanceledItems(function (items) { return items.filter(function (i) { return i !== item; }); });
                        return [2 /*return*/];
                    }
                    if ((_d = stateRef.current) === null || _d === void 0 ? void 0 : _d.options.storeFailedItems) {
                        setFailedItems(function (items) { return __spreadArray(__spreadArray([], items, true), [{ error: error_1, item: item }], false); });
                    }
                    if ((_e = stateRef.current) === null || _e === void 0 ? void 0 : _e.options.onItemFail) {
                        stateRef.current.options.onItemFail(item);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    setProcessedItems(function (items) { return items.filter(function (i) { return i !== item; }); });
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [process]);
    var shiftQueue = (0, react_1.useCallback)(function () {
        if (stateRef.current) {
            if (stateRef.current.itemsOnHold.length === 0) {
                return;
            }
            var numberOfItemsToShift = stateRef.current.options.maxProcessingItems === undefined
                ? stateRef.current.itemsOnHold.length
                : Math.min(stateRef.current.options.maxProcessingItems - stateRef.current.processedItems.length, stateRef.current.itemsOnHold.length);
            if (numberOfItemsToShift === 0) {
                return;
            }
            var itemsToShift_1 = stateRef.current.itemsOnHold.slice(0, numberOfItemsToShift);
            itemsToShift_1.forEach(function (item) {
                processItem(item)
                    .catch(function () { })
                    .finally(function () { return shiftQueue(); });
            });
            setItemsOnHold(function (items) { return items.filter(function (item) { return !itemsToShift_1.includes(item); }); });
        }
    }, [processItem]);
    var push = (0, react_1.useCallback)(function (item) {
        if (options.maxItems !== undefined &&
            processedItems.length + itemsOnHold.length >= options.maxItems) {
            throw new Error('Queue is full.');
        }
        setTotalItems(function (total) { return total + 1; });
        if (options.maxProcessingItems !== undefined &&
            processedItems.length >= options.maxProcessingItems) {
            setItemsOnHold(function (items) { return __spreadArray(__spreadArray([], items, true), [item], false); });
            return;
        }
        processItem(item)
            .catch(function () { })
            .finally(function () { return shiftQueue(); });
    }, [
        options.maxItems,
        processedItems.length,
        itemsOnHold.length,
        options.maxProcessingItems,
        processItem,
        shiftQueue,
    ]);
    return {
        length: processedItems.length + itemsOnHold.length,
        processingCount: processedItems.length,
        onHoldCount: itemsOnHold.length,
        totalItems: totalItems,
        isFull: options.maxItems !== undefined &&
            processedItems.length + itemsOnHold.length >= options.maxItems,
        isAtMaxProcessing: options.maxProcessingItems !== undefined &&
            processedItems.length >= options.maxProcessingItems,
        push: push,
        failedItems: failedItems,
        clearFailedItems: clearFailedItems,
        clear: clear,
    };
}
exports.useQueue = useQueue;
