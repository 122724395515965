"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePhotoCaptureHUDCounterLabel = void 0;
var react_i18next_1 = require("react-i18next");
var hooks_1 = require("../../hooks");
function usePhotoCaptureHUDCounterLabel(props) {
    var t = (0, react_i18next_1.useTranslation)().t;
    if (props.mode === hooks_1.PhotoCaptureMode.SIGHT) {
        return "".concat(props.sightsTaken, " / ").concat(props.totalSights);
    }
    if (props.mode === hooks_1.PhotoCaptureMode.ADD_DAMAGE_1ST_SHOT) {
        return t('photo.hud.addDamage.damagedPartCounter');
    }
    return t('photo.hud.addDamage.closeupPictureCounter');
}
exports.usePhotoCaptureHUDCounterLabel = usePhotoCaptureHUDCounterLabel;
