"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePhotoCaptureTutorial = exports.TutorialSteps = exports.STORAGE_KEY_PHOTO_CAPTURE_TUTORIAL = void 0;
var react_1 = require("react");
var types_1 = require("@monkvision/types");
var common_1 = require("@monkvision/common");
exports.STORAGE_KEY_PHOTO_CAPTURE_TUTORIAL = '@monk_photoCaptureTutorial';
/**
 * Enum of the different steps of the PhotoCapture Tutorial component.
 */
var TutorialSteps;
(function (TutorialSteps) {
    /**
     * Welcome step.
     */
    TutorialSteps["WELCOME"] = "welcome";
    /**
     * Guideline step.
     */
    TutorialSteps["GUIDELINE"] = "guideline";
    /**
     * Sight tutorial step.
     */
    TutorialSteps["SIGHT_TUTORIAL"] = "sightTutorial";
    /**
     * Sight step.
     */
    TutorialSteps["SIGHT"] = "sight";
})(TutorialSteps = exports.TutorialSteps || (exports.TutorialSteps = {}));
function getTutorialState(enableTutorial) {
    var isFirstTime = !!localStorage.getItem(exports.STORAGE_KEY_PHOTO_CAPTURE_TUTORIAL);
    return enableTutorial === types_1.PhotoCaptureTutorialOption.DISABLED ||
        (enableTutorial === types_1.PhotoCaptureTutorialOption.FIRST_TIME_ONLY && isFirstTime)
        ? null
        : TutorialSteps.WELCOME;
}
/**
 * Custom hook used to manage the state of photo capture tutorial.
 */
function usePhotoCaptureTutorial(_a) {
    var enableTutorial = _a.enableTutorial, enableSightTutorial = _a.enableSightTutorial, enableSightGuidelines = _a.enableSightGuidelines;
    var _b = (0, react_1.useState)(getTutorialState(enableTutorial)), currentTutorialStep = _b[0], setCurrentTutorialStep = _b[1];
    var goToNextTutorialStep = (0, react_1.useCallback)(function () {
        var steps = Object.values(TutorialSteps);
        if (!enableSightGuidelines) {
            steps = steps.filter(function (v) { return v !== TutorialSteps.GUIDELINE; });
        }
        if (!enableSightTutorial) {
            steps = steps.filter(function (v) { return v !== TutorialSteps.SIGHT_TUTORIAL; });
        }
        if (currentTutorialStep === steps.at(-1)) {
            setCurrentTutorialStep(null);
            return;
        }
        var currentIndex = steps.findIndex(function (v) { return currentTutorialStep === v; });
        setCurrentTutorialStep(steps[currentIndex + 1]);
    }, [currentTutorialStep]);
    var closeTutorial = (0, react_1.useCallback)(function () {
        setCurrentTutorialStep(null);
    }, []);
    (0, react_1.useEffect)(function () {
        localStorage.setItem(exports.STORAGE_KEY_PHOTO_CAPTURE_TUTORIAL, exports.STORAGE_KEY_PHOTO_CAPTURE_TUTORIAL);
    }, []);
    return (0, common_1.useObjectMemo)({ currentTutorialStep: currentTutorialStep, goToNextTutorialStep: goToNextTutorialStep, closeTutorial: closeTutorial });
}
exports.usePhotoCaptureTutorial = usePhotoCaptureTutorial;
