"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WheelName = void 0;
/**
 * Enumeration of the vehicle wheels.
 */
var WheelName;
(function (WheelName) {
    /**
     * The front left wheel.
     */
    WheelName["FRONT_LEFT"] = "wheel_front_left";
    /**
     * The front right wheel.
     */
    WheelName["FRONT_RIGHT"] = "wheel_front_right";
    /**
     * The back left wheel.
     */
    WheelName["BACK_LEFT"] = "wheel_back_left";
    /**
     * The back right wheel.
     */
    WheelName["BACK_RIGHT"] = "wheel_back_right";
})(WheelName = exports.WheelName || (exports.WheelName = {}));
