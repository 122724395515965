"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInspectionGalleryEmptyLabel = void 0;
var react_i18next_1 = require("react-i18next");
function useInspectionGalleryEmptyLabel(_a) {
    var captureMode = _a.captureMode, isFilterActive = _a.isFilterActive;
    var t = (0, react_i18next_1.useTranslation)().t;
    if (isFilterActive) {
        return t('list.empty.filter');
    }
    return captureMode ? t('list.empty.capture') : t('list.empty.nonCapture');
}
exports.useInspectionGalleryEmptyLabel = useInspectionGalleryEmptyLabel;
