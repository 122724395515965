"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyCode = exports.Severity = exports.ProgressStatus = void 0;
/**
 * The status of a task being run by the Monk backend.
 */
var ProgressStatus;
(function (ProgressStatus) {
    /**
     * The task has not been started and is not planned to be started yet.
     */
    ProgressStatus["NOT_STARTED"] = "NOT_STARTED";
    /**
     * The task is planned to be started soon by the backend but has not been started yet.
     */
    ProgressStatus["TODO"] = "TODO";
    /**
     * The task is in progress.
     */
    ProgressStatus["IN_PROGRESS"] = "IN_PROGRESS";
    /**
     * The task is complete.
     */
    ProgressStatus["DONE"] = "DONE";
    /**
     * The task was stopped due to an error.
     */
    ProgressStatus["ERROR"] = "ERROR";
    /**
     * The task is done and the result has been validated.
     */
    ProgressStatus["VALIDATED"] = "VALIDATED";
})(ProgressStatus = exports.ProgressStatus || (exports.ProgressStatus = {}));
/**
 * Enumeration of the levels of severity for a damage or a part.
 */
var Severity;
(function (Severity) {
    /**
     * No damage detected.
     */
    Severity[Severity["NONE"] = 0] = "NONE";
    /**
     * Low severity damage.
     */
    Severity[Severity["LOW"] = 1] = "LOW";
    /**
     * Moderate severity damage.
     */
    Severity[Severity["MODERATE"] = 2] = "MODERATE";
    /**
     * High severity damage.
     */
    Severity[Severity["HIGH"] = 3] = "HIGH";
})(Severity = exports.Severity || (exports.Severity = {}));
/**
 * Enumeration of the possible currency symbols used in the Monk state.
 */
var CurrencyCode;
(function (CurrencyCode) {
    /**
     * Euro
     */
    CurrencyCode["EUR"] = "EUR";
    /**
     * United-States Dollar
     */
    CurrencyCode["USD"] = "USD";
})(CurrencyCode = exports.CurrencyCode || (exports.CurrencyCode = {}));
