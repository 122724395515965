"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = exports.sizes = exports.slideTransitionFrames = void 0;
exports.slideTransitionFrames = '0.3s ease-out';
exports.sizes = {
    normal: {
        width: 65,
        height: 30,
        knobPadding: 2,
        labelPadding: 10,
        knobOverlayExpansion: 3,
        iconSize: 18,
    },
    small: {
        width: 36,
        height: 22,
        knobPadding: 2,
        knobOverlayExpansion: 2,
        iconSize: 10,
    },
};
exports.styles = {
    button: {
        width: exports.sizes.normal.width,
        height: exports.sizes.normal.height,
        padding: "0 ".concat(exports.sizes.normal.labelPadding, "px"),
        overflow: 'visible',
        borderRadius: 9999999,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: 'none',
        cursor: 'pointer',
        transition: "background-color ".concat(exports.slideTransitionFrames),
    },
    buttonDisabled: {
        opacity: 0.37,
        cursor: 'default',
    },
    buttonSmall: {
        padding: 0,
        width: exports.sizes.small.width,
        height: exports.sizes.small.height,
    },
    knobOverlay: {
        width: exports.sizes.normal.height + 2 * exports.sizes.normal.knobOverlayExpansion,
        height: exports.sizes.normal.height + 2 * exports.sizes.normal.knobOverlayExpansion,
        borderRadius: '50%',
        position: 'absolute',
        top: -exports.sizes.normal.knobOverlayExpansion,
        left: -exports.sizes.normal.knobOverlayExpansion,
        transition: "left ".concat(exports.slideTransitionFrames),
    },
    knobOverlaySmall: {
        width: exports.sizes.small.height + 2 * exports.sizes.small.knobOverlayExpansion,
        height: exports.sizes.small.height + 2 * exports.sizes.small.knobOverlayExpansion,
        top: -exports.sizes.small.knobOverlayExpansion,
        left: -exports.sizes.small.knobOverlayExpansion,
    },
    knobOverlayChecked: {
        left: exports.sizes.normal.width - exports.sizes.normal.height - exports.sizes.normal.knobOverlayExpansion,
    },
    knobOverlaySmallChecked: {
        left: exports.sizes.small.width - exports.sizes.small.height - exports.sizes.small.knobOverlayExpansion,
    },
    knob: {
        width: exports.sizes.normal.height - 2 * exports.sizes.normal.knobPadding,
        height: exports.sizes.normal.height - 2 * exports.sizes.normal.knobPadding,
        borderRadius: '50%',
        position: 'absolute',
        top: exports.sizes.normal.knobPadding,
        left: exports.sizes.normal.knobPadding,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: "left ".concat(exports.slideTransitionFrames),
    },
    knobSmall: {
        width: exports.sizes.small.height - 2 * exports.sizes.small.knobPadding,
        height: exports.sizes.small.height - 2 * exports.sizes.small.knobPadding,
        top: exports.sizes.small.knobPadding,
        left: exports.sizes.small.knobPadding,
    },
    knobChecked: {
        left: exports.sizes.normal.width - exports.sizes.normal.height + exports.sizes.normal.knobPadding,
    },
    knobSmallChecked: {
        left: exports.sizes.small.width - exports.sizes.small.height + exports.sizes.small.knobPadding,
    },
    label: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.4px',
        transition: "opacity ".concat(exports.slideTransitionFrames),
    },
    icon: {
        transition: "opacity ".concat(exports.slideTransitionFrames),
    },
};
