"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMonitoring = void 0;
var react_1 = require("react");
var context_1 = require("./context");
/**
 * Custom hook that allows you to access the Monitoring Context methods inside a component.
 */
function useMonitoring() {
    var adapter = (0, react_1.useContext)(context_1.MonitoringContext);
    return (0, react_1.useMemo)(function () { return ({
        setUserId: adapter.setUserId.bind(adapter),
        log: adapter.log.bind(adapter),
        handleError: adapter.handleError.bind(adapter),
        createTransaction: adapter.createTransaction.bind(adapter),
        setTags: adapter.setTags.bind(adapter),
    }); }, []);
}
exports.useMonitoring = useMonitoring;
